import { FC, Fragment } from "react";

import { TextInput, TextArea } from "@encoderinc/mui-inputs-core";

interface ICategoriesStepProps {
  name: string;
}

export const CategoriesStep: FC<ICategoriesStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <TextInput name={`${name}.categoriseQuestion`} />
      <TextInput name={`${name}.categorisePositive`} />
      <TextInput name={`${name}.categoriseNegative`} />
      <TextInput name={`${name}.categoriseStatement`} />
      <TextArea name={`${name}.categoriseAnswerText`} />
      <TextArea name={`${name}.categoriseWrongAnswerText`} />
    </Fragment>
  );
};
