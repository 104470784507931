import React, { FC, Fragment } from "react";
import { Grid, Box, IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Add, Create } from "@mui/icons-material";

import { ISelectInOrderOption } from "@memoryos/types";
import { useStyles } from "./styles";

interface ISelectInOrderStepProps {
  name: string;
  items: any[];
  withActions?: boolean;
  url?: string;
  parentId?: number;
  setItems?: (items: any[]) => void;
}
function arraymove(arr: any[], fromIndex: number, toIndex: any) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

export const OrderedItems: FC<ISelectInOrderStepProps> = props => {
  const { name, items, withActions = false, url = "", parentId = 0, setItems = () => {} } = props;
  const formik = useFormikContext<any>();
  const classes = useStyles();
  const itemsToUpdate = items;
  const handleDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    arraymove(itemsToUpdate, result.source.index, result.destination.index);
    formik.setFieldValue(
      name,
      typeof itemsToUpdate[0] === "object" ? itemsToUpdate.map((item: { id: number }) => item.id) : itemsToUpdate,
    );
    setItems([...itemsToUpdate]);
  };

  const handleEdit = (item: { id: number }): void => {
    // @ts-ignore
    window.open(`/${url}/${item.id}`, "_blank").focus();
  };
  const handleAdd = (): void => {
    // @ts-ignore
    window.open(`/${url}?action=add&parentId=${parentId}`, "_blank").focus();
  };
  return (
    <Fragment>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {provided => (
            <Grid
              className={classes.root}
              container
              spacing={2}
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <p>Order items:</p>
              {itemsToUpdate.map((option: ISelectInOrderOption, i: number) => (
                <Draggable key={i} draggableId={i.toString()} index={i}>
                  {provided => (
                    <Grid
                      className={classes.itemGrid}
                      item
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Box className={classes.itemBox} display="inline-block">
                        <div className={classes.itemCard}>
                          {typeof items[i] === "string" ? items[i] : items[i].title}
                          {withActions && (
                            <div>
                              <IconButton
                                onClick={() => {
                                  handleEdit(items[i]);
                                }}
                              >
                                <Create />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </Box>
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <div>
                {withActions && (
                  <IconButton
                    onClick={() => {
                      handleAdd();
                    }}
                  >
                    <Add color="primary" />
                  </IconButton>
                )}
              </div>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );
};
