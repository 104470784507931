import { FC, Fragment, useState, useEffect } from "react";
import { SelectInput, TextInput, NumberInput, TextArea } from "@encoderinc/mui-inputs-core";
import { MemoryPalaceGameMode, MemoryPalaceName } from "@memoryos/types";
import { useFormikContext, getIn } from "formik";
import { useStepValuesBeforeEditSaved } from "../../../../../../utils/hooks/useStepValuesBeforeEditSaved";
import { SelectChangeEvent } from "@mui/material";

interface IMemoryPalaceProps {
  name: string;
  valuesBeforeEdit?: any;
}

export const MemoryPalaceStep: FC<IMemoryPalaceProps> = props => {
  const { name, valuesBeforeEdit } = props;
  const formik = useFormikContext<any>();
  useStepValuesBeforeEditSaved(formik, valuesBeforeEdit, name);

  const [roomAliases, setRoomAliases] = useState<string>("");
  const [gameMode, setGameMode] = useState<string>("");
  const [timeMultiplier, setTimeMultiplier] = useState<number>(1);
  useEffect(() => {
    const aliases = getIn(formik.values, `${name}.memoryPalaceRoomsAliases`);
    const timeMultiplier = getIn(formik.values, `${name}.memoryPalaceTimeMultiplier`);
    const gameMode = getIn(formik.values, `${name}.memoryPalaceGameMode`);
    if (aliases?.length) {
      setRoomAliases(aliases.join(", ").trim());
    }
    if (timeMultiplier) {
      setTimeMultiplier(timeMultiplier);
    }
    if (gameMode) {
      setGameMode(gameMode);
    }
  }, []);

  const handleRoomAliasesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoomAliases(e.target.value);
    const newValue = e.target.value.split(",").map((alias: string) => alias.trim());
    formik.setFieldValue(`${name}.memoryPalaceRoomsAliases`, newValue);
  };

  const handleTimeMultiplierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeMultiplier(Number(e.target.value));
    formik.setFieldValue(`${name}.memoryPalaceTimeMultiplier`, Number(e.target.value));
  };

  const handleGameModeChange = (e: SelectChangeEvent<unknown>) => {
    formik.setFieldValue(`${name}.memoryPalaceGameMode`, e.target.value);
    if (e.target.value === MemoryPalaceGameMode.SEQUENTIAL_RECREATION_WITH_TIMER) {
      formik.setFieldValue(`${name}.memoryPalaceTimeMultiplier`, 1);
      setTimeMultiplier(1);
    } else {
      formik.setFieldValue(`${name}.memoryPalaceTimeMultiplier`, 0);
      setTimeMultiplier(0);
    }
    // @ts-ignore
    setGameMode(e.target.value);
  };

  return (
    <Fragment>
      <SelectInput name={`${name}.memoryPalaceName`} options={MemoryPalaceName} />
      <SelectInput
        name={`${name}.memoryPalaceGameMode`}
        options={MemoryPalaceGameMode}
        value={gameMode}
        onChange={handleGameModeChange}
      />
      {gameMode === MemoryPalaceGameMode.SEQUENTIAL_RECREATION_WITH_TIMER && (
        <NumberInput
          name={`${name}.memoryPalaceTimeMultiplier`}
          inputProps={{ step: "0.1", min: 0, max: 1 }}
          allowNegative={false}
          value={timeMultiplier}
          onChange={handleTimeMultiplierChange}
        />
      )}
      <TextInput name={`${name}.memoryPalaceRoomsAliases`} value={roomAliases} onChange={handleRoomAliasesChange} />
      <TextArea name={`${name}.memoryPalaceLoadingMessage`} />
      <TextArea name={`${name}.memoryPalaceCorrectStatementText`} />
      <TextArea name={`${name}.memoryPalaceIncorrectStatementText`} />
    </Fragment>
  );
};
