import React, { FC } from "react";
import { FormDialog } from "@encoderinc/mui-dialog-form";
import { FormattedMessage } from "react-intl";
import { validationSchema } from "./validation";
import { NumberInput, TextInput } from "@encoderinc/mui-inputs-core";
import { IVideoRegistryEntity } from "@memoryos/types";
import { InputAdornment, Box, Grid, Divider } from "@mui/material";
import HorizontalLayout from "../../../components/common/horizontal-layout/HorizontalLayout";

export interface IEditVideoRegistryEntityDialog {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: Partial<IVideoRegistryEntity>, formikBag: any) => Promise<void>;
  initialValues: IVideoRegistryEntity;
}

export const EditVideoRegistryEntityDialog: FC<IEditVideoRegistryEntityDialog> = props => {
  const { initialValues, ...rest } = props;
  const { id, key, title, vimeo_data, sprout_data, bunny_data } = initialValues;
  const fixedValues = {
    id,
    key,
    title,
    vimeo_data,
    sprout_data,
    bunny_data,
  };

  const message = id ? "dialogs.edit" : "dialogs.add";

  return (
    <FormDialog initialValues={fixedValues} validationSchema={validationSchema} message={message} {...rest}>
      {/* General */}
      <Box sx={{ mt: 0 }}>
        <Divider sx={{ fontWeight: "bold" }}>
          <FormattedMessage id="pages.videoRegistry.edit.section.general" />
        </Divider>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextInput name="key" onFocus={event => event.currentTarget.select()} />
        </Grid>
        <Grid item xs={6}>
          <TextInput name="title" onFocus={event => event.currentTarget.select()} />
        </Grid>
      </Grid>

      {/* Providers */}
      <Box sx={{ mt: 6 }}>
        <Divider sx={{ fontWeight: "bold" }}>
          <FormattedMessage id="pages.videoRegistry.edit.section.providers" />
        </Divider>
      </Box>
      <HorizontalLayout labelId="pages.videoRegistry.edit.provider.vimeo">
        <TextInput
          name={`vimeo_data.id`}
          InputProps={{
            startAdornment: <InputAdornment position="start">https://vimeo.com/</InputAdornment>,
          }}
        />
      </HorizontalLayout>

      <HorizontalLayout labelId="pages.videoRegistry.edit.provider.sprout">
        <TextInput name="sprout_data.id" disabled={true} />
      </HorizontalLayout>

      <HorizontalLayout labelId="pages.videoRegistry.edit.provider.bunny">
        <TextInput name="bunny_data.libraryId" disabled={true} />
        <TextInput name="bunny_data.videoId" disabled={true} />
      </HorizontalLayout>
    </FormDialog>
  );
};
