import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  theme => ({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    errorHint: {
      color: "#d32f2f",
      fontSize: "0.75rem",
      lineHeight: "1.66",
      letterSpacing: "0.03333em",
      marginTop: "3px",
    },
    errorLabel: {
      color: "#d32f2f !important",
    },
  }),
  { name: "SelectInput" },
);
