import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  theme => ({
    card: {
      marginTop: "15px",
      // width: "450px",
      position: "relative",
    },
    media: {
      objectFit: "contain",
      width: "100%",
      height: "auto",
    },
    selected: {
      borderBottomColor: theme.palette.primary.light,
      backgroundColor: "rgba(66, 165, 245, 0.2)",
    },
    fileInput: {
      width: "250px",
      height: "auto",
      marginTop: "15px",
    },
    darkText: {
      color: theme.palette.common.black,
    },
    errorHint: {
      color: "#d32f2f",
      fontSize: "0.75rem",
      lineHeight: "1.66",
      letterSpacing: "0.03333em",
      marginTop: "3px",
    },
  }),
  { name: "ImageMap" },
);
