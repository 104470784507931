import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Pagination,
} from "@mui/material";
import { FilterList, Article } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";

import { ProgressOverlay } from "@encoderinc/mui-progress";
import { PageHeader } from "@encoderinc/mui-page-header";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IPaginationResult } from "@encoderinc/types-collection";
import { IOrder, IOrdersSearchDto, OrderState } from "@memoryos/types";
import { emptyOrders } from "@memoryos/mocks";
import { usePrevious } from "../../../utils/hooks/usePrevious";

import { Breadcrumbs } from "../../../components/common/breadcrumbs";
import { ShowOrdersDialog } from "./show";
import { OrdersSearchForm } from "./form";
import { defaultItemsPerPage } from "@memoryos/constants";
import { parseDateRange, stringifyDateRange } from "../../../utils/date";

export const Orders: () => JSX.Element = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [orders, setOrders] = useState<Array<IOrder>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedOrder, setSelectedOrder] = useState<IOrder>(emptyOrders);
  const [isFiltersOpen, setIsFilterOpen] = useState(true);
  const prevIsFiltersOpen = usePrevious(isFiltersOpen);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1));
  const navigate = useNavigate();

  const [data, setData] = useState<IOrdersSearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    query: "",
    ordersState: [],
    ...parsedData,
    dateRangeCreated: parseDateRange(parsedData.dateRangeCreated as string),
    dateRangeUpdated: parseDateRange(parsedData.dateRangeUpdated as string),
  });

  const updateQS = (id?: number) => {
    const { skip: _skip, take: _take, dateRangeCreated, dateRangeUpdated, ...rest } = data;
    navigate(
      id
        ? `/solidgate/orders/${id}`
        : `/solidgate/orders?${stringify({
            ...rest,
            dateRangeCreated: stringifyDateRange(dateRangeCreated),
            dateRangeUpdated: stringifyDateRange(dateRangeUpdated),
          })}`,
    );
  };

  const handleEdit = (orders: IOrder): (() => void) => {
    return (): void => {
      setSelectedOrder(orders);
      setIsEditDialogOpen(true);
      updateQS(orders.id);
    };
  };

  const fetchOrdersByQuery = async (): Promise<void> => {
    const { dateRangeCreated, dateRangeUpdated, ordersState, ...rest } = data;
    const updatedData: {
      skip: number;
      take: number;
      query: string;
      dateRangeCreated?: string;
      dateRangeUpdated?: string;
      ordersState?: OrderState;
    } = {
      ...rest,
    };
    if (isFiltersOpen && ordersState) {
      // @ts-ignore
      updatedData.ordersState = ordersState;
    }
    if (isFiltersOpen && dateRangeCreated) {
      updatedData.dateRangeCreated = stringifyDateRange(dateRangeCreated);
    }
    if (isFiltersOpen && dateRangeUpdated) {
      updatedData.dateRangeUpdated = stringifyDateRange(dateRangeUpdated);
    }
    return api
      .fetchJson({
        url: "/solidgate/orders",
        data: updatedData,
      })
      .then((json: IPaginationResult<IOrder>) => {
        setOrders(json.rows);
        setCount(json.count);
      });
  };

  const fetchOrdersById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/solidgate/orders/${id}`,
      })
      .then((json: IOrder) => {
        setOrders([json]);
        setCount(1);
        handleEdit(json)();
        updateQS();
      });
  };

  const fetchLeads = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchOrdersById(id) : fetchOrdersByQuery())
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditCancel = (): void => {
    setIsEditDialogOpen(false);
    updateQS();
  };

  const handleChangePage = (e: ChangeEvent<unknown>, page: number) => {
    setData({
      ...data,
      skip: (page - 1) * data.take,
    });
  };

  const handleSubmit = (values: IOrdersSearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFiltersOpen);
  };

  useDeepCompareEffect(() => {
    void fetchLeads(id);
  }, [data]);

  useEffect(() => {
    if (prevIsFiltersOpen !== isFiltersOpen) {
      void fetchLeads(id);
    }
  }, [isFiltersOpen]);
  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "solidgateOrders"]} />

      <PageHeader message={`pages.solidgate.orders.title`}>
        <Button startIcon={<FilterList />} onClick={toggleFilters}>
          <FormattedMessage id={`form.buttons.${isFiltersOpen ? "hideFilters" : "showFilters"}`} />
        </Button>
      </PageHeader>

      <OrdersSearchForm onSubmit={handleSubmit} initialValues={data} open={isFiltersOpen} />

      <b>
        <FormattedMessage id="pages.solidgate.orders.counter" values={{ count }} />
      </b>
      <br />
      <ProgressOverlay isLoading={isLoading}>
        <List>
          {orders.map(order => (
            <ListItem key={order.id}>
              <ListItemText>{order.orderId}</ListItemText>
              <ListItemSecondaryAction>
                <IconButton onClick={handleEdit(order)}>
                  <Article />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ProgressOverlay>

      <Pagination
        shape="rounded"
        page={data.skip / data.take + 1}
        count={Math.ceil(count / data.take)}
        onChange={handleChangePage}
      />

      <ShowOrdersDialog onCancel={handleEditCancel} open={isEditDialogOpen} values={selectedOrder} />
    </Grid>
  );
};
