import { FC, Fragment } from "react";

import { TextArea, TextInput } from "@encoderinc/mui-inputs-core";
import { NumericInput } from "../../../../../../components/common/inputs/numeric";
import { NumericInputMode } from "../../../../../../components/common/inputs/numeric/types";

interface IBinaryDigitsStepProps {
  name: string;
}

export const BinaryDigitsStep: FC<IBinaryDigitsStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <TextInput name={`${name}.binaryDigitsQuestion`} />
      <TextInput name={`${name}.binaryDigitsPrompt`} />
      <NumericInput name={`${name}.binaryDigitsAnswer`} mode={NumericInputMode.BINARY} />
      <TextArea name={`${name}.binaryDigitsAnswerText`} />
      <TextArea name={`${name}.binaryDigitsWrongAnswerText`} />
    </Fragment>
  );
};
