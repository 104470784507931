import { FC, Fragment } from "react";
import { InputAdornment } from "@mui/material";

import { TextInput } from "@encoderinc/mui-inputs-core";

interface IVideoStepProps {
  name: string;
}

export const VideoStep: FC<IVideoStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <TextInput
        name={`${name}.videoUrl`}
        InputProps={{
          startAdornment: <InputAdornment position="start">https://</InputAdornment>,
        }}
      />
      <TextInput
        name={`${name}.ccsUrl`}
        InputProps={{
          startAdornment: <InputAdornment position="start">https://</InputAdornment>,
        }}
      />
    </Fragment>
  );
};
