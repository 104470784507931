import { ChangeEvent, FC, Fragment, useState } from "react";
import { Badge, Button, Card, CardActions, CardContent, CardMedia, Grid } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { CheckboxInput, SwitchInput, TextArea, TextInput } from "@encoderinc/mui-inputs-core";
import { ProgressOverlay } from "@encoderinc/mui-progress";
import { ConfirmationDialog } from "@encoderinc/mui-dialog-confirmation";
import { FirebaseFileInput } from "@encoderinc/mui-inputs-file-firebase";
import { IMultipleChoiceImageOption } from "@memoryos/types";
import { openUrlOnClick } from "@encoderinc/popup";

import { useStyles } from "./styles";
import { RadioInput } from "../../../../../../components/common/inputs/radio";
import { useStepValuesBeforeEditSaved } from "../../../../../../utils/hooks/useStepValuesBeforeEditSaved";

interface IMultipleChoiceImageStepProps {
  name: string;
  valuesBeforeEdit?: any;
}

export const MultipleChoiceImageStep: FC<IMultipleChoiceImageStepProps> = props => {
  const { name, valuesBeforeEdit } = props;

  const classes = useStyles();
  const formik = useFormikContext<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteOptionDialogOpen, setIsDeleteOptionDialogOpen] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  useStepValuesBeforeEditSaved(formik, valuesBeforeEdit, name);
  const handleOptionDelete = (index: number): (() => void) => {
    return (): void => {
      setSelectedOptionIndex(index);
      setIsDeleteOptionDialogOpen(true);
    };
  };

  const updateSelected = (newValue: Array<IMultipleChoiceImageOption>, selectedIndex: number) => {
    const index = newValue.findIndex((option: IMultipleChoiceImageOption) => option.multipleChoiceImageOptionIsCorrect);

    if (index === -1 && newValue?.length) {
      newValue[selectedIndex].multipleChoiceImageOptionIsCorrect = true;
    }

    return newValue;
  };

  const handleDeleteConfirm = (): void => {
    const newValue = formik.values[name].multipleChoiceImageOptions;
    newValue.splice(selectedOptionIndex, 1);

    formik.setFieldValue(`${name}.multipleChoiceImageOptions`, updateSelected(newValue, 0));
    setIsDeleteOptionDialogOpen(false);
  };

  const handleDeleteCancel = (): void => {
    setIsDeleteOptionDialogOpen(false);
  };

  const handleFileChange = (urls: Array<string>): void => {
    setIsLoading(true);
    const newValue = formik.values[name].multipleChoiceImageOptions;
    urls.forEach(url => {
      newValue.push({
        multipleChoiceImageOptionImageUrl: url.split("?")[0],
        multipleChoiceImageOptionIsCorrect: false,
      });
    });
    formik.setFieldValue(`${name}.multipleChoiceImageOptions`, updateSelected(newValue, 0));
    setIsLoading(false);
  };

  const handleDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    setIsLoading(true);

    const newValues = formik.values[name].multipleChoiceImageOptions;
    const [removed] = newValues.splice(result.source.index, 1);
    newValues.splice(result.destination.index, 0, removed);

    formik.setFieldValue(`${name}.multipleChoiceImageOptions`, newValues);
    setIsLoading(false);
  };

  const cleanAllSelected = (selectedIndex = -1) => {
    const options = formik.values[name].multipleChoiceImageOptions;

    if (options.length) {
      const newValue = options.map((option: IMultipleChoiceImageOption) => ({
        ...option,
        multipleChoiceImageOptionIsCorrect: false,
      }));

      formik.setFieldValue(
        `${name}.multipleChoiceImageOptions`,
        selectedIndex !== -1 ? updateSelected(newValue, selectedIndex) : newValue,
      );
    }
  };

  const handleRadioButton = (event: ChangeEvent<HTMLInputElement>) => {
    cleanAllSelected(-1);
    formik.setFieldValue(event.target.name, event.target.checked);
  };

  const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      const options = formik.values[name].multipleChoiceImageOptions;
      const index = options.findIndex(
        (option: IMultipleChoiceImageOption) => option.multipleChoiceImageOptionIsCorrect,
      );
      cleanAllSelected(index);
    }
    formik.handleChange(event);
  };

  return (
    <Fragment>
      <TextInput name={`${name}.multipleChoiceImageQuestion`} />
      <TextInput name={`${name}.multipleChoiceImagePrompt`} />

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {provided => (
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Grid item>
                <ProgressOverlay isLoading={isLoading}>
                  <FirebaseFileInput
                    accept={["image/jpeg", "image/png"]}
                    onDropAccepted={() => {
                      setIsLoading(true);
                    }}
                    onChange={handleFileChange}
                    classes={{ root: classes.media }}
                  />
                </ProgressOverlay>
              </Grid>
              {formik.values[name].multipleChoiceImageOptions.map((option: IMultipleChoiceImageOption, i: number) => (
                <Draggable key={i} draggableId={i.toString()} index={i}>
                  {provided => (
                    <Grid item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <Card>
                        <Badge badgeContent={i + 1} color="primary" classes={{ badge: classes.badge }}>
                          <CardMedia
                            image={option.multipleChoiceImageOptionImageUrl + "?alt=media"}
                            onClick={openUrlOnClick(option.multipleChoiceImageOptionImageUrl + "?alt=media")}
                            className={classes.media}
                          />
                        </Badge>
                        <CardContent>
                          {getIn(formik.values, `${name}.multipleChoiceImageIsMultiple`) ? (
                            <CheckboxInput
                              name={`${name}.multipleChoiceImageOptions[${i}].multipleChoiceImageOptionIsCorrect`}
                            />
                          ) : (
                            <RadioInput
                              name={`${name}.multipleChoiceImageOptions[${i}].multipleChoiceImageOptionIsCorrect`}
                              onChange={handleRadioButton}
                            />
                          )}
                        </CardContent>
                        <CardActions>
                          <Button size="small" color="primary" onClick={handleOptionDelete(i)}>
                            <FormattedMessage id="form.buttons.delete" />
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>

      <SwitchInput name={`${name}.multipleChoiceImageIsMultiple`} onChange={handleSwitch} />
      <TextArea name={`${name}.multipleChoiceImageAnswerText`} />
      <TextArea name={`${name}.multipleChoiceImageWrongAnswerText`} />

      <ConfirmationDialog open={isDeleteOptionDialogOpen} onCancel={handleDeleteCancel} onConfirm={handleDeleteConfirm}>
        <FormattedMessage id="dialogs.delete" values={{ title: "image" }} />
      </ConfirmationDialog>
    </Fragment>
  );
};
