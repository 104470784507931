import { FC, useState } from "react";

import { FormDialog } from "@encoderinc/mui-dialog-form";
import { NumberInput, TextInput, SelectInput } from "@encoderinc/mui-inputs-core";
import { ILesson, LessonType, LessonStatus, RatePlanType } from "@memoryos/types";
import { useIntl } from "react-intl";

import { validationSchema } from "./validation";
import { EntityInputV2 } from "../../../../components/common/inputs/entity";
import { OrderedItems } from "../../../../components/common/inputs/draggable";

export interface IEditLessonDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: Partial<ILesson>, formikBag: any) => Promise<void>;
  initialValues: ILesson;
}

export const EditLessonDialog: FC<IEditLessonDialogProps> = props => {
  const { initialValues, ...rest } = props;

  const {
    id,
    title,
    userTitle,
    priority,
    lessonType,
    lessonStatus,
    moduleId,
    steps = [],
    lociBonus = 0,
    ratePlanType,
  } = initialValues;

  const fixedValues = {
    id,
    title,
    userTitle,
    priority,
    lessonType,
    lessonStatus,
    moduleId,
    stepIds: steps.map(step => step.id),
    lociBonus,
    ratePlanType,
  };

  const message = id ? "dialogs.edit" : "dialogs.add";
  const { formatMessage } = useIntl();
  const [updatedSteps, setUpdatedSteps] = useState(steps);

  return (
    <FormDialog
      initialValues={fixedValues}
      validationSchema={validationSchema}
      message={message}
      maxWidth="sm"
      {...rest}
    >
      <TextInput name="title" />
      <TextInput name="userTitle" />
      <NumberInput name="priority" />
      <SelectInput name="lessonType" options={LessonType} />
      {id ? <SelectInput name="lessonStatus" options={LessonStatus} /> : null}
      <EntityInputV2 name="moduleId" controller="curriculum/modules" />
      <EntityInputV2
        name="stepIds"
        controller="curriculum/steps"
        multiple
        callback={values => setUpdatedSteps(values)}
      />
      <OrderedItems items={updatedSteps.length ? updatedSteps : steps} name={"stepIds"} />
      <NumberInput name="lociBonus" />
      <SelectInput
        name="ratePlanType"
        options={RatePlanType}
        label={formatMessage({ id: `form.labels.subscription` })}
      />
    </FormDialog>
  );
};
