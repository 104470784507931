import { FC, Fragment, useContext, useEffect, useState } from "react";

import { SelectInput } from "@encoderinc/mui-inputs-core";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { Region } from "@memoryos/types";
import { SelectChangeEvent, Checkbox } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { OrderedItems } from "../../../../../../components/common/inputs/draggable";
import { usePrevious } from "../../../../../../utils/hooks/usePrevious";

interface IGeographyStepProps {
  name: string;
}

export const GeographyStep: FC<IGeographyStepProps> = props => {
  const { name } = props;
  const formik = useFormikContext<any>();
  const api = useContext(ApiContext);
  const [regions, setRegions] = useState(getIn(formik.values, `${name}.geographyRegionOptions`) || []);
  const [items, setItems] = useState<string[]>([]);
  const prevItems = usePrevious(items);
  const [availableItems, setAvailableItems] = useState<string[]>([]);

  const [randomize, setRandomize] = useState(getIn(formik.values, `${name}.geographyRandomize`));
  const [isPictureMode, setIsPictureMode] = useState(getIn(formik.values, `${name}.geographyWithPicturesMode`));
  const [isLearningMode, setIsLearningMode] = useState(getIn(formik.values, `${name}.geographyLearningMode`));
  const [itemType, setItemType] = useState(getIn(formik.values, `${name}.geographyItemType`) || "country");
  const [countries, setCountries] = useState<{ id: number; country_name: string }[]>([]);
  const [cities, setCities] = useState<{ id: number; city_name: string }[]>([]);

  useEffect(() => {
    if (itemType && items.length && items !== prevItems) {
      const selectedIds: number[] = [];
      let selectedItems: any[] = [];
      switch (itemType) {
        case "country":
          if (countries.length) {
            selectedItems = countries.filter((country: { country_name: string }) =>
              items.includes(country.country_name),
            );
            items.forEach((item: string) => {
              const currentItem = selectedItems.find(
                (country: { country_name: string }) => country.country_name === item,
              );
              if (currentItem) {
                selectedIds.push(currentItem.id);
              }
            });
          }
          break;
        case "city":
          if (cities.length) {
            selectedItems = cities.filter((city: { city_name: string }) => items.includes(city.city_name));
            items.forEach((item: string) => {
              const currentItem = selectedItems.find((city: { city_name: string }) => city.city_name === item);
              if (currentItem) {
                selectedIds.push(currentItem.id);
              }
            });
          }
          break;
        default:
          break;
      }

      if (selectedIds) {
        formik.setFieldValue(`${name}.geographyItemIds`, selectedIds);
      }
    }
  }, [items]);

  const fetchCountries = async (): Promise<void> => {
    if (regions.length === 0) {
      setItems([]);
      return;
    }
    return api
      .fetchJson({
        url: "/curriculum/country",
        data: { regions, itemType },
      })
      .then((json: any) => {
        setCountries(json);
        const selectedIds: number[] = getIn(formik.values, `${name}.geographyItemIds`);
        if (
          !selectedIds.length ||
          selectedIds.filter((id: number) => {
            return !!json.map((country: { id: number }) => country.id === id);
          }).length === 0
        ) {
          setItems(json.map((country: { country_name: string }) => country.country_name));
        } else {
          const selectedItemsNames: string[] = [];
          const selectedItems = json.filter((country: { id: number }) => selectedIds.includes(country.id));
          selectedIds.forEach((id: number) => {
            const currentItem = selectedItems.find((country: { id: number }) => country.id === id);
            if (currentItem) {
              selectedItemsNames.push(currentItem.country_name);
            }
          });

          if (selectedItemsNames) {
            setItems(selectedItemsNames);
          }
        }

        setAvailableItems(json.map((country: { country_name: string }) => country.country_name));
      })
      .catch((e: ApiError) => {
        if (e.status) {
          // enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          // enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const fetchCities = async (): Promise<void> => {
    if (regions.length === 0) {
      setItems([]);
      return;
    }
    return api
      .fetchJson({
        url: "/curriculum/city",
        data: { regions },
      })
      .then((json: any) => {
        setCities(json);
        const selectedIds: number[] = getIn(formik.values, `${name}.geographyItemIds`);
        if (
          !selectedIds.length ||
          selectedIds.filter((id: number) => {
            return !!json.map((city: { id: number }) => city.id === id);
          }).length === 0
        ) {
          setItems(json.map((city: { city_name: string }) => city.city_name));
        } else {
          const selectedItemsNames: string[] = [];
          const selectedItems = json.filter((city: { id: number }) => selectedIds.includes(city.id));
          selectedIds.forEach((id: number) => {
            const currentItem = selectedItems.find((city: { id: number }) => city.id === id);
            if (currentItem) {
              selectedItemsNames.push(currentItem.city_name);
            }
          });

          if (selectedItemsNames) {
            setItems(selectedItemsNames);
          }
        }

        setAvailableItems(json.map((city: { city_name: string }) => city.city_name));
      })
      .catch((e: ApiError) => {
        if (e.status) {
          // enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          // enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const fetchItems = async (): Promise<void> => {
    if (itemType === "country") {
      return fetchCountries();
    } else {
      return fetchCities();
    }
  };

  useEffect(() => {
    void fetchItems();
  }, [regions, itemType]);

  useEffect(() => {
    void fetchItems();
  }, []);

  const updateRegions = (event: SelectChangeEvent<unknown>): void => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue(`${name}.geographyRegionOptions`, value);
    // @ts-ignore
    setRegions(value);
  };
  const updateItems = (event: SelectChangeEvent<unknown>): void => {
    const {
      target: { value },
    } = event;
    // @ts-ignore
    setItems(value);
  };

  const updateItemType = (event: SelectChangeEvent<unknown>): void => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue(`${name}.geographyItemType`, value);

    setItems([]);
    setAvailableItems([]);
    formik.setFieldValue(`${name}.geographyItemIds`, []);
    // @ts-ignore
    setItemType(value);
  };

  const switchRandomize = (randomize: boolean) => {
    formik.setFieldValue(`${name}.geographyRandomize`, randomize);
    setRandomize(randomize);
  };

  const switchIsPicture = (pictureMode: boolean) => {
    formik.setFieldValue(`${name}.geographyWithPicturesMode`, pictureMode);
    setIsPictureMode(pictureMode);
  };

  const switchIsLearning = (learningMode: boolean) => {
    formik.setFieldValue(`${name}.geographyLearningMode`, learningMode);
    setIsLearningMode(learningMode);
  };
  return (
    <Fragment>
      <SelectInput
        name={`${name}.geographyItemType`}
        options={["city", "country"]}
        value={itemType}
        onChange={updateItemType}
      />
      <SelectInput
        name={`${name}.geographyRegionOptions`}
        options={Region}
        value={regions}
        multiple={true}
        onChange={updateRegions}
      />
      <SelectInput
        name={`${name}.geographyItemIds`}
        options={availableItems}
        value={items}
        multiple={true}
        onChange={updateItems}
      />
      <OrderedItems items={items} name={`${name}.geographyItemIds`} setItems={setItems} />
      <Checkbox
        id="randomize"
        checked={randomize}
        onClick={() => switchRandomize(!randomize)}
        name={`geographyRandomize`}
      />
      <label htmlFor={"randomize"}>Randomize</label>
      <br />
      <Checkbox
        checked={isPictureMode}
        onClick={() => switchIsPicture(!isPictureMode)}
        name={`geographyWithPicturesMode`}
        id={"picturesMode"}
      />
      <label htmlFor={"picturesMode"}>Pictures mode</label>
      <br />
      <Checkbox
        checked={isLearningMode}
        onClick={() => switchIsLearning(!isLearningMode)}
        name={`geographyLearningMode`}
        id={"learningMode"}
      />
      <label htmlFor={"learningMode"}>Learning mode</label>
    </Fragment>
  );
};
