import { IVideoRegistryEntity } from "@memoryos/types";

export function normalizeVideoRegistryEntity(data: Partial<IVideoRegistryEntity>): Partial<IVideoRegistryEntity> {
  const cleanObject = (obj?: Record<string, any> | null) => {
    if (!obj) return null;

    const cleaned = Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, any>);

    return Object.keys(cleaned).length > 0 ? cleaned : null;
  };

  return {
    ...data,
    vimeo_data: cleanObject(data.vimeo_data),
    sprout_data: cleanObject(data.sprout_data),
    bunny_data: cleanObject(data.bunny_data),
  };
}
