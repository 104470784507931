import { FC } from "react";
import { format, parseISO } from "date-fns";
import { Divider } from "@mui/material";

import { StaticInput, TextInput } from "@encoderinc/mui-inputs-core";
import { FormDialog } from "@encoderinc/mui-dialog-form";
import { IAdmin } from "@memoryos/types";
import { humanReadableDateTimeFormat } from "@memoryos/constants";

export interface IEditAdminDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: Partial<IAdmin>, formikBag: any) => Promise<void>;
  initialValues: IAdmin;
}

export const EditAdminDialog: FC<IEditAdminDialogProps> = props => {
  const { initialValues, ...rest } = props;

  const { id, displayName, email, createdAt } = initialValues;

  const fixedValues = {
    id,
    displayName,
    email,
  };

  return (
    <FormDialog initialValues={fixedValues} message="dialogs.edit" {...rest}>
      <TextInput name="displayName" />
      <TextInput name="email" />
      <br />
      <Divider />
      <br />
      <StaticInput name="createdAt" value={format(parseISO(createdAt), humanReadableDateTimeFormat)} />
      {/* TODO display subscription type */}
    </FormDialog>
  );
};
