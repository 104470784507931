import { FC, Fragment } from "react";

import { NumberInput, TextInput } from "@encoderinc/mui-inputs-core";

interface ITimerStepProps {
  name: string;
}

export const TimerStep: FC<ITimerStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <TextInput name={`${name}.timerText`} />
      <NumberInput name={`${name}.timerTime`} />
    </Fragment>
  );
};
