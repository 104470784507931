import { FC, Fragment, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Grid, IconButton, Stack } from "@mui/material";
import { Add, Create, Delete } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import CopyrightTwoToneIcon from '@mui/icons-material/CopyrightTwoTone';
import CropOriginalRoundedIcon from '@mui/icons-material/CropOriginalRounded';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

import { green } from '@mui/material/colors';

import { PageHeader } from "@encoderinc/mui-page-header";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IPaginationResult, ISearchDto } from "@encoderinc/types-collection";
import { DeleteDialog } from "@encoderinc/mui-dialog-delete";
import { CommonSearchForm } from "@encoderinc/mui-form-search";
import { emptyPromoCode } from "@memoryos/mocks";
import { Frame, IPromoCode, RatePlanType } from "@memoryos/types";

import { Breadcrumbs } from "../../../components/common/breadcrumbs";
import { EditPromoCodeDialog } from "./edit";
import { normalizePromoCode } from "./utils";
import { defaultItemsPerPage } from "@memoryos/constants";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

export const PromoCode: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [promoCodes, setPromoCodes] = useState<Array<IPromoCode>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedPromoCode, setSelectedPromoCode] = useState<IPromoCode>(emptyPromoCode);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1));

  const [data, setData] = useState<ISearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    query: "",
    ...parsedData,
  });

  const updateQS = (id?: number) => {
    const { skip: _skip, take: _take, ...rest } = data;
    navigate(id ? `/promo-codes/${id}` : `/promo-codes?${stringify(rest)}`);
  };

  const handleEdit = (promoCode: IPromoCode): (() => void) => {
    return (): void => {
      setSelectedPromoCode(promoCode);
      setIsEditDialogOpen(true);
      updateQS(promoCode.id);
    };
  };

  const handleDelete = (promoCode: IPromoCode): (() => void) => {
    return (): void => {
      setSelectedPromoCode(promoCode);
      setIsDeleteDialogOpen(true);
    };
  };

  const handleDeleteCancel = (): void => {
    setIsDeleteDialogOpen(false);
  };

  const handleEditCancel = async (): Promise<void> => {
    await fetchPromoCodesByQuery(); // Ensuring that the list is going to show the latest data
    setIsEditDialogOpen(false);
    updateQS();
  };

  const fetchPromoCodesByQuery = async (): Promise<void> => {
    return api
      .fetchJson({
        url: "/money/promo-codes",
        data,
      })
      .then((json: IPaginationResult<IPromoCode>) => {
        setPromoCodes(json.rows);
        setCount(json.count);
        updateQS();
      });
  };

  const fetchPromoCodesById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/money/promo-codes/${id}`,
      })
      .then((json: IPromoCode) => {
        setPromoCodes([json]);
        setCount(1);
        handleEdit(json)();
      });
  };

  const fetchPromoCodes = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchPromoCodesById(id) : fetchPromoCodesByQuery())
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPromoCodesByQuery = async (): Promise<IPromoCode[]> => {
    try {
      const response = await api.fetchJson({
        url: "/money/promo-codes",
        data,
      });
      return response.rows;
    } catch (error) {

      console.error("Error fetching promo codes:", error);
      throw error;
    }
  };

  async function generateUniquePromoCode(): Promise<string> {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const attempts = 10;
    const existingPromoCodes = await getPromoCodesByQuery();

    // for (let i = 0; i < existingPromoCodes.length; i++)
    //   console.log(existingPromoCodes[i].code);

    for (let i = 0; i < attempts; i++) {
      const code = [...Array(6)].map(() => characters.charAt(Math.floor(Math.random() * characters.length))).join("");

      if (!existingPromoCodes.some((promoCode) => promoCode.code == code)) {
        return code;
      }
    }

    console.warn("Unable to generate unique promo code after 10 attempts.");
    return "";
  }

  const handleAdd = (): void => {
    setSelectedPromoCode(emptyPromoCode);
    setIsEditDialogOpen(true);
  };

  const handleDeleteConfirmed = (promoCode: IPromoCode): Promise<void> => {
    return api
      .fetchJson({
        url: `/money/promo-codes/${promoCode.id}`,
        method: "DELETE",
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: "snackbar.deleted" }), { variant: "success" });
        return fetchPromoCodes();
      })
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsDeleteDialogOpen(false);
      });
  };

  const handleEditConfirmed = (values: Partial<IPromoCode>, formikBag: any): Promise<void> => {
    const { id, ...data } = values;
    return api
      .fetchJson({
        url: id ? `/money/promo-codes/${id}` : "/money/promo-codes/",
        method: id ? "PUT" : "POST",
        // TODO (Future): fix BE validation and FE inputs instead of fixing it here
        data: normalizePromoCode(data),
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: id ? "snackbar.updated" : "snackbar.created" }), { variant: "success" });
        setIsEditDialogOpen(false);
        return fetchPromoCodes();
      })
      .catch((e: ApiError) => {
        if (e.status === 400) {
          formikBag.setErrors(e.getLocalizedValidationErrors());
        } else if (e.status === 404) {
          enqueueSnackbar(formatMessage({ id: `snackbar.promoCodeSaveError404` }), { variant: "error" });
        } else if (e.status === 500) {
          enqueueSnackbar(formatMessage({ id: `snackbar.internalServerError` }), { variant: "error" });
        } else if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      });
  };

  const handleChangePage = (page: number): void => {
    setData({
      ...data,
      skip: page * data.take,
      take: data.take,
    });
  };

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setData({
      ...data,
      skip: 0,
      take: pageSize,
    });
  };

  const handleSubmit = (values: ISearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  const columns: GridColDef[] = [
    {
      field: "status",
      headerName: "",
      align: "center",
      width: 40,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IPromoCode, any>) => {
        const promoCode = params.row;
        return (
          <Fragment>
            {promoCode.activateTimes == 0 ?
              (<CancelOutlinedIcon />) : promoCode.activateTimes < promoCode.activateTimesMax ?
                (<CheckCircleOutlineIcon sx={{ color: green[500] }}/>) : (<CheckCircleIcon sx={{ color: green[500] }} />)}
          </Fragment>
        );
      },
    },
    {
      field: "activations",
      headerName: formatMessage({ id: "form.labels.promoCodeActivations" }),
      align: "center",
      width: 60,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IPromoCode, any>) => {
        const promoCode = params.row;
        return <strong>{promoCode.activateTimes}/{promoCode.activateTimesMax}</strong>;
      },
    },
    {
      field: "code",
      headerName: formatMessage({ id: "form.labels.code" }),
      align: "center",
      width: 80,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IPromoCode, any>) => {
        const promoCode = params.row;
        return <strong>{promoCode.code.toUpperCase()}</strong>;
      },
    },
    {
      field: "rewards",
      headerName: formatMessage({ id: "form.labels.promoCodeRewards" }),
      align: "center",
      minWidth: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IPromoCode, any>) => {
        const promoCode = params.row;
        return (
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            { promoCode.ratePlanType === RatePlanType.SCHOLAR ? (<LockOpenTwoToneIcon sx={{ color: '#009797' }} />) : null}
            { promoCode.ratePlanType === RatePlanType.ESSENTIALS ? (<LockOpenTwoToneIcon sx={{ color: '#4D73FF'}} />) : null}
            { promoCode.ratePlanType === RatePlanType.EXPERT ? (<LockOpenTwoToneIcon sx={{ color: '#9B51E0'}} />) : null}
            { promoCode.lociBonus > 0 ? (<CopyrightTwoToneIcon sx={{ color: '#4BEEB3'}} />) : null}
            { promoCode.moduleData && promoCode.moduleData.length > 0 ? (<SchoolOutlinedIcon />) : null}
            { promoCode.frame !== Frame.NONE ? (<CropOriginalRoundedIcon />) : null}
          </Stack>
        );
      },
    },
    {
      field: "userId",
      headerName: formatMessage({ id: "form.labels.userId" }),
      align: "left",
      width: 80,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IPromoCode, any>) => {
        const promoCode = params.row;
        let userIdString = "-";
        if (promoCode.userId){
          userIdString = promoCode.userId.toString();
        }
        return <strong>{userIdString}</strong>;
      },
    },
    {
      field: "userEmail",
      headerName: formatMessage({ id: "form.labels.userEmail" }),
      align: "left",
      minWidth: 230,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IPromoCode, any>) => {
        const promoCode = params.row;
        let userEmailString = "-";
        if (promoCode.userEmail){
          userEmailString = promoCode.userEmail.toString();
        }
        return <strong>{userEmailString}</strong>;
      },
    },
    {
      field: "userFid",
      headerName: formatMessage({ id: "form.labels.userFid" }),
      align: "left",
      minWidth: 250,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IPromoCode, any>) => {
        const promoCode = params.row;
        let userFidString = "-";
        if (promoCode.userFid){
          userFidString = promoCode.userFid.toString();
        }
        return <strong>{userFidString}</strong>;
      },
    },
    {
      field: "userFbid",
      headerName: formatMessage({ id: "form.labels.userFbid" }),
      align: "left",
      minWidth: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IPromoCode, any>) => {
        const promoCode = params.row;
        let userFbidString = "-";
        if (promoCode.userFbid){
          userFbidString = promoCode.userFbid.toString();
        }
        return <strong>{userFbidString}</strong>;
      },
    },
    {
      field: "actions",
      headerName: formatMessage({ id: "form.labels.actions" }),
      align: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IPromoCode, any>) => {
        const promoCode = params.row;
        return (
          <Fragment>
            <IconButton onClick={handleEdit(promoCode)}>
              <Create />
            </IconButton>
            <IconButton onClick={handleDelete(promoCode)}>
              <Delete />
            </IconButton>
          </Fragment>
        );
      },
    },
  ];

  useDeepCompareEffect(() => {
    void fetchPromoCodes(id);
  }, [data]);

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "promoCodes"]} />

      <PageHeader message="pages.promoCodes.title">
        <Button startIcon={<Add fontSize="large" />} onClick={handleAdd} variant="contained">
          <FormattedMessage id="form.buttons.add" />
        </Button>
      </PageHeader>

      <CommonSearchForm onSubmit={handleSubmit} initialValues={data} />

      <DataGrid
        autoHeight
        pagination
        paginationMode="server"
        rowCount={count}
        pageSize={data.take}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        rows={promoCodes}
        columns={columns}
        disableSelectionOnClick
        loading={isLoading}
      />

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmed}
        open={isDeleteDialogOpen}
        initialValues={selectedPromoCode}
      />

      <EditPromoCodeDialog
        onCancel={handleEditCancel}
        onConfirm={handleEditConfirmed}
        generateUniquePromoCode={generateUniquePromoCode}
        open={isEditDialogOpen}
        initialValues={selectedPromoCode}
      />
    </Grid>
  );
};
