import { FC, Fragment } from "react";

import { NumberInput, TextInput, TextArea } from "@encoderinc/mui-inputs-core";

interface ISliderStepProps {
  name: string;
}

export const SliderStep: FC<ISliderStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <TextInput name={`${name}.sliderPrompt`} />
      <TextInput name={`${name}.sliderQuestion`} />
      <NumberInput name={`${name}.sliderMin`} />
      <NumberInput name={`${name}.sliderMax`} />
      <NumberInput name={`${name}.sliderAnswer`} />
      <TextArea name={`${name}.sliderAnswerText`} />
      <TextArea name={`${name}.sliderWrongAnswerText`} />
    </Fragment>
  );
};
