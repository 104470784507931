import { FC } from "react";
import { Collapse, Grid } from "@mui/material";

import { AutoSave, FormikForm } from "@encoderinc/mui-form";
import { ISolidgateCustomerSearchDto } from "@memoryos/types";
import { SearchInput } from "@encoderinc/mui-inputs-core";
import { DateRangeInput } from "@encoderinc/mui-inputs-picker";

import { useStyles } from "./styles";

interface IOrdersSearchFormProps {
  onSubmit: (values: ISolidgateCustomerSearchDto) => void;
  initialValues: ISolidgateCustomerSearchDto;
  open: boolean;
}

export const SolidgateCustomersSearchForm: FC<IOrdersSearchFormProps> = props => {
  const { onSubmit, initialValues, open } = props;

  const classes = useStyles();

  let { query, isPurchaser, dateRangeCreated, dateRangeUpdated } = initialValues;
  let fixedValues = { query, isPurchaser, dateRangeCreated, dateRangeUpdated };

  return (
    <div className={classes.root}>
      <FormikForm initialValues={fixedValues} onSubmit={onSubmit} showButtons={false} showPrompt={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SearchInput name="query" />
          </Grid>
        </Grid>
        <Collapse in={open}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DateRangeInput name="dateRangeCreated" />
            </Grid>
            <Grid item xs={12}>
              <DateRangeInput name="dateRangeUpdated" />
            </Grid>
          </Grid>
        </Collapse>
        <AutoSave />
      </FormikForm>
    </div>
  );
};
