import { FC } from "react";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { School } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export const Curriculum: FC = () => {
  return (
    <List
      component="nav"
      subheader={
        <ListSubheader>
          <FormattedMessage id="pages.dashboard.curriculum.title" />
        </ListSubheader>
      }
    >
      <ListItem button component={RouterLink} to="/modules">
        <ListItemIcon>
          <School />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="pages.dashboard.curriculum.modules" />
        </ListItemText>
      </ListItem>
      <ListItem button component={RouterLink} to="/lessons">
        <ListItemIcon>
          <School />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="pages.dashboard.curriculum.lessons" />
        </ListItemText>
      </ListItem>
      <ListItem button component={RouterLink} to="/steps">
        <ListItemIcon>
          <School />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="pages.dashboard.curriculum.steps" />
        </ListItemText>
      </ListItem>
      <ListItem button component={RouterLink} to="/answers">
        <ListItemIcon>
          <School />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="pages.dashboard.curriculum.answers" />
        </ListItemText>
      </ListItem>
    </List>
  );
};
