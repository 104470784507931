import { useEffect, RefObject } from "react";

export const useStepValuesBeforeEditSaved = (formik: any, valuesBeforeEdit: RefObject<any[]>, name: string): void => {
  useEffect(() => {
    if (formik.values?.id && valuesBeforeEdit.current) {
      const currentItem = valuesBeforeEdit.current.find((item: { id: any }) => item.id === formik.values.id);
      if (currentItem) {
        formik.setFieldValue(name, JSON.parse(JSON.stringify(currentItem.values)));
      } else {
        valuesBeforeEdit.current.push({
          id: formik.values.id,
          values: JSON.parse(JSON.stringify(formik.values[name])),
        });
      }
    }
  }, []);
};
