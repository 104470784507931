import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  theme => ({
    table: {
      position: "absolute",
      borderCollapse: "collapse",
      width: "100%",
      height: "calc(100% - 6.5px)",
    },
    row: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.light,
    },
    cell: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.light,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        opacity: 0.2,
      },
    },
    cellFromCurrentSelection: {
      backgroundColor: `${theme.palette.primary.light} !important`,
      opacity: `${0.5} !important`,
    },
    selected: {
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      "&:hover": {
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
      },
    },
  }),
  { name: "ImageGrid" },
);
