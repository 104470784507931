import { FC, Fragment, useState, ChangeEvent } from "react";
import { Grid, IconButton, InputAdornment, Tooltip, Typography, Card, CardMedia, Badge } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { ProgressOverlay } from "@encoderinc/mui-progress";
import { FirebaseFileInput } from "@encoderinc/mui-inputs-file-firebase";
import { openUrlOnClick } from "@encoderinc/popup";

import { TextInput } from "@encoderinc/mui-inputs-core";
import { IExpandableListOption } from "@memoryos/types";

import { useStyles } from "./styles";
import { useStepValuesBeforeEditSaved } from "../../../../../../utils/hooks/useStepValuesBeforeEditSaved";

interface IExpandableListStepProps {
  name: string;
  valuesBeforeEdit?: any;
}

export const ExpandableListStep: FC<IExpandableListStepProps> = props => {
  const { name, valuesBeforeEdit } = props;

  const { formatMessage } = useIntl();
  const formik = useFormikContext<any>();
  const classes = useStyles();

  useStepValuesBeforeEditSaved(formik, valuesBeforeEdit, name);

  const [isLoading, setIsLoading] = useState(false);

  const handleOptionAdd =
    (field: string): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].push({
        expandableListOptionTitle: "",
        expandableListOptionText: "",
        expandableListOptionImageUrl: "",
      });
      formik.setFieldValue(name, newValue);
    };

  const handleOptionDelete = (field: string, i: number) => {
    const newValue = formik.values[name];
    newValue[field].splice(i, 1);

    formik.setFieldValue(name, newValue);
  };

  const handleImageDelete = (field: string, i: number) => {
    const newValue = { ...formik.values[name] };
    newValue[field][i].expandableListOptionImageUrl = "";
    formik.setFieldValue(name, newValue);
  };

  const handleFileChange = (urls: Array<string>, i: number): void => {
    setIsLoading(true);
    const newValue = formik.values[name];
    urls.forEach(url => {
      newValue.expandableListOptions[i].expandableListOptionImageUrl = url.split("?")[0];
    });
    formik.setFieldValue(name, newValue);
    setIsLoading(false);
  };

  const handleOptionTitleChange = (e: ChangeEvent<HTMLInputElement>, i: number): void => {
    const newValue = formik.values[name];
    newValue.expandableListOptions[i].expandableListOptionTitle = e.target.value;
    formik.setFieldValue(name, newValue);
  };

  const handleOptionTextChange = (e: ChangeEvent<HTMLInputElement>, i: number): void => {
    const newValue = formik.values[name];
    newValue.expandableListOptions[i].expandableListOptionText = e.target.value;
    formik.setFieldValue(name, newValue);
  };

  return (
    <Fragment>
      <TextInput name={`${name}.expandableListQuestion`} />
      <TextInput name={`${name}.expandableListPrompt`} />

      <Typography>
        <FormattedMessage id="form.labels.expandableListOptions" />
        <Tooltip title={formatMessage({ id: "form.tips.add" })}>
          <IconButton size="large" aria-label="add" onClick={handleOptionAdd("expandableListOptions")}>
            <Add fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>

      {formik.values[name].expandableListOptions.map((option: IExpandableListOption, i: number) => (
        <Grid container key={i}>
          <Grid item>
            <TextInput
              name={`${name}.expandableListOptions[${i}].expandableListOptionTitle`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="delete" onClick={() => handleOptionDelete("expandableListOptions", i)}>
                      <Delete />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleOptionTitleChange(e, i)}
            />
            <TextInput
              multiline
              name={`${name}.expandableListOptions[${i}].expandableListOptionText`}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleOptionTextChange(e, i)}
            />
            {option.expandableListOptionImageUrl ? (
              <Grid container spacing={1} alignItems="flex-end">
                <Card>
                  <Badge badgeContent={i + 1} color="primary" classes={{ badge: classes.badge }}>
                    <CardMedia
                      image={`${option.expandableListOptionImageUrl}?alt=media`}
                      onClick={openUrlOnClick(`${option.expandableListOptionImageUrl}?alt=media`)}
                      className={classes.media}
                    />
                  </Badge>
                </Card>
                <IconButton aria-label="delete" onClick={() => handleImageDelete("expandableListOptions", i)}>
                  <Delete />
                </IconButton>
              </Grid>
            ) : (
              <ProgressOverlay isLoading={isLoading}>
                <FirebaseFileInput
                  onDropAccepted={() => {
                    setIsLoading(true);
                  }}
                  onChange={urls => handleFileChange(urls, i)}
                  classes={{ root: classes.media }}
                  accept={["image/jpeg", "image/png"]}
                />
              </ProgressOverlay>
            )}
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};
