import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  () => ({
    badge: {
      transform: "none",
    },
    media: {
      width: 373,
      height: 300,
    },
  }),
  { name: "ExpandableList" },
);
