import { FC, Fragment, MouseEvent } from "react";
import { useStyles } from "./styles";
import clsx from "clsx";

interface IImageGridProps {
  rowsCount: number;
  columnsCount: number;
  onCellClick: (e: MouseEvent<HTMLTableDataCellElement>, row: number, column: number) => void;
  isCellSelected: (row: number, column: number) => boolean;
  cellsFromCurrentSelection: string[];
}

export const ImageGrid: FC<IImageGridProps> = props => {
  const { rowsCount, columnsCount, cellsFromCurrentSelection, onCellClick, isCellSelected } = props;
  const rows = Array.from(Array(rowsCount).keys()).map(i => i + 1);
  const cols = Array.from(Array(columnsCount).keys()).map(i => i + 1);

  const classes = useStyles();

  const isCellFromCurrentSelection = (row: number, column: number) => {
    return cellsFromCurrentSelection.includes(`${row};${column}`);
  };

  return (
    <Fragment>
      <table className={classes.table}>
        {rows.map(x => (
          <tr key={x} className={classes.row}>
            {cols.map(y => (
              <td
                key={y}
                onClick={e => onCellClick(e, x, y)}
                className={clsx(classes.cell, {
                  [classes.cellFromCurrentSelection]: isCellFromCurrentSelection(x, y),
                  [classes.selected]: isCellSelected(x, y),
                })}
              />
            ))}
          </tr>
        ))}
      </table>
    </Fragment>
  );
};
