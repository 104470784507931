import * as Yup from "yup";
import { Term, Frame, Currency, ProductsType, RatePlanType } from "@memoryos/types";

function validateProductItems(this: any): any {
  const { originalValue: values, createError, path, resolve } = this;
  if (
    !values.lociBonus &&
    values.ratePlanType === RatePlanType.GUEST &&
    (!values.moduleData || values.moduleData.length === 0) &&
    values.frame === Frame.NONE
  ) {
    return createError({
      path: `${path as string}.lociBonus`,
      message: "form.validations.productsItemValuesMissing",
    });
  }

  if (values.ratePlanType !== RatePlanType.GUEST && values.term === Term.NONE) {
    return createError({
      path: `${path as string}.ratePlanType`,
      message: "form.validations.subscriptionTermMissing",
    });
  }

  if (values.ratePlanType === RatePlanType.GUEST && values.term !== Term.NONE) {
    return createError({
      path: `${path as string}.ratePlanType`,
      message: "form.validations.subscriptionTypeMissing",
    });
  }

  return resolve(true);
}

function validateSolidgateAmount(this: any): any {
  const { from, createError, path, resolve } = this;
  const values = from[0]?.value;
  if (values.type && values.type === ProductsType.PROMOCODE && !values.solidgateAmount) {
    return createError({
      path: `${path as string}`,
      message: "form.validations.valueMissing",
    });
  }

  return resolve(true);
}

function validateSolidgateCurrency(this: any): any {
  const { from, createError, path, resolve } = this;
  const values = from[0]?.value;
  let error = "";
  if (values.type && values.type === ProductsType.PROMOCODE && !values.solidgateCurrency) {
    error = "form.validations.valueMissing";
  }

  if (
    values.type &&
    values.type === ProductsType.PROMOCODE &&
    !Object.values(Currency).includes(values.solidgateCurrency)
  ) {
    error = "form.validations.badInput";
  }

  if (error) {
    return createError({
      path: `${path as string}`,
      message: error,
    });
  }

  return resolve(true);
}

const schema = {
  solidgateProductId: Yup.string().required("form.validations.valueMissing"),
  name: Yup.string().required("form.validations.valueMissing"),
  type: Yup.mixed()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(ProductsType), "form.validations.badInput"),
  solidgateDescription: Yup.string().required("form.validations.valueMissing"),
  items: Yup.array().of(Yup.mixed().test({ name: "atLeastOneRequired", test: validateProductItems })),
  solidgateAmount: Yup.number().test({ name: "requiredIfPromocode", test: validateSolidgateAmount }),
  solidgateCurrency: Yup.mixed().test({ name: "requiredIfPromocode", test: validateSolidgateCurrency }),
  ratePlanType: Yup.mixed()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(RatePlanType), "form.validations.badInput"),
  termType: Yup.mixed()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(Term), "form.validations.badInput"),
};
export const validationSchema = Yup.object().shape(schema);
