import * as Yup from "yup";
import { StepStatus, StepTimeoutBehavior } from "@memoryos/types";
import { validateTimeLimit } from "./validate.time.limit";

export const basicValidationSchema = Yup.object().shape({
  title: Yup.string().required("form.validations.valueMissing"),
  stepStatus: Yup.mixed().oneOf(Object.values(StepStatus), "form.validations.badInput"),
  timeoutBehavior: Yup.mixed()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(StepTimeoutBehavior), "form.validations.badInput"),
  timeLimit: Yup.number().test({ name: "requiredIfTimeoutBehavior", test: validateTimeLimit }),
});
