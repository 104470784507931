import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  theme => ({
    appbar: {
      backgroundColor: "#3C75E6",
    },
    toolbar: {
      minHeight: 64,
    },
    grow: {
      flexGrow: 1,
    },
    title: {
      color: theme.palette.common.white,
      textDecoration: "none",
      fontWeight: 500,
      fontSize: 36,
      "&:hover": {
        textDecoration: "none",
      },
    },
    logo: {
      display: "inline-block",
      verticalAlign: "middle",
      height: 40,
      width: 62,
      marginRight: theme.spacing(1),
    },
  }),
  { name: "Header" },
);
