import { FC, Fragment, useContext, useState, useEffect } from "react";

import { ApiContext, IApiContext, IJwt } from "@encoderinc/provider-api";
import { IUserContext, UserContext } from "@encoderinc/provider-user";
import { getAuth } from "firebase/auth";
import firebase from "@encoderinc/firebase";

import { FirebaseLogin } from "@memoryos/firebase-login";
import { IUser } from "@memoryos/types";

export const Protected: FC = props => {
  const { children } = props;

  const [isReady, setIsReady] = useState(false);
  const user = useContext<IUserContext<IUser>>(UserContext);
  const api = useContext<IApiContext<IJwt>>(ApiContext);

  useEffect(() => {
    const id = setTimeout(() => setIsReady(true), 100);
    return () => clearTimeout(id);
  }, []);

  const token = api.getToken();

  if (token && token.refreshTokenExpiresAt < Date.now()) {
    const authFb = getAuth(firebase);
    void authFb.currentUser
      ?.getIdToken(true)
      .then(accessToken => {
        const now = Date.now();
        api.setToken({
          accessToken,
          accessTokenExpiresAt: now + 1000 * 60 * 60,
          refreshToken: "",
          refreshTokenExpiresAt: now + 1000 * 60 * 30,
        });
        return true;
      })
      .catch(console.error);
  }
  if (!isReady) {
    return null;
  }

  if (!user.isAuthenticated()) {
    return <FirebaseLogin />;
  }

  return <Fragment>{children}</Fragment>;
};
