import { FC, Fragment } from "react";

import { FormDialog } from "@encoderinc/mui-dialog-form";
import { Grid, IconButton, InputAdornment, Tooltip, Typography, Divider } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Add, Delete } from "@mui/icons-material";
import { NumberInput, SelectInput, TextInput } from "@encoderinc/mui-inputs-core";
import { IProducts, IProductsItem, Term, Currency, Frame, ProductsType, RatePlanType } from "@memoryos/types";
import { ProductsTypeInput } from "./products-type-input";
import { DynamicSelectInput } from "../../../../components/common/inputs/select";

import { validationSchema } from "./validation";

export interface IEditPromoCodeDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: Partial<IProducts>, formikBag: any) => Promise<void>;
  initialValues: IProducts;
  handleOptionAdd: any;
  handleOptionDelete: (i: number) => void;
  productType: ProductsType;
  setProductType: (type: ProductsType) => void;
}

export const EditProductsDialog: FC<IEditPromoCodeDialogProps> = props => {
  const { initialValues, handleOptionAdd, handleOptionDelete, productType, setProductType, ...rest } = props;
  const {
    solidgateProductId,
    solidgateAmount,
    solidgateCurrency,
    solidgateDescription,
    items,
    id,
    type,
    name,
    ratePlanType,
    termType,
  } = initialValues;
  const fixedValues = {
    id,
    solidgateProductId,
    solidgateAmount,
    solidgateCurrency,
    solidgateDescription,
    items,
    type,
    name,
    ratePlanType,
    termType,
  };
  if (!solidgateAmount) {
    fixedValues.solidgateAmount = 0;
  }
  const { formatMessage } = useIntl();
  const message = id ? "dialogs.edit" : "dialogs.add";

  const renderFields = () => {
    switch (productType) {
      case ProductsType.PROMOCODE:
        return (
          <Fragment>
            <NumberInput name="solidgateAmount" />
            <SelectInput name="solidgateCurrency" options={Currency} />
            <br />
            <br />
            <Typography>
              <FormattedMessage id="form.labels.productsItems" />
              <Tooltip title={formatMessage({ id: "form.tips.add" })}>
                <IconButton size="large" aria-label="add" onClick={handleOptionAdd()}>
                  <Add fontSize="large" color="primary" />
                </IconButton>
              </Tooltip>
            </Typography>
            <br />
            {items?.map((option: IProductsItem, i: number) => (
              <Fragment key={i}>
                <Grid container>
                  <Grid item>
                    <NumberInput
                      name={`items[${i}].lociBonus`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="delete" onClick={() => handleOptionDelete(i)}>
                              <Delete />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <DynamicSelectInput name={`items[${i}].ratePlanType`} options={RatePlanType} />
                    <DynamicSelectInput name={`items[${i}].term`} options={Term} />
                    <DynamicSelectInput name={`items[${i}].frame`} options={Frame} />
                  </Grid>
                </Grid>

                <br />
                <Divider />
                <br />
              </Fragment>
            ))}
          </Fragment>
        );
      case ProductsType.SUBSCRIPTON:
        return (
          <Fragment>
            <SelectInput name="ratePlanType" options={RatePlanType} />
            <SelectInput name="termType" options={Term} />
          </Fragment>
        );
      default:
        return null;
    }
  };
  return (
    <FormDialog initialValues={fixedValues} validationSchema={validationSchema} message={message} {...rest}>
      <TextInput name="solidgateProductId" />
      <TextInput name="name" />
      <TextInput name="solidgateDescription" />
      <ProductsTypeInput productType={productType} setProductType={setProductType} />
      {renderFields()}
    </FormDialog>
  );
};
