import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  () => ({
    section: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 64px)",
      width: 250,
      margin: "0 auto",
    },
    button: {
      position: "absolute",
      right: 0,
    },
    hint: {
      padding: "5px 0px",
      fontSize: "15px",
    },
  }),
  { name: "UserEdit" },
);
