import * as Yup from "yup";
import {
  StepStatus,
  PlayingCardsRecallPhaseMode,
  PlayingCardsMemorizingPhaseMode,
  PlayingCardsSequenceType,
  StepTimeoutBehavior,
} from "@memoryos/types";
import { validateTimeLimit } from "./validate.time.limit";

const playingCardsDataSchema = {
  playingCardsSequenceType: Yup.string()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(PlayingCardsSequenceType), "form.validations.badInput"),
  playingCardsSequence: Yup.array().required("form.validations.valueMissing"),
  playingCardsCompetitiveMode: Yup.boolean().required("form.validations.valueMissing"),
  playingCardsRecallPhaseMode: Yup.string()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(PlayingCardsRecallPhaseMode), "form.validations.badInput"),
  playingCardsMemorizingPhaseMode: Yup.string()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(PlayingCardsMemorizingPhaseMode), "form.validations.badInput"),
  playingCardsMemorizingPhaseModeInterval: Yup.number()
    .required("form.validations.valueMissing")
    // @ts-ignore
    .when("playingCardsMemorizingPhaseMode", playingCardsMemorizingPhaseMode => {
      if (playingCardsMemorizingPhaseMode === PlayingCardsMemorizingPhaseMode.TIMER) {
        return Yup.number()
          .required("form.validations.valueMissing")
          .min(1, "form.validations.minPlayingCardsMemorizingPhaseModeInterval");
      }
    }),
};

export const playingCardsValidationSchema = Yup.object().shape({
  title: Yup.string().required("form.validations.valueMissing"),
  stepStatus: Yup.mixed().oneOf(Object.values(StepStatus), "form.validations.badInput"),
  data: Yup.object().shape(playingCardsDataSchema),
  timeoutBehavior: Yup.mixed()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(StepTimeoutBehavior), "form.validations.badInput"),
  timeLimit: Yup.number().test({ name: "requiredIfTimeoutBehavior", test: validateTimeLimit }),
});
