import * as Yup from "yup";
import {
  StepStatus,
  StepTimeoutBehavior,
  SimpleInputStepValidationMode,
  SimpleInputStepRepresentationMode,
} from "@memoryos/types";
import { validateTimeLimit } from "./validate.time.limit";

function validateSimpleInputQuestion(this: any): any {
  const { parent: values, createError, path, resolve } = this;
  if (!values.simpleInputQuestion && !values.simpleInputQuestionImageUrl) {
    return createError({
      path: path,
      message: "form.validations.simpleInputQuestionAtLeastOneRequired",
    });
  }

  return resolve(true);
}

function validateSimpleInputImageQuestion(this: any): any {
  const { parent: values, createError, path, resolve } = this;
  if (!values.simpleInputQuestion && !values.simpleInputQuestionImageUrl) {
    return createError({
      path: path,
      message: "form.validations.simpleInputQuestionAtLeastOneRequired",
    });
  }

  return resolve(true);
}

const simpleInputDataSchema = {
  simpleInputPrompt: Yup.string(),
  simpleInputAnswer: Yup.string().required("form.validations.valueMissing"),
  simpleInputValidationMode: Yup.string()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(SimpleInputStepValidationMode), "form.validations.badInput"),
  simpleInputRepresentationMode: Yup.string()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(SimpleInputStepRepresentationMode), "form.validations.badInput"),
  simpleInputQuestion: Yup.mixed().test({
    name: "atLeastOneRequired",
    test: validateSimpleInputQuestion,
  }),
  simpleInputQuestionImageUrl: Yup.mixed().test({
    name: "atLeastOneRequired",
    test: validateSimpleInputImageQuestion,
  }),
};

export const simpleInputValidationSchema = Yup.object().shape({
  title: Yup.string().required("form.validations.valueMissing"),
  stepStatus: Yup.mixed().oneOf(Object.values(StepStatus), "form.validations.badInput"),
  data: Yup.object().shape(simpleInputDataSchema),
  timeoutBehavior: Yup.mixed()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(StepTimeoutBehavior), "form.validations.badInput"),
  timeLimit: Yup.number().test({ name: "requiredIfTimeoutBehavior", test: validateTimeLimit }),
});
