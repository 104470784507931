import { FC, Fragment } from "react";
import { Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { getIn, useFormikContext } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

import { TextInput } from "@encoderinc/mui-inputs-core";
import { IWordsListOption } from "@memoryos/types";
import { useStepValuesBeforeEditSaved } from "../../../../../../utils/hooks/useStepValuesBeforeEditSaved";

interface IWordsListStepProps {
  name: string;
  valuesBeforeEdit?: any;
}

export const WordsListStep: FC<IWordsListStepProps> = props => {
  const { name, valuesBeforeEdit } = props;

  const { formatMessage } = useIntl();

  const formik = useFormikContext<any>();
  const value = getIn(formik.values, name);

  useStepValuesBeforeEditSaved(formik, valuesBeforeEdit, name);

  const handleOptionAdd =
    (field: string): (() => void) =>
    (): void => {
      const newValue = getIn(formik.values, name);
      newValue[field].push({
        wordsListOptionText: "",
      });
      formik.setFieldValue(name, newValue);
    };

  const handleOptionDelete =
    (field: string, i: number): (() => void) =>
    (): void => {
      const newValue = getIn(formik.values, name);
      newValue[field].splice(i, 1);
      formik.setFieldValue(name, newValue);
    };

  return (
    <Fragment>
      <TextInput name={`${name}.wordsListPrompt`} />
      <Typography>
        <FormattedMessage id="form.labels.wordsListOptions" />
        <Tooltip title={formatMessage({ id: "form.tips.add" })}>
          <IconButton size="large" aria-label="add" onClick={handleOptionAdd("wordsListOptions")}>
            <Add fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>

      {value.wordsListOptions.map((_o: IWordsListOption, i: number) => (
        <Grid container key={i}>
          <Grid item>
            <TextInput
              name={`${name}.wordsListOptions[${i}].wordsListOptionText`}
              InputProps={{
                startAdornment: <InputAdornment position="start">{i + 1}</InputAdornment>,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="delete" onClick={handleOptionDelete("wordsListOptions", i)}>
                      <Delete />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};
