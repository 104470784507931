import { FC, ReactElement, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useFormikContext, getIn } from "formik";
import { useSnackbar } from "notistack";

import { RadioProps, Autocomplete, TextField, TextFieldProps } from "@mui/material";

import { ProgressOverlay } from "@encoderinc/mui-progress";
import { ApiContext, IApiContext } from "@encoderinc/provider-api";

type option = {
  id: number;
  title: string;
};

export interface IEntityInput {
  name: string;
  controller: string;
  getTitle?: (option: option) => string;
  multiple?: boolean;
  data?: any;
  variant?: "standard" | "filled" | "outlined" | undefined;
  label?: string;
  onChange?: () => any;
  callback?: (values: any) => any;
  disabled?: boolean;
}
export const EntityInputV2: FC<IEntityInput & RadioProps> = props => {
  const {
    name,
    controller,
    getTitle,
    multiple,
    data,
    variant = "standard",
    onChange,
    label,
    callback,
    disabled = false,
  } = props;
  const suffix = name.split(".").pop() as string;
  const formik = useFormikContext<any>();
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);
  const value = getIn(formik.values, name);
  const { formatMessage } = useIntl();
  const localizedLabel = label ?? formatMessage({ id: `form.labels.${suffix}` });
  const localizedHelperText = error && touched ? formatMessage({ id: error }, { label: localizedLabel }) : "";
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const api: IApiContext<any> = useContext(ApiContext);
  const fetchOptions = async () => {
    setIsLoading(true);
    return api
      .fetchJson({
        url: `/${controller}/autocomplete`,
        data,
      })
      .then((json: any) => {
        setOptions(json);
      })
      .catch(e => {
        console.error(e);
        enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    void fetchOptions();
  }, [data]);
  if (multiple) {
    return (
      <ProgressOverlay isLoading={isLoading}>
        <Autocomplete
          disabled={disabled}
          multiple={true}
          options={options}
          value={value.map((v: any) => options.find((o: option) => o.id === v)).filter((e: option) => e)}
          onChange={
            onChange ||
            ((_event, options: option[]) => {
              const value = options ? options.map(option => option.id) : [];
              formik.setFieldValue(name, value);
              if (typeof callback === "function") {
                callback(options);
              }
            })
          }
          getOptionLabel={(option: option) => (typeof getTitle === "function" ? getTitle(option) : option.title)}
          renderInput={(props: TextFieldProps): ReactElement => (
            <TextField
              label={localizedLabel}
              placeholder={formatMessage({ id: `form.placeholders.${suffix}` })}
              error={!!error}
              helperText={localizedHelperText}
              variant={variant}
              fullWidth={true}
              {...props}
            />
          )}
        />
      </ProgressOverlay>
    );
  } else {
    return (
      <ProgressOverlay isLoading={isLoading}>
        <Autocomplete
          disabled={disabled}
          multiple={false}
          options={options}
          value={options.find((option: option) => value === option.id) || null}
          onChange={
            onChange ||
            ((_event, option: { id: number } | null) => {
              const value = option ? option.id : null;
              formik.setFieldValue(name, value);
            })
          }
          getOptionLabel={(option: option) => (typeof getTitle === "function" ? getTitle(option) : option.title)}
          renderInput={(props: TextFieldProps): ReactElement => (
            <TextField
              label={localizedLabel}
              placeholder={formatMessage({ id: `form.placeholders.${suffix}` })}
              error={!!error}
              helperText={localizedHelperText}
              variant={variant}
              fullWidth={true}
              {...props}
            />
          )}
        />
      </ProgressOverlay>
    );
  }
};
