import { ChangeEvent, FC, Fragment, useState, useEffect, MouseEvent } from "react";
import clsx from "clsx";

import { TextInput } from "@encoderinc/mui-inputs-core";
import { useSnackbar } from "notistack";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Grid, IconButton, InputAdornment, Tooltip, Typography, SelectChangeEvent } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { ProgressOverlay } from "@encoderinc/mui-progress";
import { FormattedMessage, useIntl } from "react-intl";
import { FirebaseFileInput } from "@encoderinc/mui-inputs-file-firebase";
import { usePrevious } from "../../../../../../utils/hooks/usePrevious";
import { ImageGrid } from "../../../../../../components/common/image-grid";
import { DeleteDialog } from "@encoderinc/mui-dialog-delete";
import { DynamicSelectInput, ISelectOption } from "../../../../../../components/common/inputs/select";
import { Heading } from "../../../../../../components/common/heading";

import { useStyles } from "./styles";
import {
  IImageMapAllObjectsOption,
  ImageMapImageGridSize,
  ImageMapStepValidationMode,
  IStep,
  IImageMapStepData,
  IImageMapAnswersObjectsOption,
} from "@memoryos/types";
import { useStepValuesBeforeEditSaved } from "../../../../../../utils/hooks/useStepValuesBeforeEditSaved";

const defaultImageWidth = 450;
enum ImageMapStepImageWidthMultiplier {
  "1x" = 1,
  "1.5x" = 1.5,
  "2x" = 2,
}
enum DeleteActionType {
  GRID = "GRID",
  IMAGE = "IMAGE",
}

interface IImageMapStepProps {
  name: string;
  initialValues: IStep;
  valuesBeforeEdit?: any;
}

interface IGridSize {
  x: number;
  y: number;
}

export const ImageMapStep: FC<IImageMapStepProps> = props => {
  const { name, initialValues, valuesBeforeEdit } = props;
  const data = initialValues.data as IImageMapStepData;
  const initialGridSize = data.imageMapImageGridSize || ImageMapImageGridSize["9x9"];

  const { formatMessage } = useIntl();
  const formik = useFormikContext<any>();
  const { touched, errors } = formik;
  const classes = useStyles();

  const [isImageLoading, setIsImageLoading] = useState(false);
  const prevIsImageLoading = usePrevious(isImageLoading);
  const [gridSize, setGridSize] = useState({
    x: Number(initialGridSize.split("x")[0]),
    y: Number(initialGridSize.split("x")[1]),
  });
  const prevGridSize = usePrevious(gridSize) as IGridSize;
  const imageUrl: string = formik.values[name].imageMapImageUrl;
  const [isGridApplied, setIsGridApplied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cellsFromCurrentSelection, setCellsFromCurrentSelection] = useState<Array<string>>([]);
  const [currentlySelectedOption, setCurrentlySelectedOption] = useState<null | number>(null);
  const prevCurrentlySelectedOption = usePrevious(currentlySelectedOption);
  const [selectedCells, setSelectedCells] = useState<Array<string>>([]);
  const [imageMapAnswersObjectsCellsOptions, setImageMapAnswersObjectsCellsOptions] = useState<Array<ISelectOption>>(
    [],
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [gridSizeSelectBoxEventValue, setGridSizeSelectBoxEventValue] = useState<string | null>(null);
  const [deleteActionType, setDeleteActionType] = useState<DeleteActionType | null>(null);
  useStepValuesBeforeEditSaved(formik, valuesBeforeEdit, name);
  const { enqueueSnackbar } = useSnackbar();
  const [imageWidth, setImageWidth] = useState<string>(defaultImageWidth.toString() + "px");

  useDeepCompareEffect(() => {
    // @ts-ignore
    const imageMapAllObjectsErrors = errors?.data?.imageMapAllObjectsOptions;
    if (imageMapAllObjectsErrors?.length) {
      for (const error of imageMapAllObjectsErrors) {
        if (error?.imageMapAllObjectsOptionCells === "form.validations.imageMapAllObjectsCellsValuesDuplicated") {
          enqueueSnackbar(formatMessage({ id: "snackbar.imageMapAllObjectsCellsValuesDuplicated" }), {
            variant: "error",
          });

          break;
        }
      }
    }
  }, [errors]);

  const setCellsFromCurrentSelectionFromFormikValues = (i: number) => {
    const selectedCellsFromFormik = formik?.values[name]?.imageMapAllObjectsOptions[i]?.imageMapAllObjectsOptionCells;
    if (selectedCellsFromFormik?.length) {
      setCellsFromCurrentSelection(selectedCellsFromFormik.split(", "));
    }
  };

  const isDefaultOptionOnly =
    formik.values[name].imageMapAllObjectsOptions.length === 1 &&
    !formik.values[name].imageMapAllObjectsOptions[0].imageMapAllObjectsOptionItem &&
    !formik.values[name].imageMapAllObjectsOptions[0].imageMapAllObjectsOptionCells.length;

  const isCellSelected = (row: number, column: number) => {
    return selectedCells.includes(`${row};${column}`);
  };

  const setSelectedCellsFromFormikValues = () => {
    const allObjectsOptions = formik.values[name].imageMapAllObjectsOptions;
    if (allObjectsOptions?.length) {
      const selectedCellsFromFormik: string[] = [];
      allObjectsOptions.filter((option: IImageMapAllObjectsOption, i: number) => {
        if (i !== currentlySelectedOption && option.imageMapAllObjectsOptionCells) {
          option.imageMapAllObjectsOptionCells
            .split(", ")
            .forEach((cords: string) => selectedCellsFromFormik.push(cords));
        }
        return false;
      });
      setSelectedCells(selectedCellsFromFormik);
    }
  };

  useEffect(() => {
    if (currentlySelectedOption !== null && currentlySelectedOption !== prevCurrentlySelectedOption) {
      setCellsFromCurrentSelection([]);
      setCellsFromCurrentSelectionFromFormikValues(currentlySelectedOption);
      setSelectedCellsFromFormikValues();
    }
  }, [currentlySelectedOption]);

  const generateImageMapAnswersCellsOptions = () => {
    const allObjectsOptions = formik.values[name].imageMapAllObjectsOptions;
    if (allObjectsOptions?.length) {
      const objectAnswersOptions: ISelectOption[] = [];
      allObjectsOptions.forEach((option: IImageMapAllObjectsOption) => {
        if (option.imageMapAllObjectsOptionCells || option.imageMapAllObjectsOptionCells) {
          objectAnswersOptions.push({
            label: `${option.imageMapAllObjectsOptionItem || ""}(${option.imageMapAllObjectsOptionCells || ""})`,
            value: option.imageMapAllObjectsOptionCells || "",
          });
        }
      });
      setImageMapAnswersObjectsCellsOptions(objectAnswersOptions);
    }
  };

  useEffect(() => {
    generateImageMapAnswersCellsOptions();
  }, [selectedCells, cellsFromCurrentSelection]);

  const handleImageGridSizeInputChange = () => {
    if (gridSizeSelectBoxEventValue) {
      setGridSize({
        x: Number(gridSizeSelectBoxEventValue.split("x")[0]),
        y: Number(gridSizeSelectBoxEventValue.split("x")[1]),
      });
    }
  };

  useEffect(() => {
    if (gridSize) {
      const currentGridSize = `${gridSize.x}x${gridSize.y}`;
      const previousGridSize = `${prevGridSize?.x}x${prevGridSize?.y}`;
      if (currentGridSize !== previousGridSize) {
        formik.setFieldValue(`${name}.imageMapImageGridSize`, `${gridSize.x}x${gridSize.y}`);

        switch (currentGridSize) {
          case "3x3":
          case "9x9":
            setImageWidth(Math.round(defaultImageWidth * ImageMapStepImageWidthMultiplier["1x"]).toString() + "px");
            break;
          case "25x25":
            setImageWidth(Math.round(defaultImageWidth * ImageMapStepImageWidthMultiplier["1.5x"]).toString() + "px");
            break;
          case "35x35":
          case "40x40":
          case "50x50":
            setImageWidth(Math.round(defaultImageWidth * ImageMapStepImageWidthMultiplier["2x"]).toString() + "px");
            break;
        }
      }
    }
  }, [gridSize]);

  const handleFileChange = (urls: Array<string>): void => {
    let imageMapUrl = "";
    urls.forEach(url => {
      imageMapUrl = url.split("?")[0];
    });
    formik.setFieldValue(`${name}.imageMapImageUrl`, imageMapUrl);
    setIsImageLoading(false);
  };

  const clearOptionsAndSetDefault = () => {
    formik.setFieldValue(`${name}.imageMapAllObjectsOptions`, [
      {
        imageMapAllObjectsOptionCells: "",
        imageMapAllObjectsOptionItem: "",
      },
    ]);

    formik.setFieldValue(`${name}.imageMapAnswersObjectsOptions`, [
      {
        imageMapAnswersObjectsOptionCells: "",
        imageMapAnswersObjectsOptionItem: "",
      },
    ]);

    setCellsFromCurrentSelection([]);
    setSelectedCells([]);
    setCurrentlySelectedOption(0);
  };

  const handleImageDelete = () => {
    setSelectedCells([]);
    const oldValue = formik.values[name];
    formik.setFieldValue(name, {
      ...oldValue,
      imageMapImageUrl: "",
      imageMapAllObjectsOptions: [],
      imageMapAnswersObjectsOptions: [],
    });

    setIsGridApplied(false);
    setCellsFromCurrentSelection([]);
    setCurrentlySelectedOption(null);
  };

  const updateGrid = () => {
    setIsGridApplied(true);
    setIsLoading(false);
    if (initialValues.id && data.imageMapAllObjectsOptions?.length) {
      setSelectedCellsFromFormikValues();
    } else if (!selectedCells.length && data.imageMapAllObjectsOptions?.length) {
      let currentSelectedCells: string[] = [];
      data.imageMapAllObjectsOptions.forEach(option => {
        currentSelectedCells = [...currentSelectedCells, ...option.imageMapAllObjectsOptionCells.split(", ")];
      });
      setSelectedCells(currentSelectedCells);
    } else {
      clearOptionsAndSetDefault();
    }
  };

  useEffect(() => {
    // new image uploaded or grid size changed
    const shouldUpdateGrid =
      prevGridSize?.x !== gridSize.x || prevGridSize?.y !== gridSize.y || (prevIsImageLoading && !isImageLoading);
    if (shouldUpdateGrid && imageUrl) {
      updateGrid();
    }
  }, [isImageLoading, gridSize]);

  const handleOptionAdd =
    (field: string): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      field === "imageMapAllObjectsOptions"
        ? newValue[field].push({
            imageMapAllObjectsOptionItem: "",
            imageMapAllObjectsOptionCells: "",
          })
        : newValue[field].push({
            imageMapAnswersObjectsOptionItem: "",
            imageMapAnswersObjectsOptionCells: "",
          });
      formik.setFieldValue(name, newValue);
      setCurrentlySelectedOption(newValue[field].length - 1);
    };

  const handleOptionDelete = (field: string, i: number) => {
    const newValue = formik.values[name];
    newValue[field].splice(i, 1);
    formik.setFieldValue(name, newValue);
    if (field === "imageMapAllObjectsOptions") {
      if (newValue[field].length === 0) {
        clearOptionsAndSetDefault();
      } else if (i === currentlySelectedOption) {
        setCurrentlySelectedOption(0);
        setCellsFromCurrentSelection([]);
      } else {
        setCurrentlySelectedOption(0);
        setCellsFromCurrentSelectionFromFormikValues(0);
      }
    }
  };

  const handleOptionItemChange = (
    e: ChangeEvent<HTMLInputElement>,
    i: number,
    field: string,
    property: string,
  ): void => {
    const newValue = formik.values[name];
    newValue[field][i][property] = e.target.value;
    formik.setFieldValue(name, newValue);
    if (property === "imageMapAllObjectsOptionItem") {
      generateImageMapAnswersCellsOptions();
    }
  };

  const onCellClick = (event: MouseEvent<HTMLTableDataCellElement>, row: number, column: number) => {
    const cell = `${row};${column}`;
    let updatedCellsFromCurrentSelection = [...cellsFromCurrentSelection];
    if (cellsFromCurrentSelection.includes(cell)) {
      updatedCellsFromCurrentSelection = updatedCellsFromCurrentSelection.filter(c => c !== cell);
    } else {
      updatedCellsFromCurrentSelection.push(cell);
    }
    const newValue = formik.values[name];
    const optionIndex = currentlySelectedOption as number;
    newValue.imageMapAllObjectsOptions[optionIndex].imageMapAllObjectsOptionCells =
      updatedCellsFromCurrentSelection.reduce((memo, current, i) => {
        return i === 0 ? current : `${memo}, ${current}`;
      }, "");

    formik.setFieldValue(name, newValue);
    setCellsFromCurrentSelection(updatedCellsFromCurrentSelection);
  };

  const openDeleteDialog = (actionType: DeleteActionType, event?: string) => {
    if (actionType === DeleteActionType.GRID) {
      setGridSizeSelectBoxEventValue(event as string);
    }
    setIsDeleteDialogOpen(true);
    setDeleteActionType(actionType);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
    setDeleteActionType(null);
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleDeleteConfirmed = async () => {
    switch (deleteActionType) {
      case DeleteActionType.IMAGE:
        handleImageDelete();
        break;
      case DeleteActionType.GRID:
        handleImageGridSizeInputChange();
        clearOptionsAndSetDefault();
        break;
      default:
        break;
    }

    setIsDeleteDialogOpen(false);
    setDeleteActionType(null);
  };

  const getDeleteDialogTitle = () => {
    switch (deleteActionType) {
      case DeleteActionType.IMAGE:
        return formatMessage({ id: "form.tips.deleteImageMapImage" });
      case DeleteActionType.GRID:
        return formatMessage({ id: "form.tips.deleteImageMapGrid" });
      default:
        return "";
    }
  };

  const isAnyObjectsDefined = !isDefaultOptionOnly;
  return (
    <Fragment>
      <DynamicSelectInput name={`${name}.imageMapValidationMode`} options={ImageMapStepValidationMode} />

      <Heading textOrLocalizationId="form.labels.imageMapFirstStep" />

      <DynamicSelectInput
        name={`${name}.imageMapImageGridSize`}
        options={ImageMapImageGridSize}
        onChange={(e: SelectChangeEvent<unknown>) => {
          openDeleteDialog(DeleteActionType.GRID, e.target.value as ImageMapImageGridSize);
        }}
      />

      <Grid container spacing={1} alignItems="flex-end" classes={{ root: classes.imageContainer }}>
        {isGridApplied ? (
          <Fragment>
            <div className={classes.card} style={{ width: imageWidth }}>
              <ImageGrid
                rowsCount={gridSize.x}
                columnsCount={gridSize.y}
                cellsFromCurrentSelection={cellsFromCurrentSelection}
                onCellClick={onCellClick}
                isCellSelected={isCellSelected}
              />
              <img src={`${imageUrl}?alt=media`} className={classes.media} />
            </div>
            <IconButton aria-label="delete" onClick={() => openDeleteDialog(DeleteActionType.IMAGE)}>
              <Delete />
            </IconButton>
          </Fragment>
        ) : (
          <ProgressOverlay isLoading={isLoading}>
            <FirebaseFileInput
              accept={["image/jpeg", "image/png"]}
              onDropAccepted={() => {
                setIsLoading(true);
                setIsImageLoading(true);
                setIsGridApplied(false);
              }}
              onChange={urls => {
                formik.setFieldTouched(`${name}.imageMapImageUrl`);
                handleFileChange(urls);
              }}
              classes={{ root: classes.fileInput }}
            />
            {/* @ts-ignore */}
            {errors?.data?.imageMapImageUrl && touched?.data?.imageMapImageUrl && (
              <div className={classes.errorHint}>
                {/* @ts-ignore */}
                {formatMessage({ id: errors.data.imageMapImageUrl }, { label: "Image" })}
              </div>
            )}
          </ProgressOverlay>
        )}
      </Grid>

      {isGridApplied && (
        <Fragment>
          {isGridApplied && <Heading textOrLocalizationId="form.labels.imageMapSecondStep" />}

          <Typography>
            <FormattedMessage id="form.labels.imageMapAllObjectsOptions" />
            <Tooltip title={formatMessage({ id: "form.tips.add" })}>
              <IconButton size="large" aria-label="add" onClick={handleOptionAdd("imageMapAllObjectsOptions")}>
                <Add fontSize="large" color="primary" />
              </IconButton>
            </Tooltip>
          </Typography>

          {formik.values[name].imageMapAllObjectsOptions.map((option: IImageMapAllObjectsOption, i: number) => (
            <Grid container key={i}>
              <Grid item>
                <TextInput
                  name={`${name}.imageMapAllObjectsOptions[${i}].imageMapAllObjectsOptionItem`}
                  InputProps={{
                    endAdornment: (
                      <Fragment>
                        {!isDefaultOptionOnly && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleOptionDelete("imageMapAllObjectsOptions", i)}
                            >
                              <Delete />
                            </IconButton>
                          </InputAdornment>
                        )}
                      </Fragment>
                    ),
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleOptionItemChange(e, i, "imageMapAllObjectsOptions", "imageMapAllObjectsOptionItem")
                  }
                />
                <TextInput
                  name={`${name}.imageMapAllObjectsOptions[${i}].imageMapAllObjectsOptionCells`}
                  onClick={() => {
                    setCurrentlySelectedOption(i);
                  }}
                  onChange={e => e.preventDefault()}
                  className={clsx({ [classes.selected]: currentlySelectedOption === i })}
                  disabled={currentlySelectedOption !== i}
                />
              </Grid>
            </Grid>
          ))}

          {isAnyObjectsDefined && (
            <Fragment>
              <Heading textOrLocalizationId="form.labels.imageMapThirdStep" />

              <Typography>
                <FormattedMessage id="form.labels.imageMapAnswersObjectsOptions" />
                <Tooltip title={formatMessage({ id: "form.tips.add" })}>
                  <IconButton size="large" aria-label="add" onClick={handleOptionAdd("imageMapAnswersObjectsOptions")}>
                    <Add fontSize="large" color="primary" />
                  </IconButton>
                </Tooltip>
              </Typography>

              {formik.values[name].imageMapAnswersObjectsOptions.map(
                (option: IImageMapAnswersObjectsOption, i: number) => (
                  <Grid container key={i}>
                    <Grid item>
                      <TextInput
                        name={`${name}.imageMapAnswersObjectsOptions[${i}].imageMapAnswersObjectsOptionItem`}
                        InputProps={{
                          endAdornment: (
                            <Fragment>
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => handleOptionDelete("imageMapAnswersObjectsOptions", i)}
                                >
                                  <Delete />
                                </IconButton>
                              </InputAdornment>
                            </Fragment>
                          ),
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleOptionItemChange(
                            e,
                            i,
                            "imageMapAnswersObjectsOptions",
                            "imageMapAnswersObjectsOptionItem",
                          )
                        }
                      />
                      <DynamicSelectInput
                        name={`${name}.imageMapAnswersObjectsOptions[${i}].imageMapAnswersObjectsOptionCells`}
                        options={imageMapAnswersObjectsCellsOptions}
                        isLocalized={false}
                      />
                    </Grid>
                  </Grid>
                ),
              )}
            </Fragment>
          )}
        </Fragment>
      )}
      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmed}
        open={isDeleteDialogOpen}
        initialValues={{
          deleteActionType,
          gridSizeSelectBoxEventValue,
        }}
        getTitle={getDeleteDialogTitle}
      />
    </Fragment>
  );
};
