import * as Yup from "yup";
import { StepStatus, IImageMapAllObjectsOption, StepTimeoutBehavior } from "@memoryos/types";
import { validateTimeLimit } from "./validate.time.limit";

function validateImageMapAllObjectsOptionsAgainstPrevious(this: any): any {
  const { createError, from } = this;
  const imageMapAllObjectsOptions = from?.[0]?.value?.imageMapAllObjectsOptions;
  const errors: Yup.ValidationError[] = [];

  if (imageMapAllObjectsOptions?.length) {
    imageMapAllObjectsOptions.forEach((option: IImageMapAllObjectsOption, i: number) => {
      if (!option.imageMapAllObjectsOptionItem) {
        errors.push(
          new Yup.ValidationError(
            "form.validations.imageMapAllObjectsItemValuesMissing",
            null,
            `data.imageMapAllObjectsOptions.[${i}].imageMapAllObjectsOptionItem`,
          ),
        );
      }

      if (!option.imageMapAllObjectsOptionCells) {
        errors.push(
          new Yup.ValidationError(
            "form.validations.imageMapAllObjectsCellsValuesMissing",
            null,
            `data.imageMapAllObjectsOptions.[${i}].imageMapAllObjectsOptionCells`,
          ),
        );
      }

      const optionsWithDuplicatedCellsIndexes: number[] = [];
      // eslint-disable-next-line array-callback-return
      imageMapAllObjectsOptions.filter((option: IImageMapAllObjectsOption, j: number) => {
        if (
          i !== j &&
          option.imageMapAllObjectsOptionCells === imageMapAllObjectsOptions[i].imageMapAllObjectsOptionCells
        ) {
          optionsWithDuplicatedCellsIndexes.push(j);
        }
      });

      if (optionsWithDuplicatedCellsIndexes.length) {
        optionsWithDuplicatedCellsIndexes.forEach((index: number) => {
          errors.push(
            new Yup.ValidationError(
              "form.validations.imageMapAllObjectsCellsValuesDuplicated",
              null,
              `data.imageMapAllObjectsOptions.[${index}].imageMapAllObjectsOptionCells`,
            ),
          );
        });
        errors.push(
          new Yup.ValidationError(
            "form.validations.imageMapAllObjectsCellsValuesDuplicated",
            null,
            `data.imageMapAllObjectsOptions.[${i}].imageMapAllObjectsOptionCells`,
          ),
        );
      }
    });
  }

  return createError({
    message: () => errors,
  });
}

function validateImageMapAnswersObjectsOptionsAgainstPrevious(this: any): any {
  const { originalValue: values, createError, path, resolve } = this;
  if (!values.imageMapAnswersObjectsOptionItem) {
    return createError({
      path: `${path as string}.imageMapAnswersObjectsOptionItem`,
      message: "form.validations.imageMapAnswersObjectsItemValuesMissing",
    });
  }

  if (!values.imageMapAnswersObjectsOptionCells || values.imageMapAnswersObjectsOptionCells.length === 0) {
    return createError({
      path: `${path as string}.imageMapAnswersObjectsOptionCells`,
      message: "form.validations.imageMapAnswersObjectsCellsValuesMissing",
    });
  }

  return resolve(true);
}

const imageMapDataSchema = {
  imageMapImageUrl: Yup.string().required("form.validations.valueMissing"),
  imageMapImageGridSize: Yup.string().required("form.validations.valueMissing"),
  imageMapValidationMode: Yup.string().required("form.validations.valueMissing"),
  imageMapAllObjectsOptions: Yup.array().of(
    Yup.mixed().test({ name: "atLeastOneRequired", test: validateImageMapAllObjectsOptionsAgainstPrevious }),
  ),
  imageMapAnswersObjectsOptions: Yup.array().of(
    Yup.mixed().test({ name: "atLeastOneRequired", test: validateImageMapAnswersObjectsOptionsAgainstPrevious }),
  ),
};

export const imageMapValidationSchema = Yup.object().shape({
  title: Yup.string().required("form.validations.valueMissing"),
  stepStatus: Yup.mixed().oneOf(Object.values(StepStatus), "form.validations.badInput"),
  data: Yup.object().shape(imageMapDataSchema),
  timeoutBehavior: Yup.mixed()
    .required("form.validations.valueMissing")
    .oneOf(Object.values(StepTimeoutBehavior), "form.validations.badInput"),
  timeLimit: Yup.number().test({ name: "requiredIfTimeoutBehavior", test: validateTimeLimit }),
});
