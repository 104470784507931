import { FC } from "react";
import { Divider, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { format, parseISO } from "date-fns";
import ReactJson from "react-json-view";

import { FormDialog } from "@encoderinc/mui-dialog-form";
import { ILead } from "@memoryos/types";
import { humanReadableDateTimeFormat } from "@memoryos/constants";

export interface IShowLeadsDialogProps {
  open: boolean;
  onCancel: () => void;
  initialValues: ILead;
}

export const ShowLeadsDialog: FC<IShowLeadsDialogProps> = props => {
  const { initialValues, onCancel, ...rest } = props;

  const {
    id,
    updatedAt,
    createdAt,
    questions,
    questionsHash,
    answers,

    origin,
    state,
    lastUpdate,

    email,
    name,

    ip,
    country,
    userAgent,
    fbc,
    fbp,

    source,
    placement,
    campaign,
    content
  } = initialValues;

  const fixedValues = {
    id,
    updatedAt,
    createdAt,
    questions,
    questionsHash,
    answers,

    origin,
    state,
    lastUpdate,

    email,
    name,

    ip,
    country,
    userAgent,
    fbc,
    fbp,

    source,
    placement,
    campaign,
    content
  };

  return (
    <FormDialog initialValues={fixedValues} message={"Lead " + id} onConfirm={onCancel} onCancel={onCancel} {...rest}>
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.origin" />
          {": "}
        </b>
        {origin}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.state" />
          {": "}
        </b>
        {state}
      </Typography>
      <br />

      <Divider />
      <br />
      {name && (
        <Typography>
          <b>
            <FormattedMessage id="pages.quiz.leads.show.name" />
            {": "}
          </b>
          {name || "Unknown"}
        </Typography>
      )}
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.email" />
          {": "}
        </b>
        {email || "Unknown"}
      </Typography>
      <br/>
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.ip" />
          {": "}
        </b>
        {ip || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.country" />
          {": "}
        </b>
        {country || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.userAgent" />
          {": "}
        </b>
        {userAgent || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.fbc" />
          {": "}
        </b>
        {fbc || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.fbp" />
          {": "}
        </b>
        {fbp || "Unknown"}
      </Typography>
      <br />
      <Divider />
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.source" />
          {": "}
        </b>
        {source || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.placement" />
          {": "}
        </b>
        {placement || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.campaign" />
          {": "}
        </b>
        {campaign || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.content" />
          {": "}
        </b>
        {content || "Unknown"}
      </Typography>
      <br />
      <Divider />
      <br />
      {createdAt && (
        <Typography>
          <b>
            <FormattedMessage id="pages.quiz.leads.show.createdAt" />
            {": "}
          </b>
          {format(parseISO(createdAt), humanReadableDateTimeFormat)}
        </Typography>
      )}
      {updatedAt && (
        <Typography>
          <b>
            <FormattedMessage id="pages.quiz.leads.show.updatedAt" />
            {": "}
          </b>
          {format(parseISO(updatedAt), humanReadableDateTimeFormat)}
        </Typography>
      )}
      {lastUpdate && (
        <Typography>
          <b>
            <FormattedMessage id="pages.quiz.leads.show.lastUpdate" />
            {": "}
          </b>
          {format(new Date(lastUpdate / 1), humanReadableDateTimeFormat)}
        </Typography>
      )}
      <br />
      <Divider />
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.questions" />
          {": "}
        </b>
        <br />
        <ReactJson src={questions} />
      </Typography>
      <br />
      <Divider />
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.quiz.leads.show.answers" />
          {": "}
        </b>
        <br />
        <ReactJson src={answers} />
      </Typography>
      <br />
      <Divider />
    </FormDialog>
  );
};
