import { FC } from "react";
import { useFormikContext } from "formik";

import { StepType } from "@memoryos/types";

// lessons
import { CategoriesStep } from "./categorise";
import { ChatStep } from "./chat";
import { DateStep } from "./date";
import { DragToMatchImageStep } from "./drag-to-match-image";
import { DragToMatchTextStep } from "./drag-to-match-text";
import { ExpandableListStep } from "./expandable-list";
import { FeedbackInputStep } from "./free-text-feedback";
import { CollectionImageStep } from "./collection-image";
import { WordsListStep } from "./collection-text";
import { GalleryStep } from "./gallery";
import { MissingWordsStep } from "./missing-words";
import { MultipleChoiceImageStep } from "./multiple-choice-image";
import { MultipleChoiceTextStep } from "./multiple-choice-text";
import { MultipleChoiceFeedbackStep } from "./multiple-choice-feedback";
import { NumberStep } from "./number";
import { ReorderTextStep } from "./reorder-text";
import { ReorderInputStep } from "./reorder-input";
import { RevealStep } from "./reveal";
import { SelectInOrderStep } from "./select-in-order";
import { SentenceConstructionStep } from "./sentence-construction";
import { SliderStep } from "./slider";
import { SliderFeedbackStep } from "./slider-feedback";
import { VideoStep } from "./video";
import { GeographyStep } from "./geography";
import { SimpleInputStep } from "./simple-input";
import { BinaryDigitsStep } from "./binary-digits";
import { AddressStep } from "./address";
import { FlashCardsStep } from "./flash-cards";
import { NumberAdvancedStep } from "./number-advanced";
import { PlayingCardsStep } from "./playing-cards";
import { ImageMapStep } from "./image-map";
import { MemoryPalaceStep } from "./memory-palace";

// test
import { MessageStep } from "./message";
import { TimerStep } from "./timer";
import { RandomWordsImageStep } from "./random-words-image";
import { RandomWordsInputStep } from "./random-words-input";
import { RandomWordsTextStep } from "./random-words-text";

// system
import { CompareTestResultsStep } from "./compare-test-results";
import { CollectionVideoStep } from "./collection-video";

interface IStepDataInputProps {
  name: string;
  valuesBeforeEdit?: any;
}

export const StepDataInput: FC<IStepDataInputProps> = props => {
  const formik = useFormikContext<any>();
  const { initialValues } = formik;

  switch (formik.values.stepType) {
    // lessons
    case StepType.CATEGORISE:
      return <CategoriesStep {...props} />;
    case StepType.CHAT:
      return <ChatStep {...props} />;
    case StepType.COLLECTION_IMAGE:
      return <CollectionImageStep {...props} />;
    case StepType.COLLECTION_VIDEO:
      return <CollectionVideoStep {...props} />;
    case StepType.WORDS_LIST:
      return <WordsListStep {...props} />;
    case StepType.DATE:
      return <DateStep {...props} />;
    case StepType.DRAG_TO_MATCH_IMAGE:
      return <DragToMatchImageStep {...props} />;
    case StepType.DRAG_TO_MATCH_TEXT:
      return <DragToMatchTextStep {...props} />;
    case StepType.EXPANDABLE_LIST:
      return <ExpandableListStep {...props} />;
    case StepType.FEEDBACK_INPUT:
      return <FeedbackInputStep {...props} />;
    case StepType.GALLERY:
      return <GalleryStep {...props} />;
    case StepType.MISSING_WORDS:
      return <MissingWordsStep {...props} />;
    case StepType.MULTIPLE_CHOICE_IMAGE:
      return <MultipleChoiceImageStep {...props} />;
    case StepType.MULTIPLE_CHOICE_TEXT:
      return <MultipleChoiceTextStep {...props} />;
    case StepType.MULTIPLE_CHOICE_FEEDBACK:
      return <MultipleChoiceFeedbackStep {...props} />;
    case StepType.NUMBER:
      return <NumberStep {...props} />;
    case StepType.REORDER_TEXT:
      return <ReorderTextStep {...props} />;
    case StepType.REORDER_INPUT:
      return <ReorderInputStep {...props} />;
    case StepType.REVEAL:
      return <RevealStep {...props} />;
    case StepType.SELECT_IN_ORDER:
      return <SelectInOrderStep {...props} />;
    case StepType.SENTENCE_CONSTRUCTION:
      return <SentenceConstructionStep {...props} />;
    case StepType.SLIDER:
      return <SliderStep {...props} />;
    case StepType.SLIDER_FEEDBACK:
      return <SliderFeedbackStep {...props} />;
    case StepType.VIDEO:
      return <VideoStep {...props} />;
    case StepType.GEOGRAPHY:
      return <GeographyStep {...props} />;
    case StepType.SIMPLE_INPUT:
      return <SimpleInputStep {...props} />;
    case StepType.BINARY_DIGITS:
      return <BinaryDigitsStep {...props} />;
    case StepType.ADDRESS:
      return <AddressStep initialValues={initialValues} {...props} />;
    case StepType.FLASH_CARDS:
      return <FlashCardsStep {...props} />;
    case StepType.NUMBER_ADVANCED:
      return <NumberAdvancedStep {...props} />;
    case StepType.PLAYING_CARDS:
      return <PlayingCardsStep {...props} />;
    case StepType.IMAGE_MAP:
      return <ImageMapStep initialValues={initialValues} {...props} />;
    // test
    case StepType.RANDOM_WORDS_TEXT:
      return <RandomWordsTextStep {...props} />;
    case StepType.RANDOM_WORDS_IMAGE:
      return <RandomWordsImageStep {...props} />;
    case StepType.RANDOM_WORDS_INPUT:
      return <RandomWordsInputStep {...props} />;
    case StepType.MESSAGE:
      return <MessageStep {...props} />;
    case StepType.TIMER:
      return <TimerStep {...props} />;
    // system
    case StepType.COMPARE_TEST_RESULTS:
      return <CompareTestResultsStep {...props} />;
    case StepType.MEMORY_PALACE:
      return <MemoryPalaceStep {...props} />;
    default:
      return null;
  }
};
