import { FC } from "react";
import { PageHeader } from "@encoderinc/mui-page-header";
import { Curriculum } from "./sections/curriculum";
import { Users } from "./sections/users";
import { WebFunnel } from "./sections/webfunnel";
import { Solidgate } from "./sections/solidgate";
import { Other } from "./sections/other";
import { useStyles } from "./styles";
import { version } from "@memoryos/constants";

export const Dashboard: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.version}>{version}</div>
      <PageHeader message="pages.dashboard.title" />
      <Curriculum />
      <WebFunnel />
      <Solidgate />
      <Users />
      <Other />
    </div>
  );
};
