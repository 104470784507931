import { FC } from "react";
import { Collapse, Grid } from "@mui/material";

import { AutoSave, FormikForm } from "@encoderinc/mui-form";
import { ILeadsSearchDto, LeadsState } from "@memoryos/types";
import { SearchInput, SelectInput } from "@encoderinc/mui-inputs-core";
import { DateRangeInput } from "@encoderinc/mui-inputs-picker";

import { useStyles } from "./styles";

interface ILeadsSearchFormProps {
  onSubmit: (values: ILeadsSearchDto) => void;
  initialValues: ILeadsSearchDto;
  open: boolean;
}

export const LeadsSearchForm: FC<ILeadsSearchFormProps> = props => {
  const { onSubmit, initialValues, open } = props;

  const classes = useStyles();

  const { query, leadsState, dateRangeCreated, dateRangeUpdated } = initialValues;
  const fixedValues = { query, leadsState, dateRangeCreated, dateRangeUpdated };

  return (
    <div className={classes.root}>
      <FormikForm initialValues={fixedValues} onSubmit={onSubmit} showButtons={false} showPrompt={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SearchInput name="query" />
          </Grid>
        </Grid>
        <Collapse in={open}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SelectInput name="leadsState" options={LeadsState} multiple />
            </Grid>
            <Grid item xs={12}>
              <DateRangeInput name="dateRangeCreated" />
            </Grid>
            <Grid item xs={12}>
              <DateRangeInput name="dateRangeUpdated" />
            </Grid>
          </Grid>
        </Collapse>
        <AutoSave />
      </FormikForm>
    </div>
  );
};
