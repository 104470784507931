import { ChangeEvent, FC, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Pagination,
} from "@mui/material";
import { Add, Create, Delete } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";

import { ProgressOverlay } from "@encoderinc/mui-progress";
import { PageHeader } from "@encoderinc/mui-page-header";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IPaginationResult, ISearchDto } from "@encoderinc/types-collection";
import { DeleteDialog } from "@encoderinc/mui-dialog-delete";
import { CommonSearchForm } from "@encoderinc/mui-form-search";
import { emptyProduct } from "@memoryos/mocks";
import { IProducts, IProductsItem, Frame, ProductsType, Term, RatePlanType } from "@memoryos/types";

import { Breadcrumbs } from "../../../components/common/breadcrumbs";
import { EditProductsDialog } from "./edit";
import { defaultItemsPerPage } from "@memoryos/constants";

export const Products: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [products, setProducts] = useState<Array<IProducts>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedProduct, setSelectedProduct] = useState<IProducts>(emptyProduct);
  const [productType, setProductType] = useState<ProductsType>(selectedProduct?.type || ProductsType.PROMOCODE);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1));

  const [data, setData] = useState<ISearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    query: "",
    ...parsedData,
  });

  const updateQS = (id?: number) => {
    const { skip: _skip, take: _take, ...rest } = data;
    navigate(id ? `/solidgate/products/${id}` : `/solidgate/products?${stringify(rest)}`);
  };

  const handleEdit = (product: IProducts): (() => void) => {
    return (): void => {
      setSelectedProduct({ ...product });
      setProductType(product.type);
      setIsEditDialogOpen(true);
      updateQS(product.id);
    };
  };

  const handleDelete = (product: IProducts): (() => void) => {
    return (): void => {
      setSelectedProduct({ ...product });
      setProductType(product.type);
      setIsDeleteDialogOpen(true);
    };
  };

  const handleDeleteCancel = (): void => {
    setIsDeleteDialogOpen(false);
  };

  const handleEditCancel = (): void => {
    setIsEditDialogOpen(false);
    updateQS();
  };

  const fetchProductsByQuery = async (): Promise<void> => {
    return api
      .fetchJson({
        url: "/solidgate/products",
        data,
      })
      .then((json: IPaginationResult<IProducts>) => {
        setProducts(json.rows);
        setCount(json.count);
        updateQS();
      });
  };

  const fetchProductsById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/solidgate/products/${id}`,
      })
      .then((json: IProducts) => {
        setProducts([json]);
        setCount(1);
        handleEdit(json)();
      });
  };

  const fetchProducts = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchProductsById(id) : fetchProductsByQuery())
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAdd = (): void => {
    setSelectedProduct({ ...emptyProduct, items: [] });
    setProductType(emptyProduct.type);
    setIsEditDialogOpen(true);
  };

  const handleDeleteConfirmed = (product: IProducts): Promise<void> => {
    return api
      .fetchJson({
        url: `/solidgate/products/${product.id}`,
        method: "DELETE",
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: "snackbar.deleted" }), { variant: "success" });
        return fetchProducts();
      })
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsDeleteDialogOpen(false);
      });
  };

  const handleEditConfirmed = (values: Partial<IProducts>, formikBag: any): Promise<void> => {
    let { id, ...data } = values;
    if (data.type === ProductsType.SUBSCRIPTON) {
      // @ts-ignore
      data = { ...data, items: [], solidgateAmount: null, solidgateCurrency: null };
    }
    return api
      .fetchJson({
        url: id ? `/solidgate/products/${id}` : "/solidgate/products/",
        method: id ? "PUT" : "POST",
        data,
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: id ? "snackbar.updated" : "snackbar.created" }), { variant: "success" });
        setIsEditDialogOpen(false);
        return fetchProducts();
      })
      .catch((e: ApiError) => {
        if (e.status === 400) {
          formikBag.setErrors(e.getLocalizedValidationErrors());
        } else if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      });
  };

  const handleChangePage = (e: ChangeEvent<unknown>, page: number) => {
    setData({
      ...data,
      skip: (page - 1) * data.take,
    });
  };

  const handleSubmit = (values: ISearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  useDeepCompareEffect(() => {
    void fetchProducts(id);
  }, [data]);

  const handleProductItemOptionAdd = (): (() => void) => (): void => {
    selectedProduct.items.push({
      id: selectedProduct.items.length ? selectedProduct.items[selectedProduct.items.length - 1].id + 1 : 1,
      term: Term.NONE,
      ratePlanType: RatePlanType.GUEST,
      lociBonus: 0,
      frame: Frame.NONE,
      moduleData: [],
    } as unknown as IProductsItem);
    setSelectedProduct({ ...selectedProduct });
  };

  const handleProductItemOptionDelete = (i: number) => {
    selectedProduct.items.splice(i, 1);
    setSelectedProduct({ ...selectedProduct, items: [...selectedProduct.items] });
  };

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "solidgateProducts"]} />

      <PageHeader message="pages.solidgate.products.title">
        <Button startIcon={<Add fontSize="large" color="primary" />} onClick={handleAdd} variant="contained">
          <FormattedMessage id="form.buttons.add" />
        </Button>
      </PageHeader>

      <CommonSearchForm onSubmit={handleSubmit} initialValues={data} />

      <ProgressOverlay isLoading={isLoading}>
        <List>
          {products.map((product, i) => (
            <ListItem key={i}>
              <ListItemText>{product.solidgateDescription}</ListItemText>
              <ListItemSecondaryAction>
                <IconButton onClick={handleEdit(product)}>
                  <Create />
                </IconButton>
                {/*<IconButton onClick={handleDelete(product)}>*/}
                {/*  <Delete />*/}
                {/*</IconButton>*/}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ProgressOverlay>

      <Pagination
        shape="rounded"
        page={data.skip / data.take + 1}
        count={Math.ceil(count / data.take)}
        onChange={handleChangePage}
      />

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmed}
        open={isDeleteDialogOpen}
        initialValues={selectedProduct}
        getTitle={(product: IProducts) => product.solidgateProductId}
      />

      <EditProductsDialog
        onCancel={handleEditCancel}
        onConfirm={handleEditConfirmed}
        open={isEditDialogOpen}
        initialValues={selectedProduct}
        handleOptionAdd={handleProductItemOptionAdd}
        handleOptionDelete={handleProductItemOptionDelete}
        setProductType={setProductType}
        productType={productType}
      />
    </Grid>
  );
};
