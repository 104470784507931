import { FC } from "react";
import { Collapse, Grid } from "@mui/material";

import { AutoSave, FormikForm } from "@encoderinc/mui-form";
import { IStepSearchDto, StepStatus, StepType } from "@memoryos/types";

import { SearchInput, SelectInput } from "@encoderinc/mui-inputs-core";

import { ModuleInput } from "./module-input";
import { LessonInput } from "./lesson-input";

import { sortStepTypesArrayAlphabetically } from "../utils";

interface IStepSearchFormProps {
  onSubmit: (values: IStepSearchDto) => void;
  initialValues: IStepSearchDto;
  open: boolean;
}

export const StepSearchForm: FC<IStepSearchFormProps> = props => {
  const { onSubmit, initialValues, open } = props;

  const { query, stepStatus, stepType, moduleIds, lessonIds } = initialValues;
  const fixedValues = { query, stepStatus, stepType, moduleIds, lessonIds };
  const stepTypes = sortStepTypesArrayAlphabetically([
    StepType.CATEGORISE,
    StepType.WORDS_LIST,
    StepType.DATE,
    StepType.DRAG_TO_MATCH_IMAGE,
    StepType.DRAG_TO_MATCH_TEXT,
    StepType.EXPANDABLE_LIST,
    StepType.FEEDBACK_INPUT,
    StepType.GALLERY,
    StepType.MULTIPLE_CHOICE_IMAGE,
    StepType.MULTIPLE_CHOICE_TEXT,
    StepType.NUMBER,
    StepType.REORDER_TEXT,
    StepType.REORDER_INPUT,
    StepType.VIDEO,
    StepType.MESSAGE,
    StepType.GEOGRAPHY,
    StepType.RANDOM_WORDS_TEXT,
    StepType.TIMER,
    StepType.SIMPLE_INPUT,
    StepType.BINARY_DIGITS,
    StepType.ADDRESS,
    StepType.FLASH_CARDS,
    StepType.NUMBER_ADVANCED,
    StepType.PLAYING_CARDS,
    StepType.IMAGE_MAP,
    StepType.MEMORY_PALACE,
  ]);

  return (
    <FormikForm initialValues={fixedValues} onSubmit={onSubmit} showButtons={false} showPrompt={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SearchInput name="query" />
        </Grid>
      </Grid>
      <Collapse in={open}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectInput name="stepStatus" options={StepStatus} multiple />
          </Grid>
          <Grid item xs={6}>
            <SelectInput name="stepType" options={stepTypes} multiple />
          </Grid>
          <Grid item xs={6}>
            <ModuleInput name="moduleIds" />
          </Grid>
          <Grid item xs={6}>
            <LessonInput name="lessonIds" />
          </Grid>
        </Grid>
      </Collapse>
      <AutoSave />
    </FormikForm>
  );
};
