import { ChangeEvent, useContext, useEffect, useState, Fragment } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Pagination,
} from "@mui/material";
import { FilterList, Article } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";
import { DeleteDialog } from "@encoderinc/mui-dialog-delete";

import { ProgressOverlay } from "@encoderinc/mui-progress";
import { PageHeader } from "@encoderinc/mui-page-header";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IPaginationResult } from "@encoderinc/types-collection";
import { ISubscription, ISubscriptionSearchDto, SubscriptionStatus } from "@memoryos/types";
import { emptySubscription } from "@memoryos/mocks";
import { usePrevious } from "../../utils/hooks/usePrevious";

import { Breadcrumbs } from "../../components/common/breadcrumbs";
import { ShowSubscriptionsDialog } from "./show";
import { SubscriptionSearchForm } from "./form";
import { defaultItemsPerPage } from "@memoryos/constants";
import { parseDateRange, stringifyDateRange } from "../../utils/date";

export const Subscriptions: () => JSX.Element = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Array<ISubscription>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedSubscription, setSelectedSubscription] = useState<ISubscription>(emptySubscription);
  const [isFiltersOpen, setIsFilterOpen] = useState(false);
  const prevIsFiltersOpen = usePrevious(isFiltersOpen);
  const [isCancelSubscriptionDialogOpen, setIsCancelSubscriptionDialogOpen] = useState(false);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1));
  const navigate = useNavigate();

  const [data, setData] = useState<ISubscriptionSearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    query: "",
    subscriptionStatus: [],
    ...parsedData,
    dateRangeStartedAt: parseDateRange(parsedData.dateRangeStartedAt as string),
  });

  const updateQS = (id?: number) => {
    const { skip: _skip, take: _take, dateRangeStartedAt, ...rest } = data;
    navigate(
      id
        ? `/solidgate/subscriptions/${id}`
        : `/solidgate/subscriptions?${stringify({
            ...rest,
            dateRangeStartedAt: stringifyDateRange(dateRangeStartedAt),
          })}`,
    );
  };

  const handleEdit = (subscription: ISubscription): (() => void) => {
    return (): void => {
      setSelectedSubscription(subscription);
      setIsEditDialogOpen(true);
      updateQS(subscription.id);
    };
  };

  const fetchSubscriptionsByQuery = async (): Promise<void> => {
    const { dateRangeStartedAt, subscriptionStatus, ...rest } = data;
    const updatedData: {
      skip: number;
      take: number;
      query: string;
      dateRangeStartedAt?: string;
      subscriptionStatus?: SubscriptionStatus;
    } = {
      ...rest,
    };
    if (isFiltersOpen && subscriptionStatus) {
      // @ts-ignore
      updatedData.subscriptionStatus = subscriptionStatus;
    }
    if (isFiltersOpen && dateRangeStartedAt) {
      updatedData.dateRangeStartedAt = stringifyDateRange(dateRangeStartedAt);
    }

    return api
      .fetchJson({
        url: "/subscriptions",
        data: updatedData,
      })
      .then((json: IPaginationResult<ISubscription>) => {
        setSubscriptions(json.rows);
        setCount(json.count);
      });
  };

  const fetchSubscriptionsById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/subscriptions/${id}`,
      })
      .then((json: ISubscription) => {
        setSubscriptions([json]);
        setCount(1);
        handleEdit(json)();
        updateQS();
      });
  };

  const fetchSubscriptions = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchSubscriptionsById(id) : fetchSubscriptionsByQuery())
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditCancel = (): void => {
    setIsEditDialogOpen(false);
    updateQS();
  };

  const handleChangePage = (e: ChangeEvent<unknown>, page: number) => {
    setData({
      ...data,
      skip: (page - 1) * data.take,
    });
  };

  const handleSubmit = (values: ISubscriptionSearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFiltersOpen);
  };

  useDeepCompareEffect(() => {
    void fetchSubscriptions(id);
  }, [data]);

  useEffect(() => {
    if (prevIsFiltersOpen !== isFiltersOpen) {
      void fetchSubscriptions(id);
    }
  }, [isFiltersOpen]);

  const getCancelSubscriptionDialogTitle = (): string => {
    return (
      formatMessage(
        { id: "pages.solidgate.subscription.cancelSubscription" },
        { email: selectedSubscription.solidgateId },
      ) || `Do you want to cancel subscription ${selectedSubscription.solidgateId}?`
    );
  };

  const handleSubscriptionCancelCanceled = (): void => {
    setIsCancelSubscriptionDialogOpen(false);
  };

  const handleSubscriptionCancelConfirmed = (): Promise<void> => {
    return api
      .fetchJson({
        url: `/subscriptions/cancel/${selectedSubscription.id}`,
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: "snackbar.subscriptionCanceled" }), { variant: "success" });
        updateQS();
      })
      .catch((e: ApiError) => {
        if (e.status) {
          e.message.startsWith("noLocalize")
            ? enqueueSnackbar(e.message.slice(10), { variant: "error" })
            : enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsCancelSubscriptionDialogOpen(false);
      });
  };

  return (
    <Fragment>
      <Grid>
        <Breadcrumbs path={["dashboard", "solidgateSubscriptions"]} />

        <PageHeader message={`pages.solidgate.subscription.title`}>
          <Button startIcon={<FilterList />} onClick={toggleFilters}>
            <FormattedMessage id={`form.buttons.${isFiltersOpen ? "hideFilters" : "showFilters"}`} />
          </Button>
        </PageHeader>

        <SubscriptionSearchForm onSubmit={handleSubmit} initialValues={data} open={isFiltersOpen} />

        <b>
          <FormattedMessage id="pages.solidgate.subscription.counter" values={{ count }} />
        </b>
        <br />
        <ProgressOverlay isLoading={isLoading}>
          <List>
            {subscriptions.map(subscription => (
              <ListItem key={subscription.id}>
                <ListItemText>{subscription.solidgateId}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton onClick={handleEdit(subscription)}>
                    <Article />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </ProgressOverlay>

        <Pagination
          shape="rounded"
          page={data.skip / data.take + 1}
          count={Math.ceil(count / data.take)}
          onChange={handleChangePage}
        />

        <ShowSubscriptionsDialog
          onCancel={handleEditCancel}
          open={isEditDialogOpen}
          values={selectedSubscription}
          handleSubscriptionCancel={() => setIsCancelSubscriptionDialogOpen(true)}
        />
      </Grid>
      <DeleteDialog
        onCancel={handleSubscriptionCancelCanceled}
        onConfirm={handleSubscriptionCancelConfirmed}
        open={isCancelSubscriptionDialogOpen}
        getTitle={getCancelSubscriptionDialogTitle}
        initialValues={{ selectedSubscription }}
      />
    </Fragment>
  );
};
