import { FC, Fragment } from "react";
import { Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

import { TextInput, TextArea } from "@encoderinc/mui-inputs-core";
import { IDragToMatchTextOption } from "@memoryos/types";
import { useStepValuesBeforeEditSaved } from "../../../../../../utils/hooks/useStepValuesBeforeEditSaved";

interface IDragToMatchTextStepProps {
  name: string;
  valuesBeforeEdit?: any;
}

export const DragToMatchTextStep: FC<IDragToMatchTextStepProps> = props => {
  const { name, valuesBeforeEdit } = props;

  const { formatMessage } = useIntl();
  const formik = useFormikContext<any>();

  useStepValuesBeforeEditSaved(formik, valuesBeforeEdit, name);

  const handleOptionAdd =
    (field: string): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].push({
        dragToMatchTextOptionTextTop: "",
        dragToMatchTextOptionTextBottom: "",
      });
      formik.setFieldValue(name, newValue);
    };

  const handleOptionDelete =
    (field: string, i: number): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].splice(i, 1);
      formik.setFieldValue(name, newValue);
    };

  return (
    <Fragment>
      <TextInput name={`${name}.dragToMatchTextQuestion`} />

      <Typography>
        <FormattedMessage id="form.labels.dragToMatchTextOptions" />
        <Tooltip title={formatMessage({ id: "form.tips.add" })}>
          <IconButton size="large" aria-label="add" onClick={handleOptionAdd("dragToMatchTextOptions")}>
            <Add fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>

      {formik.values[name].dragToMatchTextOptions.map((_o: IDragToMatchTextOption, i: number) => (
        <Grid container key={i} spacing={3}>
          <Grid item xs={4}>
            <TextInput multiline name={`${name}.dragToMatchTextOptions[${i}].dragToMatchTextOptionTextTop`} />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              name={`${name}.dragToMatchTextOptions[${i}].dragToMatchTextOptionTextBottom`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="delete" onClick={handleOptionDelete("dragToMatchTextOptions", i)}>
                      <Delete />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ))}

      <TextArea name={`${name}.dragToMatchTextAnswerText`} />
      <TextArea name={`${name}.dragToMatchTextWrongAnswerText`} />
    </Fragment>
  );
};
