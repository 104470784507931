import { ChangeEvent, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Pagination,
} from "@mui/material";
import { Create, Delete, DeleteOutline, Equalizer, FilterList } from "@mui/icons-material";
import { useNavigate, useLocation, useParams } from "react-router";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";

import { ConfirmationDialog } from "@encoderinc/mui-dialog-confirmation";
import { ProgressOverlay } from "@encoderinc/mui-progress";
import { PageHeader } from "@encoderinc/mui-page-header";
import { DeleteDialog } from "@encoderinc/mui-dialog-delete";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IPaginationResult } from "@encoderinc/types-collection";
import { IUser, IUserSearchDto, UserRole } from "@memoryos/types";
import { emptyUser } from "@memoryos/mocks";

import { Breadcrumbs } from "../../components/common/breadcrumbs";
import { EditUserDialog } from "./edit";
import { StatisticsUserDialog } from "./statistics";
import { UserSearchForm } from "./form";
import { defaultItemsPerPage } from "@memoryos/constants";

interface IUserComponentProps {
  adminPage?: boolean;
}
export const User: (props: IUserComponentProps) => JSX.Element = (props: IUserComponentProps) => {
  const { adminPage = false } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isStatisticsDialogOpen, setIsStatisticsDialogOpen] = useState(false);
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<IUser>(emptyUser);
  const [isFiltersOpen, setIsFilterOpen] = useState(false);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1));

  const [data, setData] = useState<IUserSearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    query: "",
    userStatus: [],
    userRoles: adminPage ? [UserRole.ADMIN] : [UserRole.USER],
    ...parsedData,
  });

  const updateQS = (fid?: string) => {
    const { skip: _skip, take: _take, ...rest } = data;
    const route = adminPage ? "admins" : "users";
    navigate(fid ? `/${route}/${fid}` : `/${route}?${stringify(rest)}`);
  };

  const handleEdit = (user: IUser): (() => void) => {
    return (): void => {
      setSelectedUser(user);
      setIsEditDialogOpen(true);
      updateQS(user.fid);
    };
  };

  const fetchUsersByQuery = async (): Promise<void> => {
    return api
      .fetchJson({
        url: "/users",
        data,
      })
      .then((json: IPaginationResult<IUser>) => {
        setUsers(json.rows);
        setCount(json.count);
        updateQS();
      });
  };

  const fetchUsersById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/users/${id}`,
      })
      .then((json: IUser) => {
        setUsers([json]);
        setCount(1);
        handleEdit(json)();
      });
  };

  const fetchUsers = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchUsersById(id) : fetchUsersByQuery())
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = (user: IUser): (() => void) => {
    return (): void => {
      setSelectedUser(user);
      setIsDeleteDialogOpen(true);
    };
  };

  const handleDeleteCancel = (): void => {
    setIsDeleteDialogOpen(false);
  };

  const handleEditCancel = (): void => {
    setIsEditDialogOpen(false);
    updateQS();
  };

  const handleDeleteConfirmed = (user: IUser): Promise<void> => {
    return api
      .fetchJson({
        url: `/users/${user.id}`,
        method: "DELETE",
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: "snackbar.deleted" }), { variant: "success" });
        return fetchUsers();
      })
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsDeleteDialogOpen(false);
      });
  };

  const handleEditConfirmed = (values: Partial<IUser>, formikBag: any): Promise<void> => {
    const { id, ...data } = values;
    return api
      .fetchJson({
        url: id ? `/users/${id}` : "/users",
        method: id ? "PUT" : "POST",
        data,
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: id ? "snackbar.updated" : "snackbar.created" }), { variant: "success" });
        setIsEditDialogOpen(false);
        return fetchUsers();
      })
      .catch((e: ApiError) => {
        if (e.status === 400) {
          formikBag.setErrors(e.getLocalizedValidationErrors());
        } else if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      });
  };

  const handleChangePage = (e: ChangeEvent<unknown>, page: number) => {
    setData({
      ...data,
      skip: (page - 1) * data.take,
    });
  };

  const handleSubmit = (values: IUserSearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  const handleStatistics = (user: IUser): (() => void) => {
    return (): void => {
      setSelectedUser(user);
      setIsStatisticsDialogOpen(true);
    };
  };

  const handleStatisticsCancel = () => {
    setIsStatisticsDialogOpen(false);
  };

  const handleReset = (user: IUser): (() => void) => {
    return (): void => {
      setSelectedUser(user);
      setIsResetDialogOpen(true);
    };
  };

  const handleResetCancel = () => {
    setIsResetDialogOpen(false);
  };

  const handleResetConfirm = () => {
    return api
      .fetchJson({
        url: `/curriculum/reset/${selectedUser.id}`,
      })
      .then((): void => {
        enqueueSnackbar(formatMessage({ id: "snackbar.updated" }), { variant: "success" });
      })
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsResetDialogOpen(false);
      });
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFiltersOpen);
  };

  useDeepCompareEffect(() => {
    void fetchUsers(id);
  }, [data]);
  const pageName = adminPage ? "admins" : "users";

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", pageName]} />

      <PageHeader message={`pages.${pageName}.title`}>
        <Button startIcon={<FilterList />} onClick={toggleFilters}>
          <FormattedMessage id={`form.buttons.${isFiltersOpen ? "hideFilters" : "showFilters"}`} />
        </Button>
      </PageHeader>

      <UserSearchForm onSubmit={handleSubmit} initialValues={data} open={isFiltersOpen} />

      <ProgressOverlay isLoading={isLoading}>
        <List>
          {users.map(user => (
            <ListItem key={user.id}>
              <ListItemText>
                {`${user.id}:`} {user.displayName ? user.displayName : "N/A"} {user.email ? `(${user.email})` : ""}
              </ListItemText>
              <ListItemSecondaryAction>
                {!adminPage && (
                  <IconButton onClick={handleStatistics(user)}>
                    <Equalizer />
                  </IconButton>
                )}
                <IconButton onClick={handleEdit(user)}>
                  <Create />
                </IconButton>
                {/*<IconButton onClick={handleReset(user)}>*/}
                {/*  <DeleteOutline />*/}
                {/*</IconButton>*/}
                {/*<IconButton onClick={handleDelete(user)}>*/}
                {/*  <Delete />*/}
                {/*</IconButton>*/}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ProgressOverlay>

      <Pagination
        shape="rounded"
        page={data.skip / data.take + 1}
        count={Math.ceil(count / data.take)}
        onChange={handleChangePage}
      />

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmed}
        open={isDeleteDialogOpen}
        initialValues={selectedUser}
        getTitle={(user: IUser) => user.displayName}
      />

      <EditUserDialog
        onCancel={handleEditCancel}
        onConfirm={handleEditConfirmed}
        open={isEditDialogOpen}
        initialValues={selectedUser}
      />

      <StatisticsUserDialog
        onCancel={handleStatisticsCancel}
        onConfirm={handleStatisticsCancel}
        open={isStatisticsDialogOpen}
        initialValues={selectedUser}
      />

      <ConfirmationDialog open={isResetDialogOpen} onCancel={handleResetCancel} onConfirm={handleResetConfirm}>
        <FormattedMessage id="dialogs.delete" values={{ title: "user data" }} />
      </ConfirmationDialog>
    </Grid>
  );
};
