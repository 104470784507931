import React, { FC } from "react";
import { Typography, Button } from "@mui/material";
import { format, parseISO } from "date-fns";
import { TextInput } from "@encoderinc/mui-inputs-core";
import { FormattedMessage } from "react-intl";

import { FormDialog } from "@encoderinc/mui-dialog-form";
import { ISolidgateCustomer } from "@memoryos/types";
import { humanReadableDateTimeFormat } from "@memoryos/constants";
import { validationSchema } from "./validation";

import Grid from "@mui/material/Grid";

export interface IEditSolidgateCustomerDialog {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: Partial<ISolidgateCustomer>, formikBag: any) => Promise<void>;
  onUnlinkUser: (id: number) => Promise<void>;
  onUnlinkSocial: (id: number) => Promise<void>;
  initialValues: ISolidgateCustomer;
}

export const EditSolidgateCustomersDialog: FC<IEditSolidgateCustomerDialog> = props => {
  const { initialValues, ...rest } = props;

  const {
    id,
    createdAt,
    updatedAt,
    cid,
    solidgateCustomerId,
    name,
    email,
    emailAlternative,
    socialEmail,
    socialLoginProvider,
    facebookUserId,
    mosUserId,
    country,
  } = initialValues;
  const fixedValues = {
    id,
    createdAt,
    updatedAt,
    cid,
    solidgateCustomerId,
    name,
    email,
    emailAlternative,
    socialEmail,
    socialLoginProvider,
    facebookUserId,
    mosUserId,
    country,
  };

  function showTypography(label: any | null, labelId: string) {
    return (
      <Typography>
        <b>
          <FormattedMessage id={labelId} /> {": "}
        </b>
        {label || "-"}
      </Typography>
    );
  }

  async function unlinkUser() {
    await props.onUnlinkUser(id);
  }

  async function unlinkSocialAccount() {
    await props.onUnlinkSocial(id);
  }

  return (
    <FormDialog initialValues={fixedValues} validationSchema={validationSchema} message={"dialogs.edit"} {...rest}>
      {showTypography(id, "form.labels.id")}
      {showTypography(cid, "form.labels.cid")}
      {showTypography(solidgateCustomerId, "form.labels.solidgateCustomerId")}
      {showTypography(mosUserId, "form.labels.mosUserId")}
      {showTypography(facebookUserId, "form.labels.fbid")}
      {showTypography(name, "form.labels.name")}
      {showTypography(email, "form.labels.email")}
      {showTypography(socialEmail, "pages.solidgate.customers.edit.socialEmail")}
      {showTypography(socialLoginProvider, "pages.solidgate.customers.edit.socialLoginProvider")}
      {showTypography(country, "form.labels.country")}
      {showTypography(
        createdAt ? format(parseISO(createdAt), humanReadableDateTimeFormat) : null,
        "form.labels.createdAt",
      )}
      {showTypography(
        updatedAt ? format(parseISO(updatedAt), humanReadableDateTimeFormat) : null,
        "form.labels.updatedAt",
      )}
      <TextInput name="emailAlternative" onFocus={event => event.currentTarget.select()} />
      <br />
      <br />
      <Grid container spacing={4} style={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={unlinkUser}
            disabled={mosUserId === null}
            style={{ whiteSpace: "nowrap" }}
          >
            <FormattedMessage id="pages.solidgate.customers.edit.userUnlink" />
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={unlinkSocialAccount}
            disabled={socialLoginProvider === null}
            style={{ whiteSpace: "nowrap" }}
          >
            <FormattedMessage id="pages.solidgate.customers.edit.socialUnlink" />
          </Button>
        </Grid>
        <Grid item xs={8}></Grid>
      </Grid>
    </FormDialog>
  );
};
