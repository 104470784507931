import { FC, Fragment, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { SelectInput, TextArea, TextInput } from "@encoderinc/mui-inputs-core";
import { AddressStepFormat, AddressStepValidationMode, IAddressStepData, IStep } from "@memoryos/types";
import { SelectChangeEvent } from "@mui/material";

import usCitiesByState from "./us-cities-by-state.json";

import { sortArrayAlphabetically } from "../../../utils";
import { NumericInput } from "../../../../../../components/common/inputs/numeric";
import { NumericInputMode } from "../../../../../../components/common/inputs/numeric/types";

interface IAddressStepProps {
  name: string;
  initialValues: IStep;
}

export const AddressStep: FC<IAddressStepProps> = props => {
  const { name, initialValues } = props;
  const data = initialValues.data as IAddressStepData;
  const initialAddressFormat = data.addressFormat || AddressStepFormat.CITY_US_STATE_ZIP;

  const formik = useFormikContext<any>();
  const [addressFormat, setAddressFormat] = useState(initialAddressFormat);
  useEffect(() => {
    if (addressFormat) {
      formik.setFieldValue(`${name}.addressFormat`, addressFormat);
    }
  }, [addressFormat]);

  const handleAddressFormatInputChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    setAddressFormat(event.target.value as AddressStepFormat);
    formik.setFieldValue(`${name}.addressCity`, "");
    formik.setFieldValue(`${name}.addressZip`, "");
    formik.setFieldValue(`${name}.addressStreetName`, "");
    formik.setFieldValue(`${name}.addressUSState`, "");
    formik.setFieldValue(`${name}.addressStreetNumber`, "");
  };

  const renderAddressAnswerInputs = () => {
    if (!addressFormat) {
      return null;
    }

    switch (addressFormat) {
      case AddressStepFormat.CITY_US_STATE_ZIP:
        return (
          <Fragment>
            <TextInput name={`${name}.addressCity`} />
            <SelectInput
              name={`${name}.addressUSState`}
              options={sortArrayAlphabetically(Object.keys(usCitiesByState))}
            />
            <NumericInput name={`${name}.addressZip`} mode={NumericInputMode.INTEGER} inputProps={{ maxLength: 5 }} />
          </Fragment>
        );
      case AddressStepFormat.STREET_NUMBER_STREET_NAME_CITY_US_STATE:
        return (
          <Fragment>
            <TextInput name={`${name}.addressStreetNumber`} />
            <TextInput name={`${name}.addressStreetName`} />
            <TextInput name={`${name}.addressAlternativeNumericStreetName`} />
            <div>
              Alternative accepted answer for numeric street. Fill in the number first. Ex.: &apos;2nd Drive&apos;
            </div>
            <TextInput name={`${name}.addressCity`} />
            <SelectInput
              name={`${name}.addressUSState`}
              options={sortArrayAlphabetically(Object.keys(usCitiesByState))}
            />
          </Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <TextInput name={`${name}.addressQuestion`} />
      <TextInput name={`${name}.addressPrompt`} />
      <SelectInput name={`${name}.addressValidationMode`} options={AddressStepValidationMode} />

      <SelectInput
        name={`${name}.addressFormat`}
        options={AddressStepFormat}
        value={addressFormat}
        onChange={event => handleAddressFormatInputChange(event as SelectChangeEvent<HTMLInputElement>)}
      />
      {addressFormat && renderAddressAnswerInputs()}
      <TextArea name={`${name}.addressAnswerText`} />
      <TextArea name={`${name}.addressWrongAnswerText`} />
    </Fragment>
  );
};
