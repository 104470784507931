import { FC, Fragment } from "react";

import { TextArea, TextInput } from "@encoderinc/mui-inputs-core";

interface IMessageStepProps {
  name: string;
}

export const MessageStep: FC<IMessageStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <TextInput name={`${name}.messageTitle`} />
      <TextArea name={`${name}.messageText`} />
    </Fragment>
  );
};
