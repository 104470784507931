import { FC, ClipboardEvent, KeyboardEvent, ChangeEvent, useEffect, useState } from "react";
import { NumberInput } from "@encoderinc/mui-inputs-core";
import { TextFieldProps } from "@mui/material";

import { useFormikContext, getIn } from "formik";
// eslint-disable-next-line import/no-extraneous-dependencies
import { isNaN } from "lodash";
import { usePrevious } from "../../../../utils/hooks/usePrevious";

export interface IAdvancedNumberInput {
  name: string;
  min?: number;
  max?: number;
  allowFraction?: boolean;
  step?: number;
  setMinValueWhenEmpty?: boolean;
}

export const AdvancedNumberInput: FC<IAdvancedNumberInput & TextFieldProps> = props => {
  const { name, min, max, allowFraction = false, setMinValueWhenEmpty = false, ...rest } = props;
  let step = props.step;
  if (!step && !allowFraction) {
    step = 1;
  }

  const inputProps = rest?.inputProps ? { ...rest.inputProps, min, max, step } : { min, max, step };

  const formik = useFormikContext<any>();
  const [value, setValue] = useState<string>(getIn(formik.values, name) || 1);
  const prevValue = usePrevious(value);
  useEffect(() => formik.setFieldValue(name, value), [value]);
  useEffect(() => {
    const formikValue = getIn(formik.values, name);
    if (formikValue !== prevValue && formikValue !== value) {
      setValue(formikValue);
    }
  });

  const preventFractionInput = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!allowFraction && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  const preventNonNumericPaste = (event: ClipboardEvent<HTMLDivElement>) => {
    const paste = (event.clipboardData || (window as any).clipboardData).getData("text");
    if (allowFraction && !/^\d.,+$/.test(paste)) {
      event.preventDefault();
    }
    if (!allowFraction) {
      if (!/^\d+$/.test(paste) || paste.startsWith("0")) {
        event.preventDefault();
      }

      const valueBeforePaste = getIn(formik.values, name);

      if (max && Number(paste) + Number(valueBeforePaste) > max) {
        event.preventDefault();
      }

      if (min && Number(paste) + Number(valueBeforePaste) < min) {
        event.preventDefault();
      }
    }
  };

  const handleChange = (e: ChangeEvent<any>) => {
    if (max && Number(e.target.value) > max) {
      e.preventDefault();
      return;
    }
    if (setMinValueWhenEmpty && min && (isNaN(Number(e.target.value)) || Number(e.target.value) < min)) {
      setValue(min.toString());
      return;
    }

    setValue(e.target.value);
  };

  return (
    <NumberInput
      name={name}
      inputProps={inputProps}
      {...rest}
      onKeyPress={e => preventFractionInput(e)}
      onPaste={e => preventNonNumericPaste(e)}
      value={value}
      onChange={e => handleChange(e)}
    />
  );
};
