import { FC, Fragment } from "react";

import { TextArea, TextInput } from "@encoderinc/mui-inputs-core";
import { NumericInput } from "../../../../../../components/common/inputs/numeric";

import { NumericInputMode } from "../../../../../../components/common/inputs/numeric/types";

interface INumberStepProps {
  name: string;
}

export const NumberStep: FC<INumberStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <TextInput name={`${name}.numberQuestion`} />
      <TextInput name={`${name}.numberPrompt`} />
      <NumericInput name={`${name}.numberAnswer`} mode={NumericInputMode.INTEGER} />
      <TextArea name={`${name}.numberAnswerText`} />
      <TextArea name={`${name}.numberWrongAnswerText`} />
    </Fragment>
  );
};
