import { ChangeEvent, FC, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Button, Grid, IconButton, Pagination } from "@mui/material";
import { Add, Create, Delete, FilterList } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";

import { ProgressOverlay } from "@encoderinc/mui-progress";
import { PageHeader } from "@encoderinc/mui-page-header";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IPaginationResult } from "@encoderinc/types-collection";
import { DeleteDialog } from "@encoderinc/mui-dialog-delete";
import { IModule, IModuleSearchDto, ModuleAlias, ModuleStatus, ModuleType, RatePlanType } from "@memoryos/types";
import { description } from "@memoryos/mocks";

import { Breadcrumbs } from "../../../components/common/breadcrumbs";
import { EditModuleDialog } from "./edit";
import { ModuleSearchForm } from "./form";
import { decoder } from "../step/utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useStyles } from "./styles";
import { defaultItemsPerPage, EnabledLanguages } from "@memoryos/constants";

const emptyModule = {
  id: "",
  title: "",
  userTitle: "",
  alias: ModuleAlias.NONE,
  priority: 1,
  description,
  language: EnabledLanguages.EN,
  ratePlanType: RatePlanType.GUEST,
  moduleStatus: ModuleStatus.INACTIVE,
  moduleType: ModuleType.REGULAR,
  imageUrl: "",
  lessons: [],
} as unknown as IModule;

export const Module: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [modules, setModules] = useState<Array<IModule>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedModule, setSelectedModule] = useState<IModule>(emptyModule);
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const [isFiltersOpen, setIsFilterOpen] = useState(false);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1), { decoder });

  const classes = useStyles();

  const [data, setData] = useState<IModuleSearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    query: "",
    moduleStatus: [ModuleStatus.ACTIVE, ModuleStatus.INACTIVE, ModuleStatus.HIDDEN, ModuleStatus.COMING_SOON],
    moduleType: [ModuleType.REGULAR, ModuleType.TEST, ModuleType.SINGLE_LESSON],
    ...parsedData,
  });

  const updateQS = (id?: number) => {
    const { skip: _skip, take: _take, ...rest } = data;
    navigate(id ? `/modules/${id}` : `/modules?${stringify(rest)}`);
  };

  const handleDelete = (module: IModule): (() => void) => {
    return (): void => {
      setSelectedModule(module);
      setIsDeleteDialogOpen(true);
    };
  };

  const handleDeleteCancel = (): void => {
    setIsDeleteDialogOpen(false);
  };

  const handleEditCancel = (): void => {
    setIsEditDialogOpen(false);
    updateQS();
  };

  const fetchModulesByQuery = async (): Promise<void> => {
    return api
      .fetchJson({
        url: "/curriculum/modules",
        data,
      })
      .then((json: IPaginationResult<IModule>) => {
        setModules(json.rows);
        setCount(json.count);
        updateQS();
      });
  };
  const fetchSingleModuleById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/curriculum/modules/${id}`,
      })
      .then((json: IModule) => {
        setSelectedModule(json);
        setCount(1);
      });
  };
  const handleEdit = (module: IModule): (() => void) => {
    return (): void => {
      void fetchSingleModuleById(module.id.toString());
      setIsEditDialogOpen(true);
      updateQS(module.id);
    };
  };
  const fetchModulesById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/curriculum/modules/${id}`,
      })
      .then((json: IModule) => {
        setModules([json]);
        setCount(1);
        handleEdit(json)();
      });
  };

  const fetchModules = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchModulesById(id) : fetchModulesByQuery())
      .catch(e => {
        console.error(e);
        enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAdd = (): void => {
    const moduleToAdd = {
      ...emptyModule,
    };
    moduleToAdd.priority = modules.length + 1;
    setSelectedModule(moduleToAdd);
    setIsEditDialogOpen(true);
  };

  const handleDeleteConfirmed = (module: IModule): Promise<void> => {
    return api
      .fetchJson({
        url: `/curriculum/modules/${module.id}`,
        method: "DELETE",
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: "snackbar.deleted" }), { variant: "success" });
        return fetchModules();
      })
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsDeleteDialogOpen(false);
      });
  };

  const handleEditConfirmed = (values: Partial<IModule>, formikBag: any, fetch = true): Promise<void> => {
    const { id, ...data } = values;
    return (
      api
        .fetchJson({
          url: id ? `/curriculum/modules/${id}` : "/curriculum/modules/",
          method: id ? "PUT" : "POST",
          data,
        })
        // @ts-ignore
        .then(() => {
          if (fetch) {
            enqueueSnackbar(formatMessage({ id: id ? "snackbar.updated" : "snackbar.created" }), {
              variant: "success",
            });
            setIsEditDialogOpen(false);
            return fetchModules();
          } else {
            return 1;
          }
        })
        .catch((e: ApiError) => {
          if (e.status === 400) {
            formikBag.setErrors(e.getLocalizedValidationErrors());
          } else if (e.status) {
            enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
          } else {
            console.error(e);
            enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
          }
        })
    );
  };

  const handleImageUpdated = (values: Partial<IModule>, formikBag: any) => {
    const { imageUrl } = values;
    return (
      api
        .fetchJson({
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          url: `/curriculum/modules/${id}/image`,
          method: "PUT",
          data: {
            imageUrl: imageUrl,
          },
        })
        // @ts-ignore
        .then(() => {})
        .catch((e: ApiError) => {
          if (e.status === 400) {
            formikBag.setErrors(e.getLocalizedValidationErrors());
          } else if (e.status) {
            enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
          } else {
            console.error(e);
            enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
          }
        })
    );
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFiltersOpen);
  };

  const handleChangePage = (e: ChangeEvent<unknown>, page: number) => {
    setData({
      ...data,
      skip: (page - 1) * data.take,
    });
  };

  const handleSubmit = (values: IModuleSearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  function arraymove(arr: any[], fromIndex: number, toIndex: any) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
  const handleDragEnd = (result: any) => {
    arraymove(modules, result.source.index, result.destination.index);
    modules.forEach((module, id) => {
      modules[id].priority = id + 1;
    });
    modules.forEach(module => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { createdAt, updatedAt, ...rest } = module;
      void handleEditConfirmed(rest, null, false);
    });
  };

  function formatModuleTitle(module: IModule): string {
    if (module.moduleStatus === ModuleStatus.ACTIVE) {
      return module.title;
    }
    return module.title + " [" + module.moduleStatus + "]";
  }

  useDeepCompareEffect(() => {
    void fetchModules(id);
  }, [data]);

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "modules"]} />

      <PageHeader message="pages.modules.title">
        <Button startIcon={<FilterList />} onClick={toggleFilters}>
          <FormattedMessage id={`form.buttons.${isFiltersOpen ? "hideFilters" : "showFilters"}`} />
        </Button>
        <Button startIcon={<Add fontSize="large" color="primary" />} onClick={handleAdd} variant="contained">
          <FormattedMessage id="form.buttons.add" />
        </Button>
      </PageHeader>

      <ModuleSearchForm onSubmit={handleSubmit} initialValues={data} open={isFiltersOpen} />
      <ProgressOverlay isLoading={isLoading}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {provided => (
              <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {modules.map((module, i) => (
                  <Draggable key={i} draggableId={i.toString()} index={i}>
                    {provided => (
                      <Grid
                        className={classes.itemGrid}
                        item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Box className={classes.itemBox} display="flex">
                          <div className={classes.itemCard}>
                            {typeof modules[i] === "string" ? modules[i] : formatModuleTitle(modules[i])}
                          </div>
                          <div className={classes.itemActions}>
                            <IconButton onClick={handleEdit(module)}>
                              <Create />
                            </IconButton>
                            <IconButton onClick={handleDelete(module)}>
                              <Delete />
                            </IconButton>
                          </div>
                        </Box>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </ProgressOverlay>

      <Pagination
        shape="rounded"
        page={data.skip / data.take + 1}
        count={Math.ceil(count / data.take)}
        onChange={handleChangePage}
      />

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmed}
        open={isDeleteDialogOpen}
        initialValues={selectedModule}
      />

      <EditModuleDialog
        onCancel={handleEditCancel}
        onConfirm={handleEditConfirmed}
        onImageUpdate={handleImageUpdated}
        open={isEditDialogOpen}
        initialValues={selectedModule}
      />
    </Grid>
  );
};
