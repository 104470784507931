import { ChangeEvent, FC, Fragment } from "react";
import { Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Add, Delete } from "@mui/icons-material";

import { CheckboxInput, SwitchInput, TextArea, TextInput } from "@encoderinc/mui-inputs-core";
import { IMultipleChoiceTextOption } from "@memoryos/types";

import { RadioInput } from "../../../../../../components/common/inputs/radio";
import { useStepValuesBeforeEditSaved } from "../../../../../../utils/hooks/useStepValuesBeforeEditSaved";

interface IMultipleChoiceTextStepProps {
  name: string;
  valuesBeforeEdit?: any;
}

export const MultipleChoiceTextStep: FC<IMultipleChoiceTextStepProps> = props => {
  const { name, valuesBeforeEdit } = props;

  const { formatMessage } = useIntl();
  const formik = useFormikContext<any>();

  useStepValuesBeforeEditSaved(formik, valuesBeforeEdit, name);
  const updateSelected = (newValue: Array<IMultipleChoiceTextOption>, selectedIndex: number) => {
    const index = newValue.findIndex((option: IMultipleChoiceTextOption) => option.multipleChoiceTextOptionIsCorrect);

    if (index === -1) {
      newValue[selectedIndex].multipleChoiceTextOptionIsCorrect = true;
    }

    return newValue;
  };

  const handleOptionAdd =
    (field: string): (() => void) =>
    (): void => {
      const newValue = formik.values[name][field];
      newValue.push({
        multipleChoiceTextOptionText: "",
        multipleChoiceTextOptionIsCorrect: false,
      });
      formik.setFieldValue(`${name}.${field}`, updateSelected(newValue, 0));
    };

  const handleOptionDelete =
    (field: string, i: number): (() => void) =>
    (): void => {
      const newValue = formik.values[name][field];
      newValue.splice(i, 1);
      formik.setFieldValue(`${name}.${field}`, updateSelected(newValue, 0));
    };

  const cleanAllSelected = (selectedIndex = -1) => {
    const options = formik.values[name].multipleChoiceTextOptions;

    if (options.length) {
      const newValue = options.map((option: IMultipleChoiceTextOption) => ({
        ...option,
        multipleChoiceTextOptionIsCorrect: false,
      }));

      formik.setFieldValue(
        `${name}.multipleChoiceTextOptions`,
        selectedIndex !== -1 ? updateSelected(newValue, selectedIndex) : newValue,
      );
    }
  };

  const handleRadioButton = (event: ChangeEvent<HTMLInputElement>) => {
    cleanAllSelected(-1);
    formik.setFieldValue(event.target.name, event.target.checked);
  };

  const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      const options = formik.values[name].multipleChoiceTextOptions;
      const index = options.findIndex((option: IMultipleChoiceTextOption) => option.multipleChoiceTextOptionIsCorrect);
      cleanAllSelected(index);
    }
    formik.handleChange(event);
  };

  return (
    <Fragment>
      <TextInput name={`${name}.multipleChoiceTextQuestion`} />
      <TextInput name={`${name}.multipleChoiceTextPrompt`} />

      <Typography>
        <FormattedMessage id="form.labels.multipleChoiceTextOptions" />
        <Tooltip title={formatMessage({ id: "form.tips.add" })}>
          <IconButton size="large" aria-label="add" onClick={handleOptionAdd("multipleChoiceTextOptions")}>
            <Add fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>
      {formik.values[name].multipleChoiceTextOptions.map((_o: IMultipleChoiceTextOption, i: number) => (
        <Grid container key={i}>
          <Grid item>
            <TextInput
              label={null}
              name={`${name}.multipleChoiceTextOptions[${i}].multipleChoiceTextOptionText`}
              InputProps={{
                startAdornment: <InputAdornment position="start">{i + 1}</InputAdornment>,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="delete" onClick={handleOptionDelete("multipleChoiceTextOptions", i)}>
                      <Delete />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {getIn(formik.values, `${name}.multipleChoiceTextIsMultiple`) ? (
              <CheckboxInput name={`${name}.multipleChoiceTextOptions[${i}].multipleChoiceTextOptionIsCorrect`} />
            ) : (
              <RadioInput
                name={`${name}.multipleChoiceTextOptions[${i}].multipleChoiceTextOptionIsCorrect`}
                onChange={handleRadioButton}
              />
            )}
          </Grid>
        </Grid>
      ))}

      <SwitchInput name={`${name}.multipleChoiceTextIsMultiple`} onChange={handleSwitch} />
      <TextArea name={`${name}.multipleChoiceTextAnswerText`} />
      <TextArea name={`${name}.multipleChoiceTextWrongAnswerText`} />
    </Fragment>
  );
};
