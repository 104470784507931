import { FC } from "react";
import { getIn, useFormikContext } from "formik";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { IStep, StepType, StepTypesWithTutorialByDefault } from "@memoryos/types";

import { useStyles } from "./styles";
import { getDefaultData } from "./utils";

import { sortArrayAlphabetically, sortStepTypesArrayAlphabetically } from "../../utils";

export interface IStepTypeInputProps extends SelectProps {
  name: string;
  setStepTypeValue: (value: StepType) => void;
}

const dict: Record<string, Array<string>> = {
  content: sortStepTypesArrayAlphabetically([
    StepType.EXPANDABLE_LIST,
    StepType.GALLERY,
    StepType.VIDEO,
    StepType.WORDS_LIST,
    StepType.FEEDBACK_INPUT,
    StepType.MESSAGE,
  ]),
  question: sortArrayAlphabetically([
    StepType.PLAYING_CARDS,
    StepType.FLASH_CARDS,
    StepType.IMAGE_MAP,
    StepType.GEOGRAPHY,
    StepType.MULTIPLE_CHOICE_IMAGE,
    StepType.MULTIPLE_CHOICE_TEXT,
    StepType.ADDRESS,
    StepType.BINARY_DIGITS,
    StepType.DATE,
    StepType.DRAG_TO_MATCH_IMAGE,
    StepType.DRAG_TO_MATCH_TEXT,
    StepType.NUMBER,
    StepType.NUMBER_ADVANCED,
    StepType.REORDER_TEXT,
    StepType.REORDER_INPUT,
    StepType.SIMPLE_INPUT,
    StepType.MEMORY_PALACE,
  ]),
  test: sortArrayAlphabetically([StepType.RANDOM_WORDS_TEXT, StepType.TIMER]),
};

export const StepTypeInput: FC<IStepTypeInputProps> = props => {
  const { label, name, multiple, variant = "standard", setStepTypeValue, ...rest } = props;
  const classes = useStyles();

  const suffix = name.split(".").pop() as string;

  const formik = useFormikContext<IStep>();
  const value = getIn(formik.values, name);

  const { formatMessage } = useIntl();

  const handleStepTypeChange = (e: SelectChangeEvent<unknown>): void => {
    formik.setFieldValue("data", getDefaultData(e.target.value));
    formik.setFieldValue(name, e.target.value);
    formik.setFieldValue("isTutorial", !!StepTypesWithTutorialByDefault.includes(e.target.value as StepType));
    setStepTypeValue(e.target.value as StepType);
  };

  return (
    <FormControl fullWidth className={classes.root}>
      <InputLabel id={`${name}-select-label`} variant={variant}>
        <FormattedMessage id={`form.labels.${suffix}`} />
      </InputLabel>
      <Select
        multiple={multiple}
        labelId={`${name}-select-label`}
        label={label || <FormattedMessage id={`form.labels.${suffix}`} />}
        id={`${name}-select`}
        name={name}
        onChange={handleStepTypeChange}
        value={value}
        variant={variant}
        renderValue={(value): string => formatMessage({ id: `enums.${suffix}.${value as string}` })}
        {...rest}
      >
        {/* can't work with multidimensional array because Select does not accept Fragment as child */}
        <ListSubheader>
          <FormattedMessage id={`pages.steps.groups.content`} />
        </ListSubheader>
        {dict.content.map(item => (
          <MenuItem key={item} value={item}>
            <FormattedMessage id={`enums.${suffix}.${item}`} />
          </MenuItem>
        ))}
        <ListSubheader>
          <FormattedMessage id={`pages.steps.groups.question`} />
        </ListSubheader>
        {dict.question.map(item => (
          <MenuItem key={item} value={item}>
            <FormattedMessage id={`enums.${suffix}.${item}`} />
          </MenuItem>
        ))}
        <ListSubheader>
          <FormattedMessage id={`pages.steps.groups.test`} />
        </ListSubheader>
        {dict.test.map(item => (
          <MenuItem key={item} value={item}>
            <FormattedMessage id={`enums.${suffix}.${item}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
