import React, { FC } from "react";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { format, parseISO } from "date-fns";
import { TextInput } from "@encoderinc/mui-inputs-core";

import { FormDialog } from "@encoderinc/mui-dialog-form";
import { ISolidgateCustomer } from "@memoryos/types";
import { humanReadableDateTimeFormat } from "@memoryos/constants";
import { validationSchema } from "./validation";

export interface IEditSolidgateCustomerDialog {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: Partial<ISolidgateCustomer>, formikBag: any) => Promise<void>;
  initialValues: ISolidgateCustomer;
}

export const EditSolidgateCustomersDialog: FC<IEditSolidgateCustomerDialog> = props => {
  const { initialValues, ...rest } = props;

  const {
    id,
    createdAt,
    updatedAt,
    solidgateCustomerId,
    name,
    email,
    emailAlternative,
    socialEmail,
    socialLoginProvider,
    facebookUserId,
    mosUserId,
    country,
  } = initialValues;
  const fixedValues = {
    id,
    createdAt,
    updatedAt,
    solidgateCustomerId,
    name,
    email,
    emailAlternative,
    socialEmail,
    socialLoginProvider,
    facebookUserId,
    mosUserId,
    country,
  };

  function showTypography(label : any | null, labelId : string) {
    return <Typography>
      <b><FormattedMessage id= {labelId}/> {": "}</b>
      {label ? label : "-"}
    </Typography>;
  }

  return (
    <FormDialog initialValues={fixedValues} validationSchema={validationSchema} message={"dialogs.edit"}  {...rest}>
      {showTypography(id, "form.labels.id")}
      {showTypography(solidgateCustomerId, "form.labels.solidgateCustomerId")}
      {showTypography(mosUserId, "form.labels.mosUserId")}
      {showTypography(facebookUserId, "form.labels.fbid")}
      {showTypography(name, "form.labels.name")}
      {showTypography(email, "form.labels.email")}
      {showTypography(socialEmail, "pages.solidgate.customers.edit.socialEmail")}
      {showTypography(socialLoginProvider, "pages.solidgate.customers.edit.socialLoginProvider")}
      {showTypography(country, "form.labels.country")}
      {showTypography(createdAt ? format(parseISO(createdAt), humanReadableDateTimeFormat) : null, "form.labels.createdAt")}
      {showTypography(updatedAt ? format(parseISO(updatedAt), humanReadableDateTimeFormat) : null, "form.labels.updatedAt")}
      <TextInput name="emailAlternative" onFocus={(event) => event.currentTarget.select()} />
    </FormDialog>
  );
};
