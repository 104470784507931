import { StepTimeoutBehavior } from "@memoryos/types";

export function validateTimeLimit(this: any): any {
  const { from, createError, path, resolve } = this;
  const values = from[0]?.value;
  if (
    values.timeoutBehavior &&
    !values.timeLimit &&
    (values.timeoutBehavior === StepTimeoutBehavior.AutoContinue ||
      values.timeoutBehavior === StepTimeoutBehavior.DeductHeart)
  ) {
    return createError({
      path: `${path as string}`,
      message: "form.validations.valueMissing",
    });
  }

  return resolve(true);
}
