import { FC } from "react";

import { CheckboxInput } from "@encoderinc/mui-inputs-core";

interface ISkipCorrectStatementInputProps {
  name: string;
  checked?: boolean;
}

export const SkipCorrectStatementInput: FC<ISkipCorrectStatementInputProps> = props => {
  const { name, checked = false } = props;

  return checked ? <CheckboxInput name={name} /> : <CheckboxInput name={name} />;
};
