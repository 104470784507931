import { FC, Fragment, useContext, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Add, ContentCopy, Create, Delete, FilterList } from "@mui/icons-material";
import { useNavigate, useLocation, useParams } from "react-router";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";

import { PageHeader } from "@encoderinc/mui-page-header";
import { DeleteDialog } from "@encoderinc/mui-dialog-delete";
import { IPaginationResult } from "@encoderinc/types-collection";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IStep, IStepSearchDto, StepStatus } from "@memoryos/types";
import { emptyStep } from "@memoryos/mocks";
import { defaultItemsPerPage, humanReadableDateFormat } from "@memoryos/constants";

import { Breadcrumbs } from "../../../components/common/breadcrumbs";
import { EditStepDialog } from "./edit";
import { StepSearchForm } from "./form";
import { decoder } from "./utils";
import { format, parseISO } from "date-fns";

export const Step: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [steps, setSteps] = useState<Array<IStep>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedStep, setSelectedStep] = useState<IStep>(emptyStep);
  const [isFiltersOpen, setIsFilterOpen] = useState(true);
  const valuesBeforeEdit = useRef([]);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1), { decoder });

  const [data, setData] = useState<IStepSearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    query: "",
    stepType: [],
    stepStatus: [StepStatus.ACTIVE],
    moduleIds: [],
    lessonIds: [],
    ...parsedData,
  });

  const updateQS = (id?: number) => {
    const { skip: _skip, take: _take, ...rest } = data;
    const currentQS = `${location.pathname}${location.search}`;
    const newQS = id ? `/steps/${id}` : `/steps?${stringify(rest)}`;

    if (currentQS === newQS) {
      return;
    }

    navigate(newQS, { replace: !location.search });
  };

  const handleEdit = (step: IStep): (() => void) => {
    return (): void => {
      setSelectedStep(step);
      setIsEditDialogOpen(true);
      updateQS(step.id);
    };
  };

  const handleDelete = (step: IStep): (() => void) => {
    return (): void => {
      setSelectedStep(step);
      setIsDeleteDialogOpen(true);
    };
  };

  const handleDeleteCancel = (): void => {
    setIsDeleteDialogOpen(false);
  };

  const handleEditCancel = (): void => {
    setIsEditDialogOpen(false);
    updateQS();
  };

  const fetchStepsByQuery = async (): Promise<void> => {
    return api
      .fetchJson({
        url: "/curriculum/steps",
        data,
      })
      .then((json: IPaginationResult<IStep>) => {
        setSteps(json.rows);
        setCount(json.count);
        updateQS();
      });
  };

  const fetchStepsById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/curriculum/steps/${id}`,
      })
      .then((json: IStep) => {
        setSteps([json]);
        setCount(1);
        handleEdit(json)();
      });
  };

  const fetchSteps = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchStepsById(id) : fetchStepsByQuery())
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAdd = (step?: IStep): void => {
    setSelectedStep(step || emptyStep);
    setIsEditDialogOpen(true);
  };

  const handleDeleteConfirmed = (step: IStep): Promise<void> => {
    return api
      .fetchJson({
        url: `/curriculum/steps/${step.id}`,
        method: "DELETE",
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: "snackbar.deleted" }), { variant: "success" });
        return fetchSteps();
      })
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsDeleteDialogOpen(false);
      });
  };

  const handleEditConfirmed = (values: Partial<IStep>, formikBag: any): Promise<void> => {
    const { id, ...data } = values;
    return api
      .fetchJson({
        url: id ? `/curriculum/steps/${id}` : "/curriculum/steps/",
        method: id ? "PUT" : "POST",
        data,
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: id ? "snackbar.updated" : "snackbar.created" }), { variant: "success" });
        setIsEditDialogOpen(false);
        valuesBeforeEdit.current = valuesBeforeEdit.current.filter((item: { id: any }) => item.id !== id);
        return fetchSteps();
      })
      .catch((e: ApiError) => {
        if (e.status === 400) {
          formikBag.setErrors(e.getLocalizedValidationErrors());
        } else if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      });
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFiltersOpen);
  };

  const handleChangePage = (page: number): void => {
    setData({
      ...data,
      skip: page * data.take,
      take: data.take,
    });
  };

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setData({
      ...data,
      skip: 0,
      take: pageSize,
    });
  };

  const handleSubmit = (values: IStepSearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: formatMessage({ id: "form.labels.title" }),
      minWidth: 250,
      renderCell: (params: GridRenderCellParams<any, IStep, any>) => {
        const step = params.row;
        return <strong>{step.title}</strong>;
      },
    },
    {
      field: "updatedAt",
      headerName: formatMessage({ id: "form.labels.updatedAt" }),
      type: "dateTime",
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<any, IStep, any>) => {
        const step = params.row;
        return format(parseISO(step.updatedAt), humanReadableDateFormat);
      },
    },
    {
      field: "stepType",
      headerName: formatMessage({ id: "form.labels.stepType" }),
      minWidth: 170,
      renderCell: (params: GridRenderCellParams<any, IStep, any>) => {
        return <FormattedMessage id={`enums.stepType.${params.row.stepType}`} />;
      },
    },
    {
      field: "actions",
      headerName: formatMessage({ id: "form.labels.actions" }),
      align: "right",
      sortable: false,
      width: 130,
      renderCell: (params: GridRenderCellParams<any, IStep, any>) => {
        const step = params.row;
        return (
          <Fragment>
            <IconButton onClick={handleEdit(step)}>
              <Create />
            </IconButton>
            {step.stepStatus === StepStatus.ACTIVE ? (
              <IconButton onClick={handleDelete(step)}>
                <Delete />
              </IconButton>
            ) : null}
            <IconButton
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { id, stepStatus, ...rest } = step;
                rest.title = `${rest.title} copy`;
                handleAdd(rest as IStep);
              }}
            >
              <ContentCopy />
            </IconButton>
          </Fragment>
        );
      },
    },
    {
      field: "path",
      headerName: formatMessage({ id: "form.labels.path" }),
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<any, IStep, any>) => {
        const step = params.row;
        return step.lessons
          .map(lesson => {
            const module = lesson ? lesson.module : null;
            return module ? `M${module?.priority}/L${lesson?.priority}` : "";
          })
          .join("; ");
      },
    },
  ];

  useDeepCompareEffect(() => {
    void fetchSteps(id);
  }, [data]);

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "steps"]} />

      <PageHeader message="pages.steps.title">
        <Button startIcon={<FilterList />} onClick={toggleFilters}>
          <FormattedMessage id={`form.buttons.${isFiltersOpen ? "hideFilters" : "showFilters"}`} />
        </Button>
        <Button startIcon={<Add fontSize="large" color="primary" />} onClick={() => handleAdd()} variant="contained">
          <FormattedMessage id="form.buttons.add" />
        </Button>
      </PageHeader>

      <StepSearchForm onSubmit={handleSubmit} initialValues={data} open={isFiltersOpen} />

      <Box mt={1}>
        <DataGrid
          autoHeight
          pagination
          paginationMode="server"
          rowCount={count}
          pageSize={data.take}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          rows={steps}
          columns={columns}
          disableSelectionOnClick
          loading={isLoading}
        />
      </Box>

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmed}
        open={isDeleteDialogOpen}
        initialValues={selectedStep}
      />

      <EditStepDialog
        onCancel={handleEditCancel}
        onConfirm={handleEditConfirmed}
        open={isEditDialogOpen}
        initialValues={selectedStep}
        valuesBeforeEdit={valuesBeforeEdit}
      />
    </Grid>
  );
};
