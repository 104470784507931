import { FC, useContext } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

import { SelectInput, TextInput } from "@encoderinc/mui-inputs-core";
import { UserContext } from "@encoderinc/provider-user";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { FormikForm } from "@encoderinc/mui-form";
import { AvatarInput } from "@encoderinc/mui-inputs-image-firebase";
import { EnabledLanguages } from "@memoryos/constants";
import { IUser } from "@memoryos/types";

import { validationSchema } from "./validation";
import { ITabPanelProps, ProfileTabs } from "../tabs";

export const ProfileGeneral: FC<ITabPanelProps> = props => {
  const { value } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const user = useContext(UserContext);
  const api = useContext(ApiContext);

  if (value !== ProfileTabs.general) {
    return null;
  }

  const handleSubmit = (values: Partial<IUser>, formikBag: any): Promise<void> => {
    return api
      .fetchJson({
        url: "/profile",
        method: "PUT",
        data: values,
      })
      .then((json): void => {
        enqueueSnackbar(formatMessage({ id: "snackbar.updated" }), { variant: "success" });
        if (json) {
          user.logIn(json);
        } else {
          user.logOut();
        }
      })
      .catch((e: ApiError) => {
        if (e.status === 400) {
          formikBag.setErrors(e.getLocalizedValidationErrors());
        } else if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      });
  };

  const { email, displayName, language, imageUrl } = user.profile;
  const fixedValues = { email, displayName, language, imageUrl };

  return (
    <Grid>
      <FormikForm initialValues={fixedValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <TextInput name="displayName" />
        <SelectInput name="language" options={EnabledLanguages} />
        <AvatarInput name="imageUrl" />
      </FormikForm>
    </Grid>
  );
};
