import { FC } from "react";
import { getIn, useFormikContext } from "formik";

import { EntityInput } from "@encoderinc/mui-inputs-entity";

interface ILessonInputProps {
  name: string;
}

export const LessonInput: FC<ILessonInputProps> = props => {
  const { name } = props;
  const formik = useFormikContext<any>();

  const moduleIds = getIn(formik.values, "moduleIds");

  return <EntityInput name={name} controller="curriculum/lesson" multiple data={{ moduleIds }} />;
};
