import { IPromoCode } from "@memoryos/types";

export function normalizePromoCode(data: Partial<IPromoCode>): Partial<IPromoCode> {
  const res = { ...data };

  if (!data.lociBonus) {
    res.lociBonus = 0;
  }

  if (res.userId) {
    res.userId = Number(res.userId);
  } else {
    res.userId = null;
  }

  if (res.userEmail) {
    res.userEmail = res.userEmail.trim();
  }

  if (res.userFid) {
    res.userFid = res.userFid.trim();
  }

  if (res.userFbid) {
    res.userFbid = res.userFbid.trim();
  }

  return res;
}
