import { FC, Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFormikContext } from "formik";

import { TextInput } from "@encoderinc/mui-inputs-core";
import { IRandomWordsInputOption } from "@memoryos/types";
import { useStepValuesBeforeEditSaved } from "../../../../../../utils/hooks/useStepValuesBeforeEditSaved";

interface IRandomWordsInputStepProps {
  name: string;
  valuesBeforeEdit?: any;
}

export const RandomWordsInputStep: FC<IRandomWordsInputStepProps> = props => {
  const { name, valuesBeforeEdit } = props;

  const { formatMessage } = useIntl();
  const formik = useFormikContext<any>();

  useStepValuesBeforeEditSaved(formik, valuesBeforeEdit, name);

  const handleOptionAdd =
    (field: string): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].push({
        randomWordsInputOptionText: "",
      });
      formik.setFieldValue(name, newValue);
    };

  const handleOptionDelete =
    (field: string, i: number): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].splice(i, 1);
      formik.setFieldValue(name, newValue);
    };

  return (
    <Fragment>
      <TextInput name={`${name}.randomWordsInputPrompt`} />
      <Typography>
        <FormattedMessage id="form.labels.randomWordsInputOptions" />
        <Tooltip title={formatMessage({ id: "form.tips.add" })}>
          <IconButton size="large" aria-label="add" onClick={handleOptionAdd("randomWordsInputOptions")}>
            <Add fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>

      {formik.values[name].randomWordsInputOptions.map((_o: IRandomWordsInputOption, i: number) => (
        <Grid container key={i}>
          <Grid item>
            <TextInput
              name={`${name}.randomWordsInputOptions[${i}].randomWordsInputOptionText`}
              InputProps={{
                startAdornment: <InputAdornment position="start">{i + 1}</InputAdornment>,
                endAdornment: (
                  <Fragment>
                    <InputAdornment position="end">
                      <IconButton aria-label="delete" onClick={handleOptionDelete("randomWordsInputOptions", i)}>
                        <Delete />
                      </IconButton>
                    </InputAdornment>
                  </Fragment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};
