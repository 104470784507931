import { FC, Fragment } from "react";

import { NumberInput, TextInput } from "@encoderinc/mui-inputs-core";

interface ISliderFeedbackStepProps {
  name: string;
}

export const SliderFeedbackStep: FC<ISliderFeedbackStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <TextInput name={`${name}.sliderFeedbackPrompt`} />
      <TextInput name={`${name}.sliderFeedbackQuestion`} />
      <NumberInput name={`${name}.sliderFeedbackMin`} />
      <NumberInput name={`${name}.sliderFeedbackMax`} />
    </Fragment>
  );
};
