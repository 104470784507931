import { FC } from "react";

import { FormDialog } from "@encoderinc/mui-dialog-form";
import { NumberInput, SelectInput, TextInput } from "@encoderinc/mui-inputs-core";
import { IModule, ModuleAlias, ModuleStatus, ModuleType } from "@memoryos/types";
import { EnabledLanguages, moduleBucket } from "@memoryos/constants";
import { EntityInput } from "@encoderinc/mui-inputs-entity";

import { validationSchema } from "./validation";
import { ImageInput } from "../../../../components/common/inputs/image";
import { OrderedItems } from "../../../../components/common/inputs/draggable";

export interface IEditModuleDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: Partial<IModule>, formikBag: any) => Promise<void>;
  onImageUpdate: (values: Partial<IModule>, formikBag: any) => Promise<void>;
  initialValues: IModule;
}

export const EditModuleDialog: FC<IEditModuleDialogProps> = props => {
  const { initialValues, ...rest } = props;
  const {
    id,
    title,
    userTitle,
    alias,
    description,
    priority,
    language,
    ratePlanType,
    moduleStatus,
    moduleType,
    imageUrl,
    lessons = [],
    blockedBy = [],
  } = initialValues;

  const fixedValues = {
    id,
    title,
    userTitle,
    alias,
    description,
    priority,
    language,
    ratePlanType,
    moduleStatus,
    moduleType,
    imageUrl,
    lessons: lessons.sort((a, b) => {
      return a.priority - b.priority;
    }),
    lessonIds: lessons.map((lesson: { id: number }) => lesson.id),
    blockedBy: blockedBy.map(module => module.id),
  };
  const message = id ? "dialogs.edit" : "dialogs.add";
  return (
    <FormDialog
      initialValues={fixedValues}
      validationSchema={validationSchema}
      message={message}
      maxWidth="sm"
      {...rest}
    >
      <TextInput name="id" disabled />
      <NumberInput name="priority" disabled />
      <TextInput name="title" />
      <TextInput name="userTitle" />
      <SelectInput name="alias" options={ModuleAlias} />
      <TextInput name="description" />
      <SelectInput name="language" options={EnabledLanguages} />
      <SelectInput name="moduleType" options={ModuleType} />
      <SelectInput name="moduleStatus" options={ModuleStatus} disabled={Boolean(!id)} />
      <ImageInput name={"imageUrl"} bucket={moduleBucket} update={rest.onImageUpdate} />
      <EntityInput name="blockedBy" controller="curriculum/modules" multiple />
      <OrderedItems items={lessons} name={"lessonIds"} />
    </FormDialog>
  );
};
