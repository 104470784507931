import { defaultDecoder } from "qs";
import { StepType } from "@memoryos/types";

// https://github.com/ljharb/qs/issues/91#issuecomment-522289267
export const decoder = (str: string, decoder: defaultDecoder, charset: string): any => {
  const strWithoutPlus = str.replace(/\+/g, " ");
  if (charset === "iso-8859-1") {
    // unescape never throws, no try...catch needed:
    return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
  }

  if (/^(\d+|\d*\.\d+)$/.test(str)) {
    return parseFloat(str);
  }

  const keywords: Record<string, any> = {
    true: true,
    false: false,
    null: null,
    undefined: void 0,
  };

  if (str in keywords) {
    return keywords[str];
  }

  try {
    return decodeURIComponent(strWithoutPlus);
  } catch (e) {
    return strWithoutPlus;
  }
};

export const sortArrayAlphabetically = (arr: string[]): string[] => {
  return arr.sort((a: string, b: string): number => a.localeCompare(b));
};

export const sortStepTypesArrayAlphabetically = (arr: StepType[]): StepType[] => {
  return arr.sort((a: string, b: string): number => {
    if (a === StepType.MESSAGE) a = "TEXT_INSTRUCTION";
    if (b === StepType.MESSAGE) b = "TEXT_INSTRUCTION";
    if (a === StepType.GALLERY) a = "IMAGE_GALLERY";
    if (b === StepType.GALLERY) b = "IMAGE_GALLERY";
    return a.localeCompare(b);
  });
};
