import { FC, Fragment, useState } from "react";
import { Badge, Button, Card, CardActions, CardContent, CardMedia, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { TextInput } from "@encoderinc/mui-inputs-core";
import { ProgressOverlay } from "@encoderinc/mui-progress";
import { ConfirmationDialog } from "@encoderinc/mui-dialog-confirmation";
import { FirebaseFileInput } from "@encoderinc/mui-inputs-file-firebase";
import { ICollectionVideoOption } from "@memoryos/types";
import { openUrlOnClick } from "@encoderinc/popup";

import { useStyles } from "./styles";

interface ICollectionVideoStepProps {
  name: string;
}

export const CollectionVideoStep: FC<ICollectionVideoStepProps> = props => {
  const { name } = props;

  const classes = useStyles();
  const formik = useFormikContext<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteOptionDialogOpen, setIsDeleteOptionDialogOpen] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  const handleOptionDelete = (index: number): (() => void) => {
    return (): void => {
      setSelectedOptionIndex(index);
      setIsDeleteOptionDialogOpen(true);
    };
  };

  const handleDeleteConfirm = (): void => {
    const newValue = formik.values[name];
    newValue.collectionVideoOptions.splice(selectedOptionIndex, 1);

    formik.setFieldValue(name, newValue);
    setIsDeleteOptionDialogOpen(false);
  };

  const handleDeleteCancel = (): void => {
    setIsDeleteOptionDialogOpen(false);
  };

  const handleFileChange = (urls: Array<string>): void => {
    setIsLoading(true);
    const newValue = formik.values[name];
    urls.forEach(url => {
      newValue.collectionVideoOptions.push({
        collectionVideoOptionVideoUrl: url,
        collectionVideoOptionTitle: "",
      });
    });
    formik.setFieldValue(name, newValue);
    setIsLoading(false);
  };

  const handleDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    setIsLoading(true);

    const newValues = formik.values[name];
    const options = newValues.collectionVideoOptions;
    const [removed] = options.splice(result.source.index, 1);
    options.splice(result.destination.index, 0, removed);

    formik.setFieldValue(name, newValues);
    setIsLoading(false);
  };

  return (
    <Fragment>
      <TextInput name={`${name}.collectionVideoPrompt`} />
      <TextInput name={`${name}.collectionVideoQuestion`} />

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {provided => (
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Grid item>
                <ProgressOverlay isLoading={isLoading}>
                  <FirebaseFileInput
                    accept={["video/webm", "video/mp4"]}
                    maxSize={1024 * 1024 * 10}
                    onChange={handleFileChange}
                    classes={{ root: classes.media }}
                    onDropAccepted={() => {
                      setIsLoading(true);
                    }}
                  />
                </ProgressOverlay>
              </Grid>
              {formik.values[name].collectionVideoOptions.map((option: ICollectionVideoOption, i: number) => (
                <Draggable key={i} draggableId={i.toString()} index={i}>
                  {provided => (
                    <Grid item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <Card>
                        <Badge badgeContent={i + 1} color="primary" classes={{ badge: classes.badge }}>
                          <CardMedia
                            component={"video"}
                            src={option.collectionVideoOptionVideoUrl}
                            onClick={openUrlOnClick(option.collectionVideoOptionVideoUrl)}
                            className={classes.media}
                          />
                        </Badge>
                        <CardContent>
                          <TextInput name={`${name}.collectionVideoOptions[${i}].collectionVideoOptionTitle`} />
                        </CardContent>
                        <CardActions>
                          <Button size="small" color="primary" onClick={handleOptionDelete(i)}>
                            <FormattedMessage id="form.buttons.delete" />
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>

      <ConfirmationDialog open={isDeleteOptionDialogOpen} onCancel={handleDeleteCancel} onConfirm={handleDeleteConfirm}>
        <FormattedMessage id="dialogs.delete" values={{ title: "video" }} />
      </ConfirmationDialog>
    </Fragment>
  );
};
