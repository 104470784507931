import { FC, Fragment, useCallback } from "react";
import { useDropzone, FileRejection, DropzoneOptions } from "react-dropzone";
import { IconButton } from "@mui/material";
import { VolumeUp, VolumeOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

import { ACCEPTED_FORMATS, MAX_FILE_SIZE } from "./constants";
import { humanFileSize } from "@encoderinc/mui-inputs-file";

export interface IAudioInputProps extends DropzoneOptions {
  onChange: (files: Array<File>) => void;
}

export const AudioInput: FC<IAudioInputProps> = props => {
  const { disabled, onChange, accept = ACCEPTED_FORMATS, maxSize = MAX_FILE_SIZE, ...rest } = props;
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length) {
      rejectedFiles.forEach(rejectedFile => {
        console.info("rejectedFiles", rejectedFiles);
        if (!accept.includes(rejectedFile.file.type)) {
          enqueueSnackbar(
            formatMessage(
              { id: "components.dropzone.format" },
              {
                type: rejectedFile.file.type,
                accept: Array.isArray(accept) ? accept.join(", ") : accept,
              },
            ),
            { variant: "error" },
          );
        } else if (maxSize < rejectedFile.file.size) {
          enqueueSnackbar(
            formatMessage(
              { id: "components.dropzone.size" },
              {
                size: humanFileSize(rejectedFile.file.size),
                maxSize: humanFileSize(maxSize),
              },
            ),
            { variant: "error" },
          );
        }
      });
    }

    if (acceptedFiles.length) {
      console.info("acceptedFiles", acceptedFiles);
      onChange(acceptedFiles);
    }
  }, []);

  const { getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept,
    maxSize,
    maxFiles: 1,
    ...rest,
  });

  if (disabled) {
    return (
      <IconButton>
        <VolumeOff />
      </IconButton>
    );
  }

  return (
    <Fragment>
      <input {...getInputProps()} />
      <IconButton onClick={open}>
        <VolumeUp />
      </IconButton>
    </Fragment>
  );
};
