import * as Yup from "yup";
import { ModuleAlias, ModuleStatus } from "@memoryos/types";
import { priorityValidationSchema } from "../../../../components/validation";

export const validationSchema = Yup.object().shape({
  title: Yup.string().required("form.validations.valueMissing"),
  userTitle: Yup.string().required("form.validations.valueMissing"),
  alias: Yup.mixed().oneOf(Object.values(ModuleAlias), "form.validations.badInput"),
  moduleStatus: Yup.mixed().oneOf(Object.values(ModuleStatus), "form.validations.badInput"),
  priority: priorityValidationSchema,
});
