import { FC, Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFormikContext } from "formik";

import { ConfirmationDialog } from "@encoderinc/mui-dialog-confirmation";
import { TextInput, NumberInput, CheckboxInput } from "@encoderinc/mui-inputs-core";
import { useDeleteUrl, useUploadUrl } from "@encoderinc/mui-inputs-file-firebase";
import { lessonBucket } from "@memoryos/constants";
import { IRandomWordsTextOption } from "@memoryos/types";

import { AudioInput } from "../../../../../../components/common/inputs/audio";
import { useStepValuesBeforeEditSaved } from "../../../../../../utils/hooks/useStepValuesBeforeEditSaved";

interface IRandomWordsTextStepProps {
  name: string;
  valuesBeforeEdit?: any;
}

export const RandomWordsTextStep: FC<IRandomWordsTextStepProps> = props => {
  const { name, valuesBeforeEdit } = props;

  const { formatMessage } = useIntl();
  const formik = useFormikContext<any>();

  useStepValuesBeforeEditSaved(formik, valuesBeforeEdit, name);

  const [isDeleteImageDialogOpen, setIsDeleteOptionDialogOpen] = useState(false);
  const [selectedImageIndex, setSelectedOptionIndex] = useState(0);

  const handleOptionAdd =
    (field: string): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].push({
        randomWordsTextOptionAudioUrl: "",
        randomWordsTextOptionText: "",
      });
      formik.setFieldValue(name, newValue);
    };

  const deleteUrl = useDeleteUrl(lessonBucket);

  const handleOptionDelete = (index: number): (() => void) => {
    return (): void => {
      setSelectedOptionIndex(index);
      setIsDeleteOptionDialogOpen(true);
    };
  };

  const handleDeleteConfirm = (): void => {
    const newValue = formik.values[name];
    newValue.randomWordsTextOptions.splice(selectedImageIndex, 1);

    formik.setFieldValue(name, newValue);
    setIsDeleteOptionDialogOpen(false);
  };

  const handleDeleteCancel = (): void => {
    setIsDeleteOptionDialogOpen(false);
  };

  const uploadFiles = useUploadUrl(lessonBucket, "");

  const handleAudioChange = (index: number): ((files: File[]) => Promise<void>) => {
    return async (files: File[]): Promise<void> => {
      const urls = await uploadFiles(files);
      const newValue = formik.values[name];
      newValue.randomWordsTextOptions[index].randomWordsTextOptionAudioUrl = urls[0];
      formik.setFieldValue(name, newValue);
    };
  };

  const getRandomizeValue = (): any => {
    const newValue = formik.values[name];
    if (Array.isArray(newValue.randomWordsTextRandomize)) {
      newValue.randomWordsTextRandomize = false;
      formik.setFieldValue(name, newValue);
    }
  };

  const getRandomizeChange = (checked: any): any => {
    const newValue = formik.values[name];
    newValue.randomWordsTextRandomize = checked;
    formik.setFieldValue(name, newValue);
  };

  return (
    <Fragment>
      <TextInput name={`${name}.randomWordsTextPrompt`} />

      <CheckboxInput
        name={`${name}.randomWordsTextRandomize`}
        value={getRandomizeValue()}
        onChange={e => {
          getRandomizeChange(e.target.checked);
        }}
      />

      <Typography>
        <FormattedMessage id="form.labels.randomWordsTextOptions" />
        <Tooltip title={formatMessage({ id: "form.tips.add" })}>
          <IconButton size="large" aria-label="add" onClick={handleOptionAdd("randomWordsTextOptions")}>
            <Add fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>

      {formik.values[name].randomWordsTextOptions.map((_o: IRandomWordsTextOption, i: number) => (
        <Grid container key={i}>
          <Grid item>
            <TextInput
              name={`${name}.randomWordsTextOptions[${i}].randomWordsTextOptionText`}
              InputProps={{
                startAdornment: <InputAdornment position="start">{i + 1}</InputAdornment>,
                endAdornment: (
                  <Fragment>
                    <InputAdornment position="end">
                      <AudioInput onChange={handleAudioChange(i)} />
                      <IconButton aria-label="delete" onClick={handleOptionDelete(i)}>
                        <Delete />
                      </IconButton>
                    </InputAdornment>
                  </Fragment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ))}

      <NumberInput name={`${name}.randomWordsTextInterval`} InputProps={{ inputProps: { min: 0 } }} />

      <ConfirmationDialog open={isDeleteImageDialogOpen} onCancel={handleDeleteCancel} onConfirm={handleDeleteConfirm}>
        <FormattedMessage id="dialogs.delete" values={{ title: "image" }} />
      </ConfirmationDialog>
    </Fragment>
  );
};
