import { FC, ElementType } from "react";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";

type HeadingVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2";
export interface IHeadingProps {
  isLocalized?: boolean;
  textOrLocalizationId: string;
  variant?: HeadingVariant;
  component?: ElementType<string>;
  localizationId?: string;
  mt?: number;
  mb?: number;
}

export const Heading: FC<IHeadingProps> = props => {
  const {
    textOrLocalizationId = "",
    variant = "h6",
    component = "h2",
    isLocalized = true,
    mt = 2,
    mb = 2,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Typography className={classes.darkText} variant={variant} component={component} {...rest} mt={mt} mb={mb}>
      {isLocalized ? <FormattedMessage id={textOrLocalizationId} /> : textOrLocalizationId}
    </Typography>
  );
};
