import * as Yup from "yup";

import { LessonStatus, LessonType } from "@memoryos/types";

import { priorityValidationSchema } from "../../../../components/validation";

export const validationSchema = Yup.object().shape({
  title: Yup.string().required("form.validations.valueMissing"),
  priority: priorityValidationSchema,
  lessonType: Yup.mixed().oneOf(Object.values(LessonType), "form.validations.badInput"),
  lessonStatus: Yup.mixed().oneOf(Object.values(LessonStatus), "form.validations.badInput"),
});
