import { FC } from "react";

import { SelectInput } from "@encoderinc/mui-inputs-core";
import { Currency, IProducts, ProductsType } from "@memoryos/types";
import { useFormikContext } from "formik";

export interface IProductsTypeInputProps {
  productType: ProductsType;
  setProductType: (type: ProductsType) => void;
}

export const ProductsTypeInput: FC<IProductsTypeInputProps> = props => {
  const { productType, setProductType } = props;
  const formik = useFormikContext<IProducts>();

  const handleProductTypeChange = (e: any) => {
    formik.setFieldValue("type", e.target.value);
    formik.setFieldValue("items", []);
    formik.setFieldValue("solidgateAmount", 0);
    formik.setFieldValue("solidgateCurrency", Currency.USD);
    setProductType(e.target.value);
  };

  return (
    <SelectInput name="type" options={ProductsType} value={productType} onChange={e => handleProductTypeChange(e)} />
  );
};
