import { FC, Fragment, useState } from "react";
import { useFormikContext, getIn } from "formik";
import { SelectChangeEvent } from "@mui/material";
import { IStep, StepTimeoutBehavior } from "@memoryos/types";
import { TimeLimitInput } from "../time-limit-input";
import { TimeoutBehaviorInput } from "../timeout-behavior-input";

interface ITimeInputsProps {}

export const TimeInputs: FC<ITimeInputsProps> = _props => {
  const formik = useFormikContext<IStep>();
  const [timeoutBehavior, setTimeoutBehavior] = useState(
    getIn(formik.values, `timeoutBehavior`) || StepTimeoutBehavior.None,
  );

  const handleTimeoutBehaviorChange = (e: SelectChangeEvent<unknown>) => {
    switch (e.target.value) {
      case StepTimeoutBehavior.None:
        formik.setFieldValue(`timeLimit`, 0);
        formik.setFieldValue(`timeoutBehavior`, e.target.value);
        setTimeoutBehavior(e.target.value);
        break;
      case StepTimeoutBehavior.AutoContinue:
      case StepTimeoutBehavior.DeductHeart:
        formik.setFieldValue(`timeoutBehavior`, e.target.value);
        setTimeoutBehavior(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <TimeoutBehaviorInput name={`timeoutBehavior`} value={timeoutBehavior} onChange={handleTimeoutBehaviorChange} />
      <TimeLimitInput name={`timeLimit`} isDisabled={timeoutBehavior === StepTimeoutBehavior.None} />
    </Fragment>
  );
};
