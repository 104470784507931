import { FC, Fragment, useContext } from "react";
import { useIntl } from "react-intl";
import { IconButton, Tooltip } from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";

import { ThemeContext, ThemeType } from "@encoderinc/mui-provider-theme";

export const Theme: FC = () => {
  const { formatMessage } = useIntl();
  const theme = useContext(ThemeContext);

  const handleThemeIconClick = () => {
    theme.changeThemeType(theme.type === ThemeType.light ? ThemeType.dark : ThemeType.light);
  };

  return (
    <Fragment>
      <Tooltip title={formatMessage({ id: "components.header.theme.switch" })}>
        <IconButton color="inherit" onClick={handleThemeIconClick}>
          {theme.type === ThemeType.light ? <Brightness4 /> : <Brightness7 />}
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};
