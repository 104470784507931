import { FC, Fragment } from "react";

import { TextArea, TextInput, SelectInput } from "@encoderinc/mui-inputs-core";
import { DateUtcInput } from "@encoderinc/mui-inputs-picker";
import { DateStepMode } from "@memoryos/types";

interface IDateStepProps {
  name: string;
}

export const DateStep: FC<IDateStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <TextInput name={`${name}.dateQuestion`} />
      <TextInput name={`${name}.datePrompt`} />
      <SelectInput name={`${name}.dateMode`} options={DateStepMode} />
      <DateUtcInput name={`${name}.dateAnswer`} />
      <TextArea name={`${name}.dateAnswerText`} />
      <TextArea name={`${name}.dateWrongAnswerText`} />
    </Fragment>
  );
};
