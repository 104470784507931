import { FC, useContext } from "react";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { PeopleAlt } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { IUserContext, UserContext } from "@encoderinc/provider-user";
import { IUser, UserRole } from "@memoryos/types";

export const Users: FC = () => {
  const user = useContext<IUserContext<IUser>>(UserContext);

  if (!user.profile.userRoles.includes(UserRole.ADMIN)) {
    return null;
  }

  return (
    <List
      component="nav"
      subheader={
        <ListSubheader>
          <FormattedMessage id="pages.dashboard.users.title" />
        </ListSubheader>
      }
    >
      <ListItem button component={RouterLink} to="/users">
        <ListItemIcon>
          <PeopleAlt />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="pages.dashboard.users.users" />
        </ListItemText>
      </ListItem>
      <ListItem button component={RouterLink} to="/admins">
        <ListItemIcon>
          <PeopleAlt />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="pages.dashboard.users.admins" />
        </ListItemText>
      </ListItem>
      <ListItem button component={RouterLink} to="/predefined-admins">
        <ListItemIcon>
          <PeopleAlt />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="pages.dashboard.users.predefinedAdmins" />
        </ListItemText>
      </ListItem>
    </List>
  );
};
