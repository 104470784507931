import { ChangeEvent, FC, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Pagination,
} from "@mui/material";
import { Add, Create, Delete, FilterList, ContentCopy } from "@mui/icons-material";
import { useNavigate, useLocation, useParams } from "react-router";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";

import { ProgressOverlay } from "@encoderinc/mui-progress";
import { PageHeader } from "@encoderinc/mui-page-header";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IPaginationResult, ISearchDto } from "@encoderinc/types-collection";
import { DeleteDialog } from "@encoderinc/mui-dialog-delete";
import { ILesson, LessonStatus } from "@memoryos/types";
import { emptyLesson } from "@memoryos/mocks";

import { Breadcrumbs } from "../../../components/common/breadcrumbs";
import { LessonSearchForm } from "./form";
import { EditLessonDialog } from "./edit";
import { defaultItemsPerPage } from "@memoryos/constants";

export interface ILessonSearchDto extends ISearchDto {
  lessonStatus: Array<LessonStatus>;
}

export const Lesson: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [lessons, setLessons] = useState<Array<ILesson>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedLesson, setSelectedLesson] = useState<ILesson>(emptyLesson);
  const [isFiltersOpen, setIsFilterOpen] = useState(false);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1));

  const [data, setData] = useState<ILessonSearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    query: "",
    lessonStatus: [LessonStatus.ACTIVE],
    ...parsedData,
  });

  const fetchSingleLessonById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/curriculum/lesson/${id}`,
      })
      .then((json: ILesson) => {
        setSelectedLesson(json);
        setCount(1);
      });
  };
  const updateQS = (id?: number) => {
    const { skip: _skip, take: _take, ...rest } = data;
    navigate(id ? `/lessons/${id}` : `/lessons?${stringify(rest)}`);
  };

  const handleEdit = (lesson: ILesson): (() => void) => {
    return (): void => {
      void fetchSingleLessonById(lesson.id.toString());
      setSelectedLesson(lesson);
      setIsEditDialogOpen(true);
      updateQS(lesson.id);
    };
  };

  const handleDelete = (lesson: ILesson): (() => void) => {
    return (): void => {
      setSelectedLesson(lesson);
      setIsDeleteDialogOpen(true);
    };
  };

  const handleDeleteCancel = (): void => {
    setIsDeleteDialogOpen(false);
  };

  const handleEditCancel = (): void => {
    setIsEditDialogOpen(false);
    updateQS();
  };

  const fetchLessonsByQuery = async (): Promise<void> => {
    return api
      .fetchJson({
        url: "/curriculum/lesson",
        data,
      })
      .then((json: IPaginationResult<ILesson>) => {
        setLessons(json.rows);
        setCount(json.count);
        updateQS();
      });
  };

  const fetchLessonsById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/curriculum/lesson/${id}`,
      })
      .then((json: ILesson) => {
        setLessons([json]);
        setCount(1);
      });
  };

  const fetchLessons = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchLessonsById(id) : fetchLessonsByQuery())
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAdd = (lesson: ILesson): void => {
    setSelectedLesson(lesson);
    setIsEditDialogOpen(true);
  };

  const handleDeleteConfirmed = (lesson: ILesson): Promise<void> => {
    return api
      .fetchJson({
        url: `/curriculum/lesson/${lesson.id}`,
        method: "DELETE",
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: "snackbar.deleted" }), { variant: "success" });
        return fetchLessons();
      })
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsDeleteDialogOpen(false);
      });
  };

  const handleEditConfirmed = (values: Partial<ILesson>, formikBag: any): Promise<void> => {
    const { id, ...data } = values;
    return api
      .fetchJson({
        url: id ? `/curriculum/lesson/${id}` : "/curriculum/lesson/",
        method: id ? "PUT" : "POST",
        data,
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: id ? "snackbar.updated" : "snackbar.created" }), { variant: "success" });
        setIsEditDialogOpen(false);
        return fetchLessons();
      })
      .catch((e: ApiError) => {
        if (e.status === 400) {
          formikBag.setErrors(e.getLocalizedValidationErrors());
        } else if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      });
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFiltersOpen);
  };

  const handleChangePage = (e: ChangeEvent<unknown>, page: number) => {
    setData({
      ...data,
      skip: (page - 1) * data.take,
    });
  };

  const handleSubmit = (values: ILessonSearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  useDeepCompareEffect(() => {
    void fetchLessons(id);
  }, [data]);

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "lessons"]} />

      <PageHeader message="pages.lessons.title">
        <Button startIcon={<FilterList />} onClick={toggleFilters}>
          <FormattedMessage id={`form.buttons.${isFiltersOpen ? "hideFilters" : "showFilters"}`} />
        </Button>
        <Button
          startIcon={<Add fontSize="large" color="primary" />}
          onClick={() => {
            handleAdd(emptyLesson);
          }}
          variant="contained"
        >
          <FormattedMessage id="form.buttons.add" />
        </Button>
      </PageHeader>

      <LessonSearchForm onSubmit={handleSubmit} initialValues={data} open={isFiltersOpen} />

      <ProgressOverlay isLoading={isLoading}>
        <List>
          {lessons.map((lesson, i) => (
            <ListItem key={i}>
              <ListItemText
                primary={lesson.title}
                secondary={<FormattedMessage id="pages.lessons.module" values={{ title: lesson.module?.title }} />}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={handleEdit(lesson)}>
                  <Create />
                </IconButton>
                {lesson.lessonStatus === LessonStatus.ACTIVE ? (
                  <IconButton onClick={handleDelete(lesson)}>
                    <Delete />
                  </IconButton>
                ) : null}
                <IconButton
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { id, ...rest } = lesson;
                    rest.title = `${rest.title} copy`;
                    handleAdd(rest as ILesson);
                  }}
                >
                  <ContentCopy />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ProgressOverlay>

      <Pagination
        shape="rounded"
        page={data.skip / data.take + 1}
        count={Math.ceil(count / data.take)}
        onChange={handleChangePage}
      />

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmed}
        open={isDeleteDialogOpen}
        initialValues={selectedLesson}
      />

      <EditLessonDialog
        onCancel={handleEditCancel}
        onConfirm={handleEditConfirmed}
        open={isEditDialogOpen}
        initialValues={selectedLesson}
      />
    </Grid>
  );
};
