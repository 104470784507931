import { FC } from "react";
import { useFormikContext } from "formik";

import { TextInput } from "@encoderinc/mui-inputs-core";
import { IStep, StepType } from "@memoryos/types";

interface ITitleInputProps {
  name: string;
}

export const TitleInput: FC<ITitleInputProps> = props => {
  const { name } = props;

  const formik = useFormikContext<IStep>();

  if (formik.values.stepType === StepType.STREAK) {
    return null;
  }

  return <TextInput name={name} />;
};
