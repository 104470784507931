import { FC, KeyboardEvent, ClipboardEvent } from "react";
import { TextInput } from "@encoderinc/mui-inputs-core";
import { TextFieldProps } from "@mui/material";

import { NumericInputMode } from "./types";

export interface INumericInput {
  name: string;
  mode: NumericInputMode;
}

export const NumericInput: FC<INumericInput & TextFieldProps> = props => {
  const { name, mode, ...rest } = props;

  const preventNonNumericInput = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const preventNonBinaryInput = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!/[0-1]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const preventNonBinaryPaste = (event: ClipboardEvent<HTMLDivElement>) => {
    const paste = (event.clipboardData || (window as any).clipboardData).getData("text");
    if (!/[0-1]/.test(paste)) {
      event.preventDefault();
    }
  };

  const preventNonNumericPaste = (event: ClipboardEvent<HTMLDivElement>) => {
    const paste = (event.clipboardData || (window as any).clipboardData).getData("text");
    if (!/[0-1]/.test(paste)) {
      event.preventDefault();
    }
  };

  switch (mode) {
    case NumericInputMode.BINARY:
      return (
        <TextInput
          name={name}
          {...rest}
          onKeyPress={e => preventNonBinaryInput(e)}
          onPaste={e => preventNonBinaryPaste(e)}
        />
      );
    case NumericInputMode.INTEGER:
      return (
        <TextInput
          name={name}
          {...rest}
          onKeyPress={e => preventNonNumericInput(e)}
          onPaste={e => preventNonNumericPaste(e)}
        />
      );
  }
};
