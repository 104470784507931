import { FC, useState, Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormikContext } from "formik";
import { SelectInput } from "@encoderinc/mui-inputs-core";
import { IStep, StepTimeoutBehavior, StepType } from "@memoryos/types";
import { Dialog, Typography } from "@mui/material";

interface ITimeoutBehaviorInputProps {
  name: string;
  value: StepTimeoutBehavior;
  onChange: (e: any) => void;
}

export const TimeoutBehaviorInput: FC<ITimeoutBehaviorInputProps> = props => {
  const { name, value, onChange } = props;

  const formik = useFormikContext<IStep>();
  const { formatMessage } = useIntl();

  const [isClicked, setIsClicked] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

  const handleFocus = () => {
    if (!isClicked) {
      setIsWarningDialogOpen(true);
      setIsClicked(true);
    }
  };

  if (
    // info
    formik.values.stepType === StepType.VIDEO ||
    formik.values.stepType === StepType.COLLECTION_IMAGE ||
    formik.values.stepType === StepType.COLLECTION_VIDEO ||
    // feedback
    formik.values.stepType === StepType.FEEDBACK_INPUT ||
    formik.values.stepType === StepType.MULTIPLE_CHOICE_FEEDBACK ||
    formik.values.stepType === StepType.SLIDER_FEEDBACK ||
    // test
    formik.values.stepType === StepType.COMPARE_TEST_RESULTS ||
    formik.values.stepType === StepType.RANDOM_WORDS_TEXT ||
    formik.values.stepType === StepType.RANDOM_WORDS_IMAGE ||
    formik.values.stepType === StepType.RANDOM_WORDS_INPUT ||
    formik.values.stepType === StepType.TIMER ||
    // system
    formik.values.stepType === StepType.SCORE ||
    formik.values.stepType === StepType.STREAK
  ) {
    return null;
  }

  return (
    <Fragment>
      <SelectInput name={name} options={StepTimeoutBehavior} value={value} onChange={onChange} onFocus={handleFocus} />
      <Dialog
        title={formatMessage({ id: "dialogs.stepTimeoutBehaviorInputWarningDialog.title" })}
        open={isWarningDialogOpen}
        onClose={() => setIsWarningDialogOpen(false)}
      >
        <Typography style={{ padding: "25px" }}>
          <b>
            <FormattedMessage id="dialogs.stepTimeoutBehaviorInputWarningDialog.text1" />
          </b>
          <br />
          <br />
          <FormattedMessage id="dialogs.stepTimeoutBehaviorInputWarningDialog.text2" />
          <br />
          <br />
          <FormattedMessage id="dialogs.stepTimeoutBehaviorInputWarningDialog.text3" />
        </Typography>
      </Dialog>
    </Fragment>
  );
};
