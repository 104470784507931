import { ChangeEvent, FC, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Pagination,
} from "@mui/material";
import { Add, Create, Delete } from "@mui/icons-material";
import { useNavigate, useLocation, useParams } from "react-router";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ISearchDto, IPaginationResult } from "@encoderinc/types-collection";

import { ProgressOverlay } from "@encoderinc/mui-progress";
import { PageHeader } from "@encoderinc/mui-page-header";
import { DeleteDialog } from "@encoderinc/mui-dialog-delete";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IAdmin } from "@memoryos/types";
import { emptyAdmin } from "@memoryos/mocks";

import { Breadcrumbs } from "../../components/common/breadcrumbs";
import { EditAdminDialog } from "./edit";
import { AdminSearchForm } from "./form";
import { defaultItemsPerPage } from "@memoryos/constants";

export const Admin: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [admins, setAdmins] = useState<Array<IAdmin>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedUser, setSelectedAdmin] = useState<IAdmin>(emptyAdmin);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1));

  const [data, setData] = useState<ISearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    query: "",
    ...parsedData,
  });

  const updateQS = (id?: number) => {
    const { skip: _skip, take: _take, ...rest } = data;
    navigate(id ? `/predefined-admins/${id}` : `/predefined-admins?${stringify(rest)}`);
  };

  const handleEdit = (admin: IAdmin): (() => void) => {
    return (): void => {
      setSelectedAdmin(admin);
      setIsEditDialogOpen(true);
      updateQS(admin.id);
    };
  };

  const fetchAdminsByQuery = async (): Promise<void> => {
    return api
      .fetchJson({
        url: "/admins",
        data,
      })
      .then((json: IPaginationResult<IAdmin>) => {
        setAdmins(json.rows);
        setCount(json.count);
        updateQS();
      });
  };

  const fetchAdminsById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/admins/${id}`,
      })
      .then((json: IAdmin) => {
        setAdmins([json]);
        setCount(1);
        handleEdit(json)();
      });
  };

  const fetchAdmins = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchAdminsById(id) : fetchAdminsByQuery())
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = (admin: IAdmin): (() => void) => {
    return (): void => {
      setSelectedAdmin(admin);
      setIsDeleteDialogOpen(true);
    };
  };

  const handleDeleteCancel = (): void => {
    setIsDeleteDialogOpen(false);
  };

  const handleEditCancel = (): void => {
    setIsEditDialogOpen(false);
    updateQS();
  };

  const handleDeleteConfirmed = (admin: IAdmin): Promise<void> => {
    return api
      .fetchJson({
        url: `/admins/${admin.id}`,
        method: "DELETE",
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: "snackbar.deleted" }), { variant: "success" });
        return fetchAdmins();
      })
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsDeleteDialogOpen(false);
      });
  };

  const handleEditConfirmed = (values: Partial<IAdmin>, formikBag: any): Promise<void> => {
    const { id, ...data } = values;
    return api
      .fetchJson({
        url: id ? `/admins/${id}` : "/admins",
        method: id ? "PUT" : "POST",
        data,
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: id ? "snackbar.updated" : "snackbar.created" }), { variant: "success" });
        setIsEditDialogOpen(false);
        return fetchAdmins();
      })
      .catch((e: ApiError) => {
        if (e.status === 400) {
          formikBag.setErrors(e.getLocalizedValidationErrors());
        } else if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      });
  };

  const handleChangePage = (e: ChangeEvent<unknown>, page: number) => {
    setData({
      ...data,
      skip: (page - 1) * data.take,
    });
  };

  const handleSubmit = (values: ISearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  const handleAdd = (): void => {
    setSelectedAdmin(emptyAdmin);
    setIsEditDialogOpen(true);
  };
  useDeepCompareEffect(() => {
    void fetchAdmins(id);
  }, [data]);
  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "admins"]} />

      <PageHeader message={`pages.admins.predefinedTitle`}>
        <Button startIcon={<Add fontSize="large" color="primary" />} onClick={handleAdd} variant="contained">
          <FormattedMessage id="form.buttons.add" />
        </Button>
      </PageHeader>

      <AdminSearchForm onSubmit={handleSubmit} initialValues={data} />

      <ProgressOverlay isLoading={isLoading}>
        <List>
          {admins.map(admin => (
            <ListItem key={admin.id}>
              <ListItemText>
                {admin.displayName || "N/A"} {admin.email || ""}
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton onClick={handleEdit(admin)}>
                  <Create />
                </IconButton>
                <IconButton onClick={handleDelete(admin)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ProgressOverlay>

      <Pagination
        shape="rounded"
        page={data.skip / data.take + 1}
        count={Math.ceil(count / data.take)}
        onChange={handleChangePage}
      />

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmed}
        open={isDeleteDialogOpen}
        initialValues={selectedUser}
        getTitle={(admin: IAdmin) => admin.displayName}
      />

      <EditAdminDialog
        onCancel={handleEditCancel}
        onConfirm={handleEditConfirmed}
        open={isEditDialogOpen}
        initialValues={selectedUser}
      />
    </Grid>
  );
};
