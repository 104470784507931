import { FC } from "react";

import { NumberInput } from "@encoderinc/mui-inputs-core";

interface IMaxScoreInputProps {
  name: string;
}

export const MaxScoreInput: FC<IMaxScoreInputProps> = props => {
  const { name } = props;
  return <NumberInput name={name} InputProps={{ inputProps: { min: 0 } }} />;
};
