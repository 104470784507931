import { FC } from "react";
import { format, intervalToDuration, parseISO } from "date-fns";
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { ConfirmationDialog } from "@encoderinc/mui-dialog-confirmation";
import { IAnswer } from "@memoryos/types";
import { humanReadableDateTimeFormat } from "@memoryos/constants";

export interface IViewAnswerDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  initialValues: IAnswer;
}

export const ViewAnswerDialog: FC<IViewAnswerDialogProps> = props => {
  const { initialValues, onConfirm, ...rest } = props;

  const { id, time, user, step, answer, rawScore, maxScore, createdAt } = initialValues;

  const handleConfirm = (): void => {
    onConfirm();
  };

  const duration = intervalToDuration({ start: 0, end: time });

  return (
    <ConfirmationDialog message="dialogs.view" onConfirm={handleConfirm} {...rest}>
      <Typography variant="h5">Answer #{id}</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="streak table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <FormattedMessage id="pages.answers.user" />
              </TableCell>
              <TableCell align="right">
                <Link component={RouterLink} to={`/users/${user!.id}`}>
                  {user!.displayName}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <FormattedMessage id="pages.answers.stepType" />
              </TableCell>
              <TableCell align="right">
                <Link component={RouterLink} to={`/steps/${step!.id}`}>
                  <FormattedMessage id={`enums.stepType.${step!.stepType}`} />
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <FormattedMessage id="pages.answers.answer" />
              </TableCell>
              <TableCell align="right">{JSON.stringify(answer)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <FormattedMessage id="pages.answers.score" />
              </TableCell>
              <TableCell align="right">
                {rawScore} / {maxScore}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <FormattedMessage id="pages.answers.time" />
              </TableCell>
              <TableCell align="right">
                {duration.minutes}′ {duration.seconds}″
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <FormattedMessage id="pages.answers.createdAt" />
              </TableCell>
              <TableCell align="right">{format(parseISO(createdAt), humanReadableDateTimeFormat)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ConfirmationDialog>
  );
};
