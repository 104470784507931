import * as Yup from "yup";
import { Frame, RatePlanType, Term } from "@memoryos/types";

export const validationSchema = Yup.object()
  .shape({
    code: Yup.string().required("form.validations.valueMissing"),
    userId: Yup.string().nullable().notRequired(),
    userEmail: Yup.string().email("form.validations.badInput").nullable().notRequired(),
    userFid: Yup.string().nullable().notRequired(),
    userFbid: Yup.string().nullable().notRequired(),
    activateTimes: Yup.number().required("form.validations.valueMissing").min(0),
    activateTimesMax: Yup.number().required("form.validations.valueMissing").min(0),
    lociBonus: Yup.number().required("form.validations.valueMissing").min(0),
    ratePlanType: Yup.mixed().notRequired(),
    moduleData: Yup.array(),
    frame: Yup.mixed().oneOf(Object.values(Frame)),
    term: Yup.mixed().oneOf(Object.values(Term)),
  })
  .test({
    name:'validUserId',
    test: function (values){

      if (values.userId === null || values.userId === undefined){
        return true;
      }

      const digitsOnly = /^\d+$/;
      if (!digitsOnly.test(values.userId)) {
        return this.createError({
          path: "userId",
          message: "form.validations.badInput",
        });
      }

      return true;
    },
  })
  .test({
    name:'validActivatedTimesForUser',
    test: function (values){
      if ((values.userId === null || values.userId === undefined) &&
        (values.userEmail === null || values.userEmail === undefined) &&
        (values.userFid === null || values.userFid === undefined) &&
        (values.userFbid === null || values.userFbid === undefined)){
        return true;
      }

      const activatedTimesMax = Number(values.activateTimesMax);
      if (activatedTimesMax !== 1) {
        const message = activatedTimesMax > 1 ? "form.validations.rangeOverflow" : "form.validations.rangeUnderflow";
        return this.createError({
          path: "activateTimesMax",
          message: message,
        });
      }

      return true;
    },
  })
  .test({
    name: "atLeastOneRequired",
    test: function (values) {
      if (
        !values.lociBonus &&
        values.ratePlanType === RatePlanType.GUEST &&
        (!values.moduleData || values.moduleData.length === 0) &&
        values.frame === Frame.NONE
      ) {
        return this.createError({
          path: "lociBonus",
          message: "form.validations.promoCodeValuesMissing",
        });
      }

      if (values.ratePlanType !== RatePlanType.GUEST && values.term === Term.NONE) {
        return this.createError({
          path: "ratePlanType",
          message: "form.validations.subscriptionTermMissing",
        });
      }

      if (values.ratePlanType === RatePlanType.GUEST && values.term !== Term.NONE) {
        return this.createError({
          path: "ratePlanType",
          message: "form.validations.subscriptionTypeMissing",
        });
      }

      return true;
    },
  });
