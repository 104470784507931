import { FC, useState } from "react";

import { FormDialog } from "@encoderinc/mui-dialog-form";
import { SelectInput } from "@encoderinc/mui-inputs-core";
import { IStep, StepStatus, StepType } from "@memoryos/types";

import {
  imageMapValidationSchema,
  basicValidationSchema,
  playingCardsValidationSchema,
  simpleInputValidationSchema,
} from "./validation";
import { StepDataInput } from "./data-input";
import { StepTypeInput } from "./type-input";
import { TitleInput } from "./title-input";
import { MaxScoreInput } from "./max-score-input";
import { TutorialInput } from "./tutorial-input";
import { Heading } from "../../../../components/common/heading";
import { SkipCorrectStatementInput } from "./skip-correct-statement-input";
import useDeepCompareEffect from "use-deep-compare-effect";
import { TimeInputs } from "./time-inputs";

export interface IEditStepDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: Partial<IStep>, formikBag: any) => Promise<void>;
  initialValues: IStep;
  valuesBeforeEdit?: any;
}

export const EditStepDialog: FC<IEditStepDialogProps> = props => {
  const { initialValues, valuesBeforeEdit, ...rest } = props;

  const { id, stepType, isTutorial, skipCorrectStatement } = initialValues;
  const fixedValues = JSON.parse(JSON.stringify(initialValues));
  delete fixedValues.createdAt;
  delete fixedValues.updatedAt;
  delete fixedValues.lessons;
  const [stepTypeValue, setStepTypeValue] = useState<StepType>(stepType);
  const message = id ? "dialogs.edit" : "dialogs.add";
  useDeepCompareEffect(() => {
    if (initialValues?.stepType && initialValues?.stepType !== stepTypeValue) {
      setStepTypeValue(initialValues.stepType);
    }
  }, [initialValues]);
  let validationSchema = basicValidationSchema;

  switch (stepTypeValue) {
    case StepType.IMAGE_MAP:
      validationSchema = imageMapValidationSchema;
      break;
    case StepType.PLAYING_CARDS:
      validationSchema = playingCardsValidationSchema;
      break;
    case StepType.SIMPLE_INPUT:
      validationSchema = simpleInputValidationSchema;
      break;
    default:
      break;
  }

  return (
    <FormDialog
      initialValues={fixedValues}
      validationSchema={validationSchema}
      message={message}
      maxWidth="lg"
      {...rest}
    >
      <StepTypeInput name="stepType" setStepTypeValue={setStepTypeValue} />
      {id ? <SelectInput name="stepStatus" options={StepStatus} /> : null}
      <TitleInput name="title" />
      <StepDataInput name="data" valuesBeforeEdit={valuesBeforeEdit} />
      <Heading textOrLocalizationId="form.labels.editModalCommonSections" />
      <MaxScoreInput name="maxScore" />
      <TimeInputs />
      <TutorialInput name="isTutorial" checked={isTutorial} />
      <SkipCorrectStatementInput name={"skipCorrectStatement"} checked={skipCorrectStatement} />
    </FormDialog>
  );
};
