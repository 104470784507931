import React, { FC, useState, useEffect } from "react";

import { FormDialog } from "@encoderinc/mui-dialog-form";
import { NumberInput, SelectInput, TextInput } from "@encoderinc/mui-inputs-core";
import { DateInput } from "@encoderinc/mui-inputs-picker";
import { Frame, IPromoCode, ModuleAlias, Term, RatePlanType, IUser } from "@memoryos/types";
import { DynamicSelectInput } from "../../../../components/common/inputs/select";
import { FormattedMessage } from "react-intl";
import { Typography, List, ListItem, Button, Box, IconButton} from "@mui/material";
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { validationSchema } from "./validation";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import HorizontalLayout from "../../../../components/common/horizontal-layout/HorizontalLayout";
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

export interface IEditPromoCodeDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: Partial<IPromoCode>, formikBag: any) => Promise<void>;
  generateUniquePromoCode: () => Promise<string>;
  initialValues: IPromoCode;
}

export const EditPromoCodeDialog: FC<IEditPromoCodeDialogProps> = props => {
  const { initialValues, ...rest } = props;
  const [isGeneratingPromoCode, setIsGeneratingPromoCode] = useState(false);
  const [generatedCode, setGeneratedCode] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setGeneratedCode('');
  }, [props.open]);

  const classes = useStyles();

  const {
    id,
    code,
    userId,
    userEmail,
    userFid,
    userFbid,
    activateTimes,
    activateTimesMax,
    term,
    lociBonus,
    moduleData = [],
    ratePlanType,
    expiresAt,
    frame,
    users,
  } = initialValues;
  const fixedValues = {
    id,
    code,
    activateTimes,
    userEmail,
    userId,
    userFid,
    userFbid,
    activateTimesMax,
    lociBonus,
    moduleData,
    term,
    ratePlanType,
    expiresAt,
    frame,
  };

  async function handleGeneratePromoCode() {
    setIsGeneratingPromoCode(true);
    setGeneratedCode(await props.generateUniquePromoCode());
    setIsGeneratingPromoCode(false);
  }

  async function handleCopy() {
    navigator.clipboard.writeText(generatedCode);
    setCopied(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setCopied(false);
  }

  const renderUserString = (user: IUser) => {
    const { displayName, email, fid } = user;
    if (displayName && email) {
      return `${displayName} (${email}) - ${fid}`;
    }

    if (displayName) {
      return `${displayName} (N/A) - ${fid}`;
    }

    if (email) {
      return `N/A ${email} - ${fid}`;
    }

    return `N/A (N/A) - ${fid}`;
  };

  const renderUsersList = () => {
    if (!users?.length) {
      return (
        <Typography className={classes.noCodesMessage}>
          <FormattedMessage id="pages.promoCodes.edit.noUsers" />
        </Typography>
      );
    }

    return (
      <List>
        {users?.map((user: IUser) => {
          return (
            <ListItem key={user.fid}>
              <Link to={`/users/${user.fid}`} target="_blank" rel="noopener noreferrer">
                {renderUserString(user)}
              </Link>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const message = id ? "dialogs.edit" : "dialogs.add";
  const disabled = activateTimesMax >= 1 && activateTimes >= activateTimesMax;
  return (
    <FormDialog initialValues={fixedValues} validationSchema={validationSchema} message={message} {...rest}>
      {disabled ? (
        <FormattedMessage id="form.messages.promo-code-activated" />
      ) : (
        <FormattedMessage id="form.messages.empty" />
      )}

      {/*Code & Limitations*/}
      <Grid container spacing={4} style={{ display: 'flex', alignItems: 'center' }}>
        <Grid item xs={8}><TextInput name="code" onFocus={(event) => event.currentTarget.select() } disabled={disabled} /></Grid>
        <Grid item xs={4}><DateInput name="expiresAt" /></Grid>
      </Grid>
      <Grid container spacing={4} style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Grid item xs={8}>
          {generatedCode ?
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>{generatedCode}</Typography>
              <IconButton onClick={handleCopy}>
                <ContentCopyOutlinedIcon color={copied ? "primary" : "inherit"} />
              </IconButton>
              <Typography>{copied ? <FormattedMessage id="pages.promoCodes.edit.copiedMessage" /> : ""}</Typography>
            </div>
            : null}
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" onClick={handleGeneratePromoCode} disabled={isGeneratingPromoCode || disabled} style={{ whiteSpace: "nowrap" }}>
            {isGeneratingPromoCode ? <CircularProgress size={24} /> :
              <FormattedMessage id="pages.promoCodes.edit.generatePromoCode" />}
          </Button>
        </Grid>
      </Grid>

      <br/>
      <br/>
      <Grid container spacing={4} style={{ display: 'flex', alignItems: 'center' }}>
        <Grid item xs={8}><NumberInput name="activateTimesMax" disabled={disabled} /></Grid>
        <Grid item xs={4}><NumberInput name="activateTimes" disabled={true} /></Grid>
      </Grid>


      {/*User Settings*/}
      <Box sx={{ mt: 8}}><Divider sx={{ fontWeight: 'bold' }}><FormattedMessage id="pages.promoCodes.edit.section.userSettings" /></Divider></Box>
      <Grid container spacing={1}>
        <Grid item xs={6}> <TextInput name="userId" onFocus={(event) => event.currentTarget.select()} disabled={disabled} /></Grid>
        <Grid item xs={6}> <TextInput name="userEmail" onFocus={(event) => event.currentTarget.select()} disabled={disabled} /></Grid>
        <Grid item xs={6}> <TextInput name="userFid" onFocus={(event) => event.currentTarget.select()} disabled={disabled} /></Grid>
        <Grid item xs={6}> <TextInput name="userFbid" onFocus={(event) => event.currentTarget.select()} disabled={disabled} /></Grid>
      </Grid>

      {/*Rewards*/}
      <Box sx={{ mt: 8}}><Divider sx={{ fontWeight: 'bold' }}><FormattedMessage id="pages.promoCodes.edit.section.rewards" /></Divider></Box>
      <HorizontalLayout labelId="pages.promoCodes.edit.rewardGroup.loci">
        <NumberInput name="lociBonus" disabled={disabled} />
      </HorizontalLayout>
      <HorizontalLayout labelId="pages.promoCodes.edit.rewardGroup.subscription">
        <DynamicSelectInput name="ratePlanType" disabled={disabled} options={RatePlanType} />
        <DynamicSelectInput name="term" disabled={disabled} options={Term} />
      </HorizontalLayout>
      <HorizontalLayout labelId="pages.promoCodes.edit.rewardGroup.add-ons">
        <SelectInput
          name="moduleData"
          options={[ModuleAlias.QUIZ_CHAMP, ModuleAlias.SPANISH_VOCAB, ModuleAlias.ENGLISH_VOCAB, ModuleAlias.BIOLOGY]}
          disabled={disabled}
          multiple
        />
        <SelectInput name="frame" options={Frame} disabled={disabled} />
      </HorizontalLayout>

      {/*Usage Info*/}
      <Box sx={{ mt: 8}}><Divider sx={{ fontWeight: 'bold' }}><FormattedMessage id="pages.promoCodes.edit.section.usageInfo" /></Divider></Box>
      <FormattedMessage id="pages.promoCodes.edit.users" />
      <br />
      {renderUsersList()}
    </FormDialog>
  );
};
