import { FC, Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFormikContext } from "formik";

import { IChatAnswerOption, IChatQuestionOption } from "@memoryos/types";
import { CheckboxInput, TextInput, TextArea } from "@encoderinc/mui-inputs-core";

interface IChatStepProps {
  name: string;
}

export const ChatStep: FC<IChatStepProps> = props => {
  const { name } = props;

  const { formatMessage } = useIntl();
  const formik = useFormikContext<any>();

  const handleAnswerOptionAdd =
    (field: string): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].push({
        chatAnswerOptionText: "",
        chatAnswerOptionIsCorrect: false,
      });
      formik.setFieldValue(name, newValue);
    };

  const handleQuestionOptionAdd =
    (field: string): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].push({
        chatQuestionText: "",
      });
      formik.setFieldValue(name, newValue);
    };

  const handleOptionDelete =
    (field: string, i: number): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].splice(i, 1);
      formik.setFieldValue(name, newValue);
    };

  return (
    <Fragment>
      <TextInput name={`${name}.chatQuestionTitle`} />

      <Typography>
        <FormattedMessage id="form.labels.chatQuestionOptions" />
        <Tooltip title={formatMessage({ id: "form.tips.add" })}>
          <IconButton size="large" aria-label="add" onClick={handleQuestionOptionAdd("chatQuestionOptions")}>
            <Add fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>

      {formik.values[name].chatQuestionOptions.map((_o: IChatQuestionOption, i: number) => (
        <Grid container key={i}>
          <Grid item>
            <TextInput
              name={`${name}.chatQuestionOptions[${i}].chatQuestionText`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="delete" onClick={handleOptionDelete("chatQuestionOptions", i)}>
                      <Delete />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ))}

      <TextInput name={`${name}.chatAnswerTitle`} />
      <Typography>
        <FormattedMessage id="form.labels.chatAnswerOptions" />
        <Tooltip title={formatMessage({ id: "form.tips.add" })}>
          <IconButton size="large" aria-label="add" onClick={handleAnswerOptionAdd("chatAnswerOptions")}>
            <Add fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>

      {formik.values[name].chatAnswerOptions.map((_o: IChatAnswerOption, i: number) => (
        <Grid container key={i}>
          <Grid item>
            <TextInput
              name={`${name}.chatAnswerOptions[${i}].chatAnswerOptionText`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="delete" onClick={handleOptionDelete("chatAnswerOptions", i)}>
                      <Delete />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CheckboxInput name={`${name}.chatAnswerOptions[${i}].chatAnswerOptionIsCorrect`} />
          </Grid>
        </Grid>
      ))}

      <TextArea name={`${name}.chatAnswerText`} />
      <TextArea name={`${name}.chatWrongAnswerText`} />
    </Fragment>
  );
};
