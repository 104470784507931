import { FC, Fragment, useState } from "react";
import { TextArea, TextInput } from "@encoderinc/mui-inputs-core";
import { SimpleInputStepValidationMode, SimpleInputStepRepresentationMode } from "@memoryos/types";
import { Grid, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ProgressOverlay } from "@encoderinc/mui-progress";
import { FirebaseFileInput } from "@encoderinc/mui-inputs-file-firebase";
import { useStyles } from "./styles";
import { useFormikContext, getIn } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { DynamicSelectInput } from "../../../../../../components/common/inputs/select";
import { openUrlOnClick } from "@encoderinc/popup";

interface ISimpleInputStepProps {
  name: string;
}

export const SimpleInputStep: FC<ISimpleInputStepProps> = props => {
  const { name } = props;

  const classes = useStyles();

  const formik = useFormikContext<any>();
  const { errors, touched } = formik;

  const { formatMessage } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [questionImageUrl, setQuestionImageUrl] = useState<string | null>(
    getIn(formik.values, `${name}.simpleInputQuestionImageUrl`) || null,
  );

  const handleFileChange = (urls: Array<string>): void => {
    let titleUrl = "";
    urls.forEach(url => {
      titleUrl = url.split("?")[0];
    });
    formik.setFieldValue(`${name}.simpleInputQuestionImageUrl`, titleUrl);
    setQuestionImageUrl(titleUrl);
    setIsLoading(false);
  };

  const handleQuestionImageUrlDelete = (): void => {
    formik.setFieldValue(`${name}.simpleInputQuestionImageUrl`, null);
    setQuestionImageUrl(null);
  };

  return (
    <Fragment>
      <TextInput name={`${name}.simpleInputQuestion`} />
      <br />
      <br />
      <FormattedMessage id="form.labels.simpleInputQuestionImageUrl" />
      <Grid container spacing={1} alignItems="flex-end" classes={{ root: classes.imageContainer }}>
        {questionImageUrl ? (
          <Fragment>
            <div className={classes.card}>
              <img
                src={`${questionImageUrl}?alt=media`}
                className={classes.media}
                onClick={openUrlOnClick(`${questionImageUrl}?alt=media`)}
              />
            </div>
            <IconButton aria-label="delete" onClick={handleQuestionImageUrlDelete}>
              <Delete />
            </IconButton>
          </Fragment>
        ) : (
          <ProgressOverlay isLoading={isLoading}>
            <FirebaseFileInput
              accept={["image/jpeg", "image/png"]}
              onDropAccepted={() => setIsLoading(true)}
              onChange={handleFileChange}
              classes={{ root: classes.fileInput }}
            />
            {/* @ts-ignore */}
            {errors?.data?.simpleInputQuestionImageUrl &&
              // @ts-ignore
              errors?.data?.simpleInputQuestion &&
              // @ts-ignore
              touched?.data?.simpleInputQuestion && (
                <div className={classes.errorHint}>
                  {/* @ts-ignore */}
                  {formatMessage({ id: errors.data.simpleInputQuestionImageUrl }, { label: "Image" })}
                </div>
              )}
          </ProgressOverlay>
        )}
      </Grid>
      <TextInput name={`${name}.simpleInputPrompt`} />
      <DynamicSelectInput name={`${name}.simpleInputValidationMode`} options={SimpleInputStepValidationMode} />
      <DynamicSelectInput name={`${name}.simpleInputRepresentationMode`} options={SimpleInputStepRepresentationMode} />
      <TextInput name={`${name}.simpleInputAnswer`} />
      <TextArea name={`${name}.simpleInputAnswerText`} />
      <TextArea name={`${name}.simpleInputWrongAnswerText`} />
    </Fragment>
  );
};
