import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  () => ({
    noUsersMessage: {
      fontSize: "15px",
      marginTop: "5px",
    },
  }),
  { name: "PromoCodeEdit" },
);
