import { FC, Fragment, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { Button, Grid, IconButton } from "@mui/material";
import { Add, Create, Delete } from "@mui/icons-material";
import { Breadcrumbs } from "../../components/common/breadcrumbs";
import { FormattedMessage, useIntl } from "react-intl";
import { IPaginationResult, ISearchDto } from "@encoderinc/types-collection";
import { DeleteDialog } from "@encoderinc/mui-dialog-delete";
import { CommonSearchForm } from "@encoderinc/mui-form-search";

import { EditVideoRegistryEntityDialog } from "./edit";
import { PageHeader } from "@encoderinc/mui-page-header";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IVideoRegistryEntity } from "@memoryos/types";
import { emptyVideoRegistryEntity } from "@memoryos/mocks";
import { defaultItemsPerPage } from "@memoryos/constants";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useLocation, useNavigate, useParams } from "react-router";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { normalizeVideoRegistryEntity } from "./utils";

export const VideoRegistry: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [entities, setEntities] = useState<Array<IVideoRegistryEntity>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedEntity, setSelectedEntity] = useState<IVideoRegistryEntity>(emptyVideoRegistryEntity);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1));

  const [data, setData] = useState<ISearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    query: "",
    ...parsedData,
  });

  const updateQS = (id?: number) => {
    const { skip: _skip, take: _take, ...rest } = data;
    navigate(id ? `/video/registry/${id}` : `/video/registry?${stringify(rest)}`);
  };

  const handleEdit = (entity: IVideoRegistryEntity): (() => void) => {
    return (): void => {
      setSelectedEntity(entity);
      setIsEditDialogOpen(true);
      updateQS(entity.id);
    };
  };

  const handleDelete = (entity: IVideoRegistryEntity): (() => void) => {
    return (): void => {
      setSelectedEntity(entity);
      setIsDeleteDialogOpen(true);
    };
  };

  const fetchEntitiesByQuery = async (): Promise<void> => {
    return api
      .fetchJson({
        url: "/video/registry",
        data,
      })
      .then((json: IPaginationResult<IVideoRegistryEntity>) => {
        setEntities(json.rows);
        setCount(json.count);
        updateQS();
      });
  };

  const fetchEntitiesById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/video/registry/${id}`,
      })
      .then((json: IVideoRegistryEntity) => {
        setEntities([json]);
        setCount(1);
        handleEdit(json)();
      });
  };

  const fetchEntities = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchEntitiesById(id) : fetchEntitiesByQuery())
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAdd = (): void => {
    setSelectedEntity(emptyVideoRegistryEntity);
    setIsEditDialogOpen(true);
  };

  const handleDeleteConfirmed = (entity: IVideoRegistryEntity): Promise<void> => {
    return api
      .fetchJson({
        url: `/video/registry/${entity.id}`,
        method: "DELETE",
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: "snackbar.deleted" }), { variant: "success" });
        return fetchEntities();
      })
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsDeleteDialogOpen(false);
      });
  };

  const handleEditConfirmed = (values: Partial<IVideoRegistryEntity>, formikBag: any): Promise<void> => {
    const { id, ...data } = values;

    return api
      .fetchJson({
        url: id ? `/video/registry/${id}` : "/video/registry/",
        method: id ? "PUT" : "POST",
        data: normalizeVideoRegistryEntity(data),
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: id ? "snackbar.updated" : "snackbar.created" }), { variant: "success" });
        setIsEditDialogOpen(false);
        return fetchEntities();
      })
      .catch((e: ApiError) => {
        if (e.status === 400) {
          formikBag.setErrors(e.getLocalizedValidationErrors());
        } else if (e.status === 404) {
          enqueueSnackbar(formatMessage({ id: `snackbar.videoRegistryEntitySaveError404` }), { variant: "error" });
        } else if (e.status === 500) {
          enqueueSnackbar(formatMessage({ id: `snackbar.internalServerError` }), { variant: "error" });
        } else if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      });
  };

  const handleChangePage = (page: number): void => {
    setData({
      ...data,
      skip: page * data.take,
      take: data.take,
    });
  };

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setData({
      ...data,
      skip: 0,
      take: pageSize,
    });
  };

  const handleSubmit = (values: ISearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  const handleDeleteCancel = (): void => {
    setIsDeleteDialogOpen(false);
  };

  const handleEditCancel = async (): Promise<void> => {
    await fetchEntitiesByQuery();
    setIsEditDialogOpen(false);
    updateQS();
  };

  const columns: GridColDef[] = [
    {
      field: "key",
      headerName: formatMessage({ id: "form.labels.videoKey" }),
      align: "left",
      width: 220,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IVideoRegistryEntity, any>) => {
        const entity = params.row;
        return <strong>{entity.key}</strong>;
      },
    },
    {
      field: "title",
      headerName: formatMessage({ id: "form.labels.videoTitle" }),
      align: "left",
      width: 220,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IVideoRegistryEntity, any>) => {
        const entity = params.row;
        return <strong>{entity.title}</strong>;
      },
    },
    {
      field: "vimeo_id",
      headerName: formatMessage({ id: "form.labels.videoVimeoId" }),
      align: "left",
      width: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IVideoRegistryEntity, any>) => {
        const entity = params.row;
        return <strong>{entity.vimeo_data?.id || "-"}</strong>;
      },
    },
    {
      field: "sprout_id",
      headerName: formatMessage({ id: "form.labels.videoSproutId" }),
      align: "left",
      width: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IVideoRegistryEntity, any>) => {
        const entity = params.row;
        return <strong>{entity.sprout_data?.id || "-"}</strong>;
      },
    },
    {
      field: "bunny_data",
      headerName: formatMessage({ id: "form.labels.videoBunnyId" }),
      align: "left",
      width: 230,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IVideoRegistryEntity, any>) => {
        const { bunny_data } = params.row;

        console.log(JSON.stringify(bunny_data, null, 2));
        return (
          <strong>
            {bunny_data ? `Library: ${bunny_data?.libraryId || "-"}, Video: ${bunny_data?.videoId || "-"}` : "-"}
          </strong>
        );
      },
    },

    {
      field: "actions",
      headerName: formatMessage({ id: "form.labels.actions" }),
      align: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, IVideoRegistryEntity, any>) => {
        const entity = params.row;
        return (
          <Fragment>
            <IconButton onClick={handleEdit(entity)}>
              <Create />
            </IconButton>
            <IconButton onClick={handleDelete(entity)}>
              <Delete />
            </IconButton>
          </Fragment>
        );
      },
    },
  ];

  useDeepCompareEffect(() => {
    void fetchEntities(id);
  }, [data]);

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "videoRegistry"]} />

      <PageHeader message="pages.videoRegistry.title">
        <Button startIcon={<Add fontSize="large" />} onClick={handleAdd} variant="contained">
          <FormattedMessage id="form.buttons.add" />
        </Button>
      </PageHeader>

      <CommonSearchForm onSubmit={handleSubmit} initialValues={data} />

      <DataGrid
        autoHeight
        pagination
        paginationMode="server"
        rowCount={count}
        pageSize={data.take}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        rows={entities}
        columns={columns}
        disableSelectionOnClick
        loading={isLoading}
      />

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmed}
        open={isDeleteDialogOpen}
        initialValues={selectedEntity}
      />

      <EditVideoRegistryEntityDialog
        onCancel={handleEditCancel}
        onConfirm={handleEditConfirmed}
        open={isEditDialogOpen}
        initialValues={selectedEntity}
      />
    </Grid>
  );
};
