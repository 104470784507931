import { FC } from "react";
import { useStyles } from "./styles";
import { useIntl } from "react-intl";
import { useFormikContext, getIn } from "formik";
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";

export type SelectOption = ISelectEnumOption | ISelectOption[];

export interface ISelectOption {
  label: string;
  value: string;
}

export interface ISelectEnumOption {
  [key: string]: string;
}

export interface IDynamicSelectInput extends SelectProps {
  name: string;
  isLocalized?: boolean;
  options: SelectOption;
}

export const DynamicSelectInput: FC<IDynamicSelectInput> = props => {
  const { options, label = "", name = "", multiple, variant = "standard", isLocalized = true, ...rest } = props;
  const { formatMessage } = useIntl();
  const formik = useFormikContext<any>();
  const classes = useStyles();
  const suffix = name.split(".").pop() || "";
  const labelText = isLocalized ? formatMessage({ id: `form.labels.${suffix}` }) : label;
  const value = getIn(formik.values, name);
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);
  const localizedHelperText = error && touched ? formatMessage({ id: error }, { label: labelText }) : "";
  const hasError = error && touched;
  const renderOptions = () => {
    if (!isLocalized && Array.isArray(options)) {
      return options.map((option: ISelectOption) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ));
    }

    if (isLocalized && Array.isArray(options)) {
      return options.map((option: ISelectOption) => (
        <MenuItem key={option.value} value={option.value}>
          {formatMessage({ id: `enums.${suffix}.${option.label}` })}
        </MenuItem>
      ));
    }

    if (isLocalized && typeof options === "object") {
      return Object.values(options as ISelectEnumOption).map(key => {
        return (
          <MenuItem key={key} value={key}>
            {formatMessage({ id: `enums.${suffix}.${key}` })}
          </MenuItem>
        );
      });
    }

    return null;
  };

  return (
    <FormControl fullWidth className={classes.root}>
      <InputLabel id={`${name}-select-label`} variant={variant} className={hasError ? classes.errorLabel : ""}>
        {labelText}
      </InputLabel>
      <Select
        multiple={multiple}
        labelId={`${name}-select-label`}
        label={labelText}
        id={`${name}-select`}
        name={name}
        onChange={formik.handleChange}
        value={value}
        variant={variant}
        error={hasError}
        {...rest}
      >
        {renderOptions()}
      </Select>
      {hasError && <div className={classes.errorHint}> {localizedHelperText} </div>}
    </FormControl>
  );
};
