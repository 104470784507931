import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface HorizontalLayoutProps {
  labelId: string;
  children: React.ReactNode;
  marginTop?: number; // Optional prop for top margin
  marginBottom?: number; // Optional prop for bottom margin
}

const HorizontalLayout: React.FC<HorizontalLayoutProps> = ({ labelId, children, marginTop = 2, marginBottom = 2 }) => {
  return (
    <Box sx={{ mt: marginTop, mb: marginBottom }}>
      <Grid container spacing={2}>
        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          <FormattedMessage id={labelId} />
        </Grid>
        <Grid item xs={9} style={{ display: 'flex' }} spacing={1}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HorizontalLayout;
