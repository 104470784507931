import { FC, Fragment } from "react";
import { TextInput, SelectInput } from "@encoderinc/mui-inputs-core";
import { Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

import { IFlashCardsOption, IFlashCardsStepData, FlashCardsStepValidationMode } from "@memoryos/types";
import { useStepValuesBeforeEditSaved } from "../../../../../../utils/hooks/useStepValuesBeforeEditSaved";

interface IFlashCardsStepProps {
  name: string;
  valuesBeforeEdit?: any;
}

export const FlashCardsStep: FC<IFlashCardsStepProps> = props => {
  const { name, valuesBeforeEdit } = props;

  const { formatMessage } = useIntl();
  const formik = useFormikContext<any>();

  useStepValuesBeforeEditSaved(formik, valuesBeforeEdit, name);

  const handleOptionAdd =
    (field: string): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      const flashCardsOptions: IFlashCardsOption[] = newValue[field as keyof IFlashCardsStepData];
      const id = flashCardsOptions.length + 1;
      flashCardsOptions.push({
        id,
        flashCardQuestion: "",
        flashCardAnswer: "",
      });
      formik.setFieldValue(name, newValue);
    };

  const handleOptionDelete =
    (field: string, i: number): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].splice(i, 1);
      formik.setFieldValue(name, newValue);
    };

  return (
    <Fragment>
      <SelectInput name={`${name}.flashCardsValidationMode`} options={FlashCardsStepValidationMode} />
      <Typography>
        <FormattedMessage id="form.labels.flashCardsOptions" />
        <Tooltip title={formatMessage({ id: "form.tips.add" })}>
          <IconButton size="large" aria-label="add" onClick={handleOptionAdd("flashCardsOptions")}>
            <Add fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>

      {formik.values[name].flashCardsOptions.map((_o: IFlashCardsOption, i: number) => (
        <Grid container key={i}>
          <Grid item>
            <TextInput
              name={`${name}.flashCardsOptions[${i}].flashCardQuestion`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="delete" onClick={handleOptionDelete("flashCardsOptions", i)}>
                      <Delete />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextInput multiline name={`${name}.flashCardsOptions[${i}].flashCardAnswer`} />
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};
