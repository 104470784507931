import { FC, Fragment, useState } from "react";
import { Badge, Card, CardContent, CardMedia, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { TextInput } from "@encoderinc/mui-inputs-core";
import { ProgressOverlay } from "@encoderinc/mui-progress";
import { FirebaseFileInput } from "@encoderinc/mui-inputs-file-firebase";
import { openUrlOnClick } from "@encoderinc/popup";

import { useStyles } from "./styles";

interface IDragToMatchImageStepProps {
  name: string;
  bucket: string;
  update?: (values: any, formikBag: any) => Promise<void>;
  folder?: string;
}

export const ImageInput: FC<IDragToMatchImageStepProps> = props => {
  const { name, bucket, update, folder = "" } = props;

  const classes = useStyles();
  const formik = useFormikContext<any>();
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (urls: Array<string>): void => {
    setIsLoading(true);
    const imgUrlWithoutParams = urls[0].split("?")[0];
    formik.setFieldValue(name, imgUrlWithoutParams);
    if (update) {
      void update({ [name]: imgUrlWithoutParams, id: formik.values.id }, formik);
    }

    setIsLoading(false);
  };

  const handleDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    setIsLoading(true);

    const newValues = formik.values[name];
    const options = newValues.dragToMatchOptions;
    const [removed] = options.splice(result.source.index, 1);
    options.splice(result.destination.index, 0, removed);

    formik.setFieldValue(name, newValues);
    setIsLoading(false);
  };
  return (
    <Fragment>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {provided => (
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Grid item>
                <ProgressOverlay isLoading={isLoading}>
                  <FirebaseFileInput
                    onDropAccepted={() => {
                      setIsLoading(true);
                    }}
                    onChange={handleFileChange}
                    classes={{ root: classes.media }}
                    bucket={bucket}
                    folder={folder}
                  />
                </ProgressOverlay>
              </Grid>
              {formik.values[name] && (
                <Grid item ref={provided.innerRef}>
                  <Card>
                    <Badge color="primary" classes={{ badge: classes.badge }}>
                      <CardMedia
                        image={(formik.values[name] as string) + "?alt=media"}
                        onClick={openUrlOnClick((formik.values[name] as string) + "?alt=media")}
                        className={classes.media}
                      />
                    </Badge>
                    <CardContent>
                      <TextInput name={`${name}`} />
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );
};
