import { FC, Fragment } from "react";

import { TextInput } from "@encoderinc/mui-inputs-core";

interface IFeedbackInputStepProps {
  name: string;
}

export const FeedbackInputStep: FC<IFeedbackInputStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <TextInput name={`${name}.feedbackInputQuestion`} />
    </Fragment>
  );
};
