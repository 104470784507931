import { FC } from "react";
import { Grid } from "@mui/material";

import { AutoSave, FormikForm } from "@encoderinc/mui-form";
import { DateRangeInput } from "@encoderinc/mui-inputs-picker";
import { EntityInput } from "@encoderinc/mui-inputs-entity";
import { IUser } from "@memoryos/types";

import { IAnswerSearchDto } from "../index";

interface IAnswerSearchFormProps {
  onSubmit: (values: IAnswerSearchDto) => void;
  initialValues: IAnswerSearchDto;
}

export const AnswerSearchForm: FC<IAnswerSearchFormProps> = props => {
  const { onSubmit, initialValues } = props;

  const { dateRange, stepIds, userIds } = initialValues;
  const fixedValues = { dateRange, stepIds, userIds };

  return (
    <FormikForm initialValues={fixedValues} onSubmit={onSubmit} showButtons={false} showPrompt={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DateRangeInput name="dateRange" />
        </Grid>
        <Grid item xs={6}>
          <EntityInput name="stepIds" controller="curriculum/steps" multiple />
        </Grid>
        <Grid item xs={6}>
          <EntityInput name="userIds" controller="users" multiple getTitle={(option: IUser) => option.displayName} />
        </Grid>
      </Grid>
      <AutoSave />
    </FormikForm>
  );
};
