import { ChangeEvent, FC } from "react";
import { useFormikContext } from "formik";
import { EntityInput, IAutocompleteOption } from "@encoderinc/mui-inputs-entity";

interface IModuleInputProps {
  name: string;
}

export const ModuleInput: FC<IModuleInputProps> = props => {
  const { name } = props;
  const formik = useFormikContext<any>();

  return (
    <EntityInput
      name={name}
      controller="curriculum/modules"
      multiple
      onChange={(
        _event: ChangeEvent<unknown>,
        options: Array<IAutocompleteOption> | IAutocompleteOption | null,
      ): void => {
        const value = options
          ? (options as Array<IAutocompleteOption>).map((option: IAutocompleteOption) => option.id)
          : [];
        formik.setFieldValue(name, value);
        formik.setFieldValue("lessonIds", []);
      }}
    />
  );
};
