import { FC } from "react";
import { Collapse, Grid } from "@mui/material";

import { LessonStatus } from "@memoryos/types";
import { AutoSave, FormikForm } from "@encoderinc/mui-form";
import { SearchInput, SelectInput } from "@encoderinc/mui-inputs-core";

import { ILessonSearchDto } from "../index";

interface IStepSearchFormProps {
  onSubmit: (values: ILessonSearchDto) => void;
  initialValues: ILessonSearchDto;
  open: boolean;
}

export const LessonSearchForm: FC<IStepSearchFormProps> = props => {
  const { onSubmit, initialValues, open } = props;

  const { query, lessonStatus } = initialValues;
  const fixedValues = { query, lessonStatus };

  return (
    <FormikForm initialValues={fixedValues} onSubmit={onSubmit} showButtons={false} showPrompt={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SearchInput name="query" />
        </Grid>
      </Grid>
      <Collapse in={open}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectInput name="lessonStatus" options={LessonStatus} multiple />
          </Grid>
        </Grid>
      </Collapse>
      <AutoSave />
    </FormikForm>
  );
};
