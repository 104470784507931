import {
  DateStepMode,
  ICategoriseStepData,
  IChatStepData,
  ICollectionImageStepData,
  ICompareTestResultsStepData,
  IDateStepData,
  IDragToMatchImageStepData,
  IDragToMatchTextStepData,
  IExpandableListStepData,
  IFeedbackInputStepData,
  IGalleryStepData,
  IGeographyStepData,
  IMessageStepData,
  IMissingWordsStepData,
  IMultipleChoiceFeedbackStepData,
  IMultipleChoiceImageStepData,
  IMultipleChoiceTextStepData,
  INumberStepData,
  IRandomWordsImageStepData,
  IRandomWordsInputStepData,
  IRandomWordsTextStepData,
  IReorderInputStepData,
  IReorderTextStepData,
  IRevealStepData,
  ISelectInOrderStepData,
  ISentenceConstructionStepData,
  ISliderFeedbackStepData,
  ISliderStepData,
  IStepData,
  ITimerStepData,
  IVideoStepData,
  IWordsListStepData,
  ReorderInputStepOrderMode,
  StepType,
  IFlashCardsStepData,
  IBinaryDigitsStepData,
  INumberAdvancedStepData,
  ISimpleInputStepData,
  IAddressStepData,
  AddressStepFormat,
  IImageMapStepData,
  ImageMapImageGridSize,
  FlashCardsStepValidationMode,
  IPlayingCardsStepData,
} from "@memoryos/types";
import { ICollectionVideoStepData } from "@memoryos/types/dist/entities/curriculum/step-data/collection-video";

export const getDefaultData = (stepType: unknown): IStepData | null => {
  switch (stepType) {
    case StepType.CATEGORISE:
      return {
        categorisePositive: "",
        categoriseNegative: "",
        categoriseStatement: "",
        categorisePrompt: "",
      } as ICategoriseStepData;
    case StepType.CHAT:
      return {
        chatPrompt: "",
        chatQuestionTitle: "",
        chatQuestionOptions: [],
        chatAnswerTitle: "",
        chatAnswerOptions: [],
        chatAnswerText: "",
        chatWrongAnswerText: "",
      } as IChatStepData;
    case StepType.DATE:
      return {
        datePrompt: "",
        dateQuestion: "",
        dateAnswer: new Date().toISOString(),
        dateAnswerText: "",
        dateWrongAnswerText: "",
        dateMode: DateStepMode.MONTH_DATE_YEAR,
      } as IDateStepData;
    case StepType.DRAG_TO_MATCH_IMAGE:
      return {
        dragToMatchPrompt: "",
        dragToMatchQuestion: "",
        dragToMatchOptions: [],
        dragToMatchImageAnswerText: "",
        dragToMatchImageWrongAnswerText: "",
      } as IDragToMatchImageStepData;
    case StepType.DRAG_TO_MATCH_TEXT:
      return {
        dragToMatchTextPrompt: "",
        dragToMatchTextQuestion: "",
        dragToMatchTextOptions: [],
        dragToMatchTextAnswerText: "",
        dragToMatchTextWrongAnswerText: "",
      } as IDragToMatchTextStepData;
    case StepType.EXPANDABLE_LIST:
      return {
        expandableListPrompt: "",
        expandableListQuestion: "",
        expandableListOptions: [],
      } as IExpandableListStepData;
    case StepType.FEEDBACK_INPUT:
      return {
        feedbackInputQuestion: "",
      } as IFeedbackInputStepData;
    case StepType.COLLECTION_IMAGE:
      return {
        collectionImagePrompt: "",
        collectionImageQuestion: "",
        collectionImageOptions: [],
      } as ICollectionImageStepData;
    case StepType.COLLECTION_VIDEO:
      return {
        collectionVideoPrompt: "",
        collectionVideoQuestion: "",
        collectionVideoOptions: [],
      } as ICollectionVideoStepData;
    case StepType.WORDS_LIST:
      return {
        wordsListPrompt: "",
        wordsListOptions: [],
      } as IWordsListStepData;
    case StepType.GALLERY:
      return {
        galleryPrompt: "",
        galleryOptions: [],
      } as IGalleryStepData;
    case StepType.MISSING_WORDS:
      return {
        missingWordsPrompt: "",
        missingWordsOptions: [],
        missingWordsAnswerText: "",
        missingWordsWrongAnswerText: "",
      } as IMissingWordsStepData;
    case StepType.MULTIPLE_CHOICE_FEEDBACK:
      return {
        multipleChoiceFeedbackPrompt: "",
        multipleChoiceFeedbackQuestion: "",
        multipleChoiceFeedbackOptions: [],
      } as IMultipleChoiceFeedbackStepData;
    case StepType.MULTIPLE_CHOICE_IMAGE:
      return {
        multipleChoiceImagePrompt: "",
        multipleChoiceImageQuestion: "",
        multipleChoiceImageOptions: [],
        multipleChoiceImageAnswerText: "",
        multipleChoiceImageWrongAnswerText: "",
        multipleChoiceImageIsMultiple: false,
      } as IMultipleChoiceImageStepData;
    case StepType.MULTIPLE_CHOICE_TEXT:
      return {
        multipleChoiceTextPrompt: "",
        multipleChoiceTextQuestion: "",
        multipleChoiceTextOptions: [],
        multipleChoiceTextAnswerText: "",
        multipleChoiceTextWrongAnswerText: "",
        multipleChoiceTextIsMultiple: false,
      } as IMultipleChoiceTextStepData;
    case StepType.NUMBER:
      return {
        numberPrompt: "",
        numberQuestion: "",
        numberAnswer: "",
        numberText: "",
      } as INumberStepData;
    case StepType.REORDER_INPUT:
      return {
        // reorderInputPrompt: "",
        reorderInputQuestion: "",
        reorderInputOrderMode: ReorderInputStepOrderMode.STRICT,
        reorderInputOptions: [],
        reorderInputAnswerText: "",
        reorderInputWrongAnswerText: "",
      } as IReorderInputStepData;
    case StepType.REORDER_TEXT:
      return {
        // reorderTextPrompt: "",
        reorderTextQuestion: "",
        reorderTextOptions: [],
        reorderTextAnswerText: "",
        reorderTextWrongAnswerText: "",
      } as IReorderTextStepData;
    case StepType.REVEAL:
      return {
        revealPrompt: "",
        revealQuestion: "",
        revealOptions: [],
      } as IRevealStepData;
    case StepType.SELECT_IN_ORDER:
      return {
        selectInOrderPrompt: "",
        selectInOrderQuestion: "",
        selectInOrderOptions: [],
        selectInOrderAnswerText: "",
        selectInOrderWrongAnswerText: "",
      } as ISelectInOrderStepData;
    case StepType.SENTENCE_CONSTRUCTION:
      return {
        sentenceConstructionPrompt: "",
        sentenceConstructionOptions: [],
        sentenceConstructionAnswerText: "",
        sentenceConstructionWrongAnswerText: "",
      } as ISentenceConstructionStepData;
    case StepType.SLIDER:
      return {
        sliderPrompt: "",
        sliderQuestion: "",
        sliderMin: 0,
        sliderMax: 0,
        sliderAnswer: 0,
        sliderAnswerText: "",
        sliderWrongAnswerText: "",
      } as ISliderStepData;
    case StepType.SLIDER_FEEDBACK:
      return {
        sliderFeedbackPrompt: "",
        sliderFeedbackQuestion: "",
        sliderFeedbackMin: 0,
        sliderFeedbackMax: 0,
      } as ISliderFeedbackStepData;
    case StepType.VIDEO:
      return {
        videoUrl: "",
        ccsUrl: "",
      } as IVideoStepData;
    case StepType.RANDOM_WORDS_TEXT:
      return {
        randomWordsTextOptions: [],
        randomWordsTextInterval: 0,
        randomWordsTextRandomize: false,
        randomWordsTextPrompt: "",
      } as IRandomWordsTextStepData;
    case StepType.RANDOM_WORDS_IMAGE:
      return {
        randomWordsImageOptions: [],
        randomWordsImageInterval: 0,
      } as IRandomWordsImageStepData;
    case StepType.RANDOM_WORDS_INPUT:
      return {
        randomWordsInputOptions: [],
        randomWordsInputPrompt: "",
      } as IRandomWordsInputStepData;
    case StepType.COMPARE_TEST_RESULTS:
      return {
        compareTestResultsFirstStepId: 1,
        compareTestResultsSecondStepId: 2,
        compareTestResultsAttempts: [],
      } as ICompareTestResultsStepData;
    case StepType.MESSAGE:
      return {
        messageTitle: "",
        messageText: "",
      } as IMessageStepData;
    case StepType.GEOGRAPHY:
      return {
        geographyRandomize: false,
        geographyRegionOptions: [],
        geographyItemIds: [],
        geographyItemType: "country",
        geographyWithPicturesMode: false,
        geographyLearningMode: false,
      } as IGeographyStepData;
    case StepType.TIMER:
      return {
        timerText: "",
        timerTime: 0,
      } as ITimerStepData;
    case StepType.FLASH_CARDS:
      return {
        flashCardsOptions: [],
        flashCardsValidationMode: FlashCardsStepValidationMode.SCORE,
      } as unknown as IFlashCardsStepData;
    case StepType.BINARY_DIGITS:
      return {
        binaryDigitsPrompt: "",
        binaryDigitsQuestion: "",
        binaryDigitsAnswer: "",
      } as IBinaryDigitsStepData;
    case StepType.NUMBER_ADVANCED:
      return {
        numberAdvancedPrompt: "",
        numberAdvancedQuestion: "",
        numberAdvancedAnswer: "",
      } as INumberAdvancedStepData;
    case StepType.SIMPLE_INPUT:
      return {
        simpleInputPrompt: "",
        simpleInputQuestion: "",
        simpleInputAnswer: "",
        simpleInputValidationMode: "",
        simpleInputRepresentationMode: "",
      } as unknown as ISimpleInputStepData;
    case StepType.ADDRESS:
      return {
        addressPrompt: "",
        addressQuestion: "",
        addressAnswer: "",
        addressValidationMode: "",
        addressFormat: AddressStepFormat.CITY_US_STATE_ZIP,
        addressUSState: "",
        addressCity: "",
      } as unknown as IAddressStepData;
    case StepType.IMAGE_MAP:
      return {
        imageMapImageUrl: "",
        imageMapImageGridSize: ImageMapImageGridSize["9x9"],
        imageMapAllObjectsOptions: [],
        imageMapAnswersObjectsOptions: [],
        imageMapValidationMode: "",
      } as unknown as IImageMapStepData;
    case StepType.PLAYING_CARDS:
      return {
        playingCardsSequenceType: "",
        playingCardsSequence: [],
        playingCardsSequenceCount: 1,
        playingCardsRecallPhaseMode: "",
        playingCardsMemorizingPhaseMode: "",
        playingCardsMemorizingPhaseModeInterval: 1,
      } as unknown as IPlayingCardsStepData;
    default:
      return null;
  }
};
