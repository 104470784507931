import { FC, useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

import { ConfirmationDialog } from "@encoderinc/mui-dialog-confirmation";
import { ProgressOverlay } from "@encoderinc/mui-progress";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IStat, IUser } from "@memoryos/types";

export interface IStatisticsUserDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  initialValues: IUser;
}

const emptyStatistics = {
  lessonCount: 0,
  totalTime: 0,
  totalScore: 0,
} as IStat;

export const StatisticsUserDialog: FC<IStatisticsUserDialogProps> = props => {
  const { initialValues, ...rest } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const [statistics, setStatistics] = useState(emptyStatistics);
  const [isLoading, setIsLoading] = useState(false);

  const api = useContext(ApiContext);

  const fetchStatistics = (): Promise<void> => {
    return api
      .fetchJson({
        url: `/curriculum/statistics/${initialValues.id}`,
      })
      .then(json => {
        setStatistics(json);
      })
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!initialValues.id) {
      return;
    }
    void fetchStatistics();
  }, [initialValues]);

  return (
    <ConfirmationDialog maxWidth="xs" message="pages.users.statistics" {...rest}>
      <ProgressOverlay isLoading={isLoading}>
        <pre>{JSON.stringify(statistics, null, "\t")}</pre>
      </ProgressOverlay>
    </ConfirmationDialog>
  );
};
