import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  theme => ({
    root: {
      padding: theme.spacing(4),
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(2),
    },
    itemGrid: {
      width: "100%",
    },
    itemBox: {
      width: "100%",
    },
    itemCard: {
      padding: "8px",
      backgroundColor: "#eeeeee",
      borderRadius: "16px",
    }
  }),
  { name: "Step" },
);
