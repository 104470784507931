import { FC } from "react";
import { Grid } from "@mui/material";

import { AutoSave, FormikForm } from "@encoderinc/mui-form";
import { ISearchDto } from "@encoderinc/types-collection";

import { SearchInput } from "@encoderinc/mui-inputs-core";

import { useStyles } from "./styles";

interface IAdminSearchFormProps {
  onSubmit: (values: ISearchDto) => void;
  initialValues: ISearchDto;
}

export const AdminSearchForm: FC<IAdminSearchFormProps> = props => {
  const { onSubmit, initialValues } = props;

  const classes = useStyles();

  const { query } = initialValues;
  const fixedValues = { query };

  return (
    <div className={classes.root}>
      <FormikForm initialValues={fixedValues} onSubmit={onSubmit} showButtons={false} showPrompt={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SearchInput name="query" />
          </Grid>
        </Grid>
        <AutoSave />
      </FormikForm>
    </div>
  );
};
