import { FC, Fragment } from "react";
import { Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

import { TextInput } from "@encoderinc/mui-inputs-core";
import { IRevealOption } from "@memoryos/types";

interface IRevealStepProps {
  name: string;
}

export const RevealStep: FC<IRevealStepProps> = props => {
  const { name } = props;

  const { formatMessage } = useIntl();
  const formik = useFormikContext<any>();

  const handleOptionAdd =
    (field: string): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].push({
        revealOptionTitle: "",
        revealOptionText: "",
      });
      formik.setFieldValue(name, newValue);
    };

  const handleOptionDelete =
    (field: string, i: number): (() => void) =>
    (): void => {
      const newValue = formik.values[name];
      newValue[field].splice(i, 1);
      formik.setFieldValue(name, newValue);
    };

  return (
    <Fragment>
      <TextInput name={`${name}.revealPrompt`} />
      <TextInput name={`${name}.revealQuestion`} />

      <Typography>
        <FormattedMessage id="form.labels.revealOptions" />
        <Tooltip title={formatMessage({ id: "form.tips.add" })}>
          <IconButton size="large" aria-label="add" onClick={handleOptionAdd("revealOptions")}>
            <Add fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>

      {formik.values[name].revealOptions.map((_o: IRevealOption, i: number) => (
        <Grid container key={i}>
          <Grid item>
            <TextInput
              name={`${name}.revealOptions[${i}].revealOptionTitle`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="delete" onClick={handleOptionDelete("revealOptions", i)}>
                      <Delete />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextInput multiline name={`${name}.revealOptions[${i}].revealOptionText`} />
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};
