import { FC, Fragment, useState, useEffect } from "react";
import { DynamicSelectInput } from "../../../../../../components/common/inputs/select";
import {
  PlayingCardsMemorizingPhaseMode,
  PlayingCardsRecallPhaseMode,
  PlayingCardsSequenceItem,
  PlayingCardsSequenceType,
} from "@memoryos/types";
import { SelectChangeEvent } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { AdvancedNumberInput } from "../../../../../../components/common/inputs/advanced-number";
import { CheckboxInput } from "@encoderinc/mui-inputs-core";
import { usePrevious } from "../../../../../../utils/hooks/usePrevious";

interface IPlayingCardsStepProps {
  name: string;
}

export const PlayingCardsStep: FC<IPlayingCardsStepProps> = props => {
  const { name } = props;

  const formik = useFormikContext<any>();
  const initialMemorizingPhaseMode =
    getIn(formik.values, `${name}.playingCardsMemorizingPhaseMode`) || PlayingCardsMemorizingPhaseMode.MANUAL;
  const [memorizingPhaseMode, setMemorizingPhaseMode] =
    useState<PlayingCardsMemorizingPhaseMode>(initialMemorizingPhaseMode);

  useEffect(() => {
    if (memorizingPhaseMode) {
      formik.setFieldValue(`${name}.playingCardsMemorizingPhaseMode`, memorizingPhaseMode);
    }
  }, [memorizingPhaseMode]);

  const handleMemorizingPhaseModeInputChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    setMemorizingPhaseMode(event.target.value as PlayingCardsMemorizingPhaseMode);
    formik.setFieldValue(`${name}.playingCardsMemorizingPhaseModeInterval`, 0);
  };

  const [isCompetitiveMode, setIsCompetitiveMode] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    // To have backwards compatibility with Playing Cards steps created before Competitive mode implementation
    const competitiveMode = getIn(formik.values, `${name}.playingCardsCompetitiveMode`);
    if (typeof competitiveMode === "undefined") {
      formik.setFieldValue(`${name}.playingCardsCompetitiveMode`, false);
      setIsCompetitiveMode(false);
    } else {
      setIsCompetitiveMode(competitiveMode);
    }

    const decksCount = getIn(formik.values, `${name}.playingCardsDecksCount`);
    if (typeof decksCount === "undefined") {
      formik.setFieldValue(`${name}.playingCardsDecksCount`, null);
    }

    const countupTimer = getIn(formik.values, `${name}.playingCardsCountUpTimer`);
    if (typeof countupTimer === "undefined") {
      formik.setFieldValue(`${name}.playingCardsCountUpTimer`, false);
    }
  }, []);

  useEffect(() => {
    const updatedIsCompetitiveMode = getIn(formik.values, `${name}.playingCardsCompetitiveMode`);
    if (typeof isCompetitiveMode !== "undefined" && updatedIsCompetitiveMode !== isCompetitiveMode) {
      if (updatedIsCompetitiveMode) {
        formik.setFieldValue(`${name}.playingCardsSequenceCount`, null);
        formik.setFieldValue(`${name}.playingCardsDecksCount`, 1);
      } else {
        formik.setFieldValue(`${name}.playingCardsSequenceCount`, 1);
        formik.setFieldValue(`${name}.playingCardsDecksCount`, null);
      }
      setIsCompetitiveMode(updatedIsCompetitiveMode);
    }
  }, [getIn(formik.values, `${name}.playingCardsCompetitiveMode`)]);

  const [playingCardsSequenceType, setPlayingCardsSequenceType] = useState<PlayingCardsSequenceType | null>(
    getIn(formik.values, `${name}.playingCardsSequenceType`) || null,
  );
  const previousPlayingCardsSequenceType = usePrevious(playingCardsSequenceType);
  const [playingCardsSequence, setPlayingCardsSequence] = useState<PlayingCardsSequenceItem[]>(
    getIn(formik.values, `${name}.playingCardsSequence`) || [],
  );

  const handlePlayingCardsSequenceTypeChange = (event: SelectChangeEvent<unknown>) => {
    const updatedPlayingCardsSequenceType = event.target.value as PlayingCardsSequenceType;
    if (updatedPlayingCardsSequenceType !== previousPlayingCardsSequenceType) {
      switch (updatedPlayingCardsSequenceType) {
        case PlayingCardsSequenceType.RANDOM:
          formik.setFieldValue(`${name}.playingCardsSequence`, []);
          formik.setFieldValue(`${name}.playingCardsSequenceCount`, 1);
          formik.setFieldValue(`${name}.playingCardsDecksCount`, null);
          formik.setFieldValue(`${name}.playingCardsCompetitiveMode`, false);
          formik.setFieldValue(`${name}.playingCardsSequenceType`, updatedPlayingCardsSequenceType);
          setPlayingCardsSequence([]);
          setPlayingCardsSequenceType(updatedPlayingCardsSequenceType);
          break;
        case PlayingCardsSequenceType.EXACT:
          formik.setFieldValue(`${name}.playingCardsSequence`, []);
          formik.setFieldValue(`${name}.playingCardsSequenceCount`, null);
          formik.setFieldValue(`${name}.playingCardsDecksCount`, null);
          formik.setFieldValue(`${name}.playingCardsCompetitiveMode`, false);
          formik.setFieldValue(`${name}.playingCardsSequenceType`, updatedPlayingCardsSequenceType);
          setPlayingCardsSequence([]);
          setPlayingCardsSequenceType(updatedPlayingCardsSequenceType);
          break;
        default:
          break;
      }
    }
  };

  const handlePlayingCardsSequenceChange = (event: SelectChangeEvent<unknown>) => {
    formik.setFieldValue(`${name}.playingCardsSequence`, event.target.value);
    // @ts-ignore
    formik.setFieldValue(`${name}.playingCardsSequenceCount`, event.target.value?.length || 0);
    setPlayingCardsSequence(event.target.value as PlayingCardsSequenceItem[]);
  };

  return (
    <Fragment>
      <DynamicSelectInput
        name={`${name}.playingCardsSequenceType`}
        options={PlayingCardsSequenceType}
        onChange={(e: SelectChangeEvent<unknown>) => handlePlayingCardsSequenceTypeChange(e)}
      />

      {playingCardsSequenceType && playingCardsSequenceType === PlayingCardsSequenceType.RANDOM && (
        <Fragment>
          <CheckboxInput name={`${name}.playingCardsCompetitiveMode`} />
          {isCompetitiveMode ? (
            <AdvancedNumberInput name={`${name}.playingCardsDecksCount`} min={1} max={5} setMinValueWhenEmpty={true} />
          ) : (
            <AdvancedNumberInput
              name={`${name}.playingCardsSequenceCount`}
              min={1}
              max={52}
              setMinValueWhenEmpty={true}
            />
          )}
        </Fragment>
      )}

      {playingCardsSequenceType && playingCardsSequenceType === PlayingCardsSequenceType.EXACT && (
        <DynamicSelectInput
          name={`${name}.playingCardsSequence`}
          options={PlayingCardsSequenceItem}
          onChange={(e: SelectChangeEvent<unknown>) => handlePlayingCardsSequenceChange(e)}
          multiple
          renderValue={selected => (selected as PlayingCardsSequenceItem[]).join(", ")}
        />
      )}

      <DynamicSelectInput name={`${name}.playingCardsRecallPhaseMode`} options={PlayingCardsRecallPhaseMode} />

      <DynamicSelectInput
        name={`${name}.playingCardsMemorizingPhaseMode`}
        options={PlayingCardsMemorizingPhaseMode}
        value={memorizingPhaseMode}
        onChange={e => handleMemorizingPhaseModeInputChange(e as SelectChangeEvent<HTMLInputElement>)}
      />

      {memorizingPhaseMode === PlayingCardsMemorizingPhaseMode.TIMER && (
        <AdvancedNumberInput
          name={`${name}.playingCardsMemorizingPhaseModeInterval`}
          min={1}
          setMinValueWhenEmpty={true}
        />
      )}

      <CheckboxInput name={`${name}.playingCardsCountUpTimer`} />
    </Fragment>
  );
};
