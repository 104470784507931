import { FC, Fragment } from "react";

import { TextArea, TextInput } from "@encoderinc/mui-inputs-core";
import { NumericInput } from "../../../../../../components/common/inputs/numeric";

import { NumericInputMode } from "../../../../../../components/common/inputs/numeric/types";

interface INumberAdvancedStepProps {
  name: string;
}

export const NumberAdvancedStep: FC<INumberAdvancedStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <TextInput name={`${name}.numberAdvancedQuestion`} />
      <TextInput name={`${name}.numberAdvancedPrompt`} />
      <NumericInput name={`${name}.numberAdvancedAnswer`} mode={NumericInputMode.INTEGER} />
      <TextArea name={`${name}.numberAdvancedAnswerText`} />
      <TextArea name={`${name}.numberAdvancedWrongAnswerText`} />
    </Fragment>
  );
};
