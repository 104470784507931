import { FC } from "react";
import { AppBar, Hidden, Link, Toolbar } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { companyName } from "@memoryos/constants";

import { Sections } from "./sections";
import { Translation } from "./translation";
import { Theme } from "./theme";

import { useStyles } from "./styles";

export const Header: FC = () => {
  const classes = useStyles();
  return (
    <AppBar position="fixed">
      <Toolbar className={classes.toolbar}>
        <Link className={classes.title} component={RouterLink} to="/">
          <Hidden smDown>{companyName}</Hidden>
        </Link>
        <div className={classes.grow} />
        <Theme />
        <Translation />
        <Sections />
      </Toolbar>
    </AppBar>
  );
};
