import { FC, useContext } from "react";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { Redeem, Videocam } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { IUserContext, UserContext } from "@encoderinc/provider-user";
import { IUser, UserRole } from "@memoryos/types";

export const Other: FC = () => {
  const user = useContext<IUserContext<IUser>>(UserContext);

  if (!user.profile.userRoles.includes(UserRole.ADMIN)) {
    return null;
  }

  return (
    <List
      component="nav"
      subheader={
        <ListSubheader>
          <FormattedMessage id="pages.dashboard.other.title" />
        </ListSubheader>
      }
    >
      <ListItem button component={RouterLink} to="/promo-codes">
        <ListItemIcon>
          <Redeem />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="pages.dashboard.other.promoCodes" />
        </ListItemText>
      </ListItem>
      <ListItem button component={RouterLink} to="/video/registry">
        <ListItemIcon>
          <Videocam />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="pages.dashboard.other.videoRegistry" />
        </ListItemText>
      </ListItem>
    </List>
  );
};
