import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  theme => ({
    imageContainer: {
      marginTop: "10px",
    },
    card: {
      marginTop: "15px",
      position: "relative",
    },
    media: {
      objectFit: "contain",
      width: "100%",
      height: "auto",
    },
    fileInput: {
      width: "250px",
      height: "auto",
      marginTop: "15px",
    },
    errorHint: {
      color: "#d32f2f",
      fontSize: "0.75rem",
      lineHeight: "1.66",
      letterSpacing: "0.03333em",
      marginTop: "3px",
    },
  }),
  { name: "SimpleInput" },
);
