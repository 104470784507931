import { FC } from "react";
import { useIntl } from "react-intl";
import { useFormikContext, getIn } from "formik";

import { Radio, RadioProps, FormControlLabel } from "@mui/material";

export interface IRequireName {
  name: string;
}

export interface IRadioInputProps extends IRequireName {
  label?: string;
}

export const RadioInput: FC<IRadioInputProps & RadioProps> = props => {
  const { name, label, ...rest } = props;

  const suffix = name.split(".").pop() as string;

  const formik = useFormikContext<any>();
  const value = getIn(formik.values, name);

  const { formatMessage } = useIntl();
  const localizedLabel = label ?? formatMessage({ id: `form.labels.${suffix}` });

  return (
    <FormControlLabel
      control={
        <Radio name={name} checked={value} onChange={formik.handleChange} onBlur={formik.handleBlur} {...rest} />
      }
      label={localizedLabel}
    />
  );
};
