import { FC, Fragment, MouseEvent, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { matchPath, useNavigate, useLocation } from "react-router";
import { Avatar, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { Link as RouterLink, NavLink as RouterNavLink } from "react-router-dom";

import { IUserContext, UserContext } from "@encoderinc/provider-user";
import { ApiContext } from "@encoderinc/provider-api";
import { IUser } from "@memoryos/types";

import { useStyles } from "./styles";

export const Sections: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const classes = useStyles();
  const [anchor, setAnchor] = useState<Element | null>(null);

  const user = useContext<IUserContext<IUser>>(UserContext);
  const api = useContext(ApiContext);

  const handleMenuOpen = (event: MouseEvent): void => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchor(null);
  };

  const logout = (e: MouseEvent): void => {
    e.preventDefault();
    handleMenuClose();
    user.logOut();
    api.setToken(null);
    navigate("/login");
  };

  if (!user.isAuthenticated()) {
    return null;
  }

  return (
    <Fragment>
      <IconButton
        aria-owns={anchor ? "material-appbar" : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit"
      >
        <Avatar alt={user.profile.displayName} src={void 0}>
          {user.profile.displayName && user.profile.displayName.substr(0, 1)}
        </Avatar>
      </IconButton>
      <Menu id="material-appbar" anchorEl={anchor} open={!!anchor} onClose={handleMenuClose}>
        <MenuItem
          component={RouterNavLink}
          to="/dashboard"
          color="inherit"
          selected={!!matchPath(location.pathname, "/dashboard")}
        >
          <FormattedMessage id="components.header.menu.dashboard" />
        </MenuItem>
        <MenuItem
          color="inherit"
          component={RouterNavLink}
          to="/profile"
          selected={!!matchPath(location.pathname, "/profile")}
        >
          <FormattedMessage id="components.header.menu.profile" />
        </MenuItem>
        <MenuItem to="/logout" onClick={logout} component={RouterNavLink}>
          <FormattedMessage id="components.header.menu.logout" />
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
