import { Fragment, useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Grid, IconButton } from "@mui/material";
import { Create, FilterList } from "@mui/icons-material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { useLocation, useNavigate, useParams } from "react-router";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";

import { PageHeader } from "@encoderinc/mui-page-header";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IPaginationResult } from "@encoderinc/types-collection";
import { Frame, IPromoCode, ISolidgateCustomer, ISolidgateCustomerSearchDto, RatePlanType } from "@memoryos/types";
import { emptySolidgateCustomer } from "@memoryos/mocks";
import { usePrevious } from "../../../utils/hooks/usePrevious";

import { Breadcrumbs } from "../../../components/common/breadcrumbs";
import { EditSolidgateCustomersDialog } from "./edit";
import { SolidgateCustomersSearchForm } from "./form";
import { defaultItemsPerPage } from "@memoryos/constants";
import { parseDateRange, stringifyDateRange } from "../../../utils/date";

export const SolidgateCustomers: () => JSX.Element = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [customers, setCustomers] = useState<Array<ISolidgateCustomer>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedCustomer, setSelectedCustomer] = useState<ISolidgateCustomer>(emptySolidgateCustomer);
  const [isFiltersOpen, setIsFilterOpen] = useState(true);
  const prevIsFiltersOpen = usePrevious(isFiltersOpen);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1));

  const [data, setData] = useState<ISolidgateCustomerSearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    query: "",
    dateRangeCreated: parseDateRange(parsedData.dateRangeCreated as string),
    dateRangeUpdated: parseDateRange(parsedData.dateRangeUpdated as string),
  });

  const updateQS = (id?: number) => {
    const { skip: _skip, take: _take, dateRangeCreated, dateRangeUpdated, ...rest } = data;
    navigate(
      id
        ? `/solidgate/customers/${id}`
        : `/solidgate/customers?${stringify({
            ...rest,
            dateRangeCreated: stringifyDateRange(dateRangeCreated),
            dateRangeUpdated: stringifyDateRange(dateRangeUpdated),
          })}`,
    );
  };

  const handleEdit = (customer: ISolidgateCustomer): (() => void) => {
    return (): void => {
      setSelectedCustomer(customer);
      setIsEditDialogOpen(true);
      updateQS(customer.id);
    };
  };

  const fetchSolidgateCustomers = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchSolidgateCustomersById(id) : fetchSolidgateCustomersByQuery())
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchSolidgateCustomersByQuery = async (): Promise<void> => {
    const { dateRangeCreated, dateRangeUpdated, isPurchaser, ...rest } = data;
    const updatedData: {
      skip: number;
      take: number;
      query: string;
      isPurchaser?: boolean;
      dateRangeCreated?: string;
      dateRangeUpdated?: string;
    } = {
      ...rest,
    };
    if (isFiltersOpen && isPurchaser) {
      // @ts-ignore
      updatedData.isPurchaser = isPurchaser;
    }
    if (isFiltersOpen && dateRangeCreated) {
      updatedData.dateRangeCreated = stringifyDateRange(dateRangeCreated);
    }
    if (isFiltersOpen && dateRangeUpdated) {
      updatedData.dateRangeUpdated = stringifyDateRange(dateRangeUpdated);
    }
    return api
      .fetchJson({
        url: "/solidgate/customers",
        data: updatedData,
      })
      .then((json: IPaginationResult<ISolidgateCustomer>) => {
        setCustomers(json.rows);
        setCount(json.count);
      });
  };

  const fetchSolidgateCustomersById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/solidgate/customers/${id}`,
      })
      .then((json: ISolidgateCustomer) => {
        setCustomers([json]);
        setCount(1);
        handleEdit(json)();
        updateQS();
      });
  };

  const handleEditCancel = async (): Promise<void> => {
    await fetchSolidgateCustomersByQuery();
    setIsEditDialogOpen(false);
    updateQS();
  };

  const handleEditConfirmed = (values: Partial<ISolidgateCustomer>, formikBag: any): Promise<void> => {
    const { id, emailAlternative, ...data } = values;
    const updateData: { id: number | undefined; emailAlternative?: string | null } = {
      id: id,
      emailAlternative: emailAlternative || null,
    };
    return api
      .fetchJson({
        url: id ? `/solidgate/customers/${id}` : "/solidgate/customers/",
        method: id ? "PUT" : "POST",
        data: updateData,
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: id ? "snackbar.updated" : "snackbar.created" }), { variant: "success" });
        setIsEditDialogOpen(false);
        return fetchSolidgateCustomers();
      })
      .catch((e: ApiError) => {
        if (e.status === 400) {
          formikBag.setErrors(e.getLocalizedValidationErrors());
        } else if (e.status === 500) {
          enqueueSnackbar(formatMessage({ id: `snackbar.internalServerError` }), { variant: "error" });
        } else if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      });
  };

  const unlinkUser = async (id: number): Promise<void> => {
    setIsEditDialogOpen(false);

    await api
      .fetchJson({
        url: `/solidgate/customers/unlink-user/${id}`,
        method: "POST",
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: "snackbar.unlinked" }), { variant: "success" });
      })
      .catch((e: ApiError) => {
        console.error(e);
        enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
      });

    await fetchSolidgateCustomers(id.toString());
    setIsEditDialogOpen(true);
  };

  const unlinkSocial = async (id: number): Promise<void> => {
    setIsEditDialogOpen(false);

    await api
      .fetchJson({
        url: `/solidgate/customers/unlink-social/${id}`,
        method: "POST",
      })
      .then(() => {
        enqueueSnackbar(formatMessage({ id: "snackbar.unlinked" }), { variant: "success" });
      })
      .catch((e: ApiError) => {
        console.error(e);
        enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
      });

    await fetchSolidgateCustomers(id.toString());
    setIsEditDialogOpen(true);
  };

  const handleChangePage = (page: number): void => {
    setData({
      ...data,
      skip: page * data.take,
      take: data.take,
    });
  };

  const handleChangeRowsPerPage = (pageSize: number): void => {
    setData({
      ...data,
      skip: 0,
      take: pageSize,
    });
  };

  const handleSubmit = (values: ISolidgateCustomerSearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFiltersOpen);
  };

  useDeepCompareEffect(() => {
    void fetchSolidgateCustomers(id);
  }, [data]);

  useEffect(() => {
    if (prevIsFiltersOpen !== isFiltersOpen) {
      void fetchSolidgateCustomers(id);
    }
  }, [isFiltersOpen]);

  function truncateString(str: string, num: number): string {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  const columns: GridColDef[] = [
    {
      field: "solidgateCustomerId",
      headerName: formatMessage({ id: "form.labels.solidgateCustomerId" }),
      align: "center",
      width: 170,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, ISolidgateCustomer, any>) => {
        const customer = params.row;
        return <strong>{truncateString(customer.solidgateCustomerId, 13)}</strong>;
      },
    },
    {
      field: "name",
      headerName: formatMessage({ id: "form.labels.name" }),
      align: "left",
      width: 110,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, ISolidgateCustomer, any>) => {
        const customer = params.row;
        return <strong>{customer.name ? truncateString(customer.name, 15) : "-"}</strong>;
      },
    },
    {
      field: "email",
      headerName: formatMessage({ id: "form.labels.email" }),
      align: "left",
      width: 220,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, ISolidgateCustomer, any>) => {
        const customer = params.row;
        return <strong>{truncateString(customer.email, 25)}</strong>;
      },
    },
    {
      field: "emailAlternative",
      headerName: formatMessage({ id: "form.labels.emailAlternative" }),
      align: "left",
      width: 220,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, ISolidgateCustomer, any>) => {
        const customer = params.row;
        return <strong>{customer.emailAlternative ? truncateString(customer.emailAlternative, 25) : "-"}</strong>;
      },
    },
    {
      field: "cid",
      headerName: formatMessage({ id: "form.labels.cid" }),
      align: "center",
      width: 90,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, ISolidgateCustomer, any>) => {
        const customer = params.row;
        return <strong>{customer.cid ? truncateString(customer.cid.toString(), 15) : "-"}</strong>;
      },
    },
    {
      field: "mosUserId",
      headerName: formatMessage({ id: "form.labels.mosuid" }),
      align: "center",
      width: 90,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, ISolidgateCustomer, any>) => {
        const customer = params.row;
        return <strong>{customer.mosUserId ? truncateString(customer.mosUserId.toString(), 15) : "-"}</strong>;
      },
    },
    {
      field: "country",
      headerName: formatMessage({ id: "form.labels.country" }),
      align: "center",
      width: 80,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, ISolidgateCustomer, any>) => {
        const customer = params.row;
        return <strong>{customer.country ? customer.country : "-"}</strong>;
      },
    },
    {
      field: "actions",
      headerName: formatMessage({ id: "form.labels.actions" }),
      align: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, ISolidgateCustomer, any>) => {
        const customer = params.row;
        return (
          <Fragment>
            <IconButton onClick={handleEdit(customer)}>
              <Create />
            </IconButton>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "solidgateCustomers"]} />

      <PageHeader message={`pages.solidgate.customers.title`}>
        <Button startIcon={<FilterList />} onClick={toggleFilters}>
          <FormattedMessage id={`form.buttons.${isFiltersOpen ? "hideFilters" : "showFilters"}`} />
        </Button>
      </PageHeader>

      <SolidgateCustomersSearchForm onSubmit={handleSubmit} initialValues={data} open={isFiltersOpen} />

      <DataGrid
        autoHeight
        pagination
        paginationMode="server"
        rowCount={count}
        pageSize={data.take}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        rows={customers}
        columns={columns}
        disableSelectionOnClick
        loading={isLoading}
      />

      <EditSolidgateCustomersDialog
        onCancel={handleEditCancel}
        onConfirm={handleEditConfirmed}
        open={isEditDialogOpen}
        initialValues={selectedCustomer}
        onUnlinkSocial={unlinkSocial}
        onUnlinkUser={unlinkUser}
      />
    </Grid>
  );
};
