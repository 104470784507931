import { FC, Fragment } from "react";
import { Divider, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { format, parseISO } from "date-fns";
import ReactJson from "react-json-view";

import { FormDialog } from "@encoderinc/mui-dialog-form";
import { IOrder } from "@memoryos/types";
import { humanReadableDateTimeFormat } from "@memoryos/constants";
import { Link } from "react-router-dom";

export interface IShowOrdersDialogProps {
  open: boolean;
  onCancel: () => void;
  values: IOrder;
}

export const ShowOrdersDialog: FC<IShowOrdersDialogProps> = props => {
  const { values, onCancel, ...rest } = props;

  const {
    id,
    updatedAt,
    createdAt,
    orderId,
    orderState,
    wasEverRefunded,
    origin,
    product,
    customerAccountId,
    ip,
    userAgent,
    fbp,
    fbc,
    source,
    placement,
    campaign,
    content,
    recurringMetadata
  } = values;

  return (
    <FormDialog initialValues={{}} message={"Order " + id} onConfirm={onCancel} onCancel={onCancel} {...rest}>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.orderId" />
          {": "}
        </b>
        {orderId}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.origin" />
          {": "}
        </b>
        {origin}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.orderState" />
          {": "}
        </b>
        {orderState}
      </Typography>
      <br/>
      <Divider/>
      <br/>
      {product && (
        <Typography>
          <b>
            <FormattedMessage id="pages.solidgate.orders.show.product" />
            {": "}
          </b>
          <Link to={`/solidgate/products/${product.id}`} target="_blank">
            {product.solidgateProductId}
          </Link>
        </Typography>
      )}
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.productName" />
          {": "}
        </b>
        {product?.name}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.productType" />
          {": "}
        </b>
        {product?.type}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.productDescription" />
          {": "}
        </b>
        {product?.solidgateDescription}
      </Typography>
      {product && product.solidgateAmount && (
        <Typography>
          <b>
            <FormattedMessage id="pages.solidgate.orders.show.productAmount" />
            {": "}
          </b>
          {(Math.round(product.solidgateAmount * 100) / 10000).toFixed(2) + " USD"}
        </Typography>
      )}
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.wasEverRefunded" />
          {": "}
        </b>
        {wasEverRefunded || "false"}
      </Typography>
      <br />
      <Divider />
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.customerAccountId" />
          {": "}
        </b>
        {customerAccountId}
      </Typography>
      <br/>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.ip" />
          {": "}
        </b>
        {ip || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.userAgent" />
          {": "}
        </b>
        {userAgent || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.fbc" />
          {": "}
        </b>
        {fbc || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.fbp" />
          {": "}
        </b>
        {fbp || "Unknown"}
      </Typography>
      <br />
      <Divider />
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.source" />
          {": "}
        </b>
        {source || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.placement" />
          {": "}
        </b>
        {placement || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.campaign" />
          {": "}
        </b>
        {campaign || "Unknown"}
      </Typography>
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.orders.show.content" />
          {": "}
        </b>
        {content || "Unknown"}
      </Typography>
      <br />
      <Divider />
      <br />
      {recurringMetadata && (
        <Fragment>
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.orders.show.recurringMetadata" />
              {": "}
            </b>
            <br />
            <ReactJson src={recurringMetadata} />
          </Typography>
          <br />
          <Divider />
          <br />
        </Fragment>
      )}
      {createdAt && (
        <Typography>
          <b>
            <FormattedMessage id="pages.solidgate.orders.show.createdAt" />
            {": "}
          </b>
          {format(parseISO(createdAt), humanReadableDateTimeFormat)}
        </Typography>
      )}
      {updatedAt && (
        <Typography>
          <b>
            <FormattedMessage id="pages.solidgate.orders.show.updatedAt" />
            {": "}
          </b>
          {format(parseISO(updatedAt), humanReadableDateTimeFormat)}
        </Typography>
      )}
      <br />
      <Divider />
    </FormDialog>
  );
};
