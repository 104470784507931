import { FC } from "react";
import { useFormikContext } from "formik";

import { NumberInput } from "@encoderinc/mui-inputs-core";
import { IStep, StepType } from "@memoryos/types";

interface ITimeLimitInputProps {
  name: string;
  isDisabled?: boolean;
}

export const TimeLimitInput: FC<ITimeLimitInputProps> = props => {
  const { name, isDisabled = false } = props;

  const formik = useFormikContext<IStep>();

  if (
    // info
    formik.values.stepType === StepType.VIDEO ||
    formik.values.stepType === StepType.COLLECTION_IMAGE ||
    formik.values.stepType === StepType.COLLECTION_VIDEO ||
    // feedback
    formik.values.stepType === StepType.FEEDBACK_INPUT ||
    formik.values.stepType === StepType.MULTIPLE_CHOICE_FEEDBACK ||
    formik.values.stepType === StepType.SLIDER_FEEDBACK ||
    // test
    formik.values.stepType === StepType.COMPARE_TEST_RESULTS ||
    formik.values.stepType === StepType.RANDOM_WORDS_TEXT ||
    formik.values.stepType === StepType.RANDOM_WORDS_IMAGE ||
    formik.values.stepType === StepType.RANDOM_WORDS_INPUT ||
    formik.values.stepType === StepType.TIMER ||
    // system
    formik.values.stepType === StepType.SCORE ||
    formik.values.stepType === StepType.STREAK
  ) {
    return null;
  }

  return <NumberInput name={name} InputProps={{ inputProps: { min: 0 } }} disabled={isDisabled} />;
};
