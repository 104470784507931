import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  theme => ({
    darkText: {
      color: theme.palette.common.black,
    },
  }),
  { name: "Heading" },
);
