import { ChangeEvent, FC, Fragment, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Pagination,
} from "@mui/material";
import { CloudDownload, Visibility } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate, useLocation, useParams } from "react-router";
import { parse, stringify } from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";

import { ProgressOverlay } from "@encoderinc/mui-progress";
import { PageHeader } from "@encoderinc/mui-page-header";
import { ApiContext, ApiError } from "@encoderinc/provider-api";
import { IPaginationDto, IPaginationResult } from "@encoderinc/types-collection";

import { IAnswer } from "@memoryos/types";
import { emptyAnswer } from "@memoryos/mocks";

import { Breadcrumbs } from "../../../components/common/breadcrumbs";
import { ViewAnswerDialog } from "./view";
import { parseDateRange, stringifyDateRange } from "../../../utils/date";
import { AnswerSearchForm } from "./form";
import { defaultItemsPerPage } from "@memoryos/constants";

export interface IAnswerSearchDto extends IPaginationDto {
  stepIds: Array<number>;
  userIds: Array<number>;
  lessonIds: Array<number>;
  dateRange: [Date, Date];
}

export const Answer: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [answers, setAnswers] = useState<Array<IAnswer>>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedAnswer, setSelectedAnswer] = useState<IAnswer>(emptyAnswer);

  const api = useContext(ApiContext);

  const parsedData = parse(location.search.substring(1));

  const [data, setData] = useState<IAnswerSearchDto>({
    skip: 0,
    take: defaultItemsPerPage,
    userIds: [],
    lessonIds: [],
    stepIds: [],
    ...parsedData,
    dateRange: parseDateRange(parsedData.dateRange as string),
  });

  const updateQS = (id?: number) => {
    const { skip: _skip, take: _take, dateRange, ...rest } = data;
    navigate(
      id
        ? `/answers/${id}`
        : `/answers?${stringify({
            ...rest,
            dateRange: stringifyDateRange(dateRange),
          })}`,
    );
  };

  const handleView = (answer: IAnswer): (() => void) => {
    return (): void => {
      setSelectedAnswer(answer);
      setIsViewDialogOpen(true);
      updateQS(answer.id);
    };
  };

  const handleViewCancel = (): void => {
    setIsViewDialogOpen(false);
    updateQS();
  };

  const fetchAnswersByQuery = async (): Promise<void> => {
    const { dateRange, ...rest } = data;
    return api
      .fetchJson({
        url: "/curriculum/answers",
        data: {
          ...rest,
          dateRange: stringifyDateRange(dateRange),
        },
      })
      .then((json: IPaginationResult<IAnswer>) => {
        setAnswers(json.rows);
        setCount(json.count);
        updateQS();
      });
  };

  const fetchAnswersById = async (id: string): Promise<void> => {
    return api
      .fetchJson({
        url: `/curriculum/answers/${id}`,
      })
      .then((json: IAnswer) => {
        setAnswers([json]);
        setCount(1);
        handleView(json)();
      });
  };

  const fetchAnswers = async (id?: string): Promise<void> => {
    setIsLoading(true);
    return (id ? fetchAnswersById(id) : fetchAnswersByQuery())
      .catch((e: ApiError) => {
        if (e.status) {
          enqueueSnackbar(formatMessage({ id: `snackbar.${e.message}` }), { variant: "error" });
        } else {
          console.error(e);
          enqueueSnackbar(formatMessage({ id: "snackbar.error" }), { variant: "error" });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleViewConfirmed = (): void => {
    setIsViewDialogOpen(false);
  };

  const handleChangePage = (e: ChangeEvent<unknown>, page: number) => {
    setData({
      ...data,
      skip: (page - 1) * data.take,
    });
  };

  const handleSubmit = (values: IAnswerSearchDto): void => {
    setData({
      ...values,
      skip: 0,
      take: defaultItemsPerPage,
    });
  };

  const handleExport = (): Promise<void> => {
    const { dateRange, ...rest } = data;
    return api.fetchFile({
      url: "/curriculum/answers/export",
      data: {
        ...rest,
        dateRange: stringifyDateRange(dateRange),
      },
    });
  };

  useDeepCompareEffect(() => {
    void fetchAnswers(id);
  }, [data]);

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "answers"]} />

      <PageHeader message="pages.answers.title">
        <Button startIcon={<CloudDownload />} onClick={handleExport}>
          <FormattedMessage id="form.buttons.export" />
        </Button>
      </PageHeader>

      <AnswerSearchForm onSubmit={handleSubmit} initialValues={data} />

      <ProgressOverlay isLoading={isLoading}>
        <List disablePadding={true}>
          {answers.map((answer, i) => (
            <ListItem key={i} disableGutters={true}>
              <ListItemText
                primary={`Answer #${answer.id}`}
                secondary={
                  <Fragment>
                    <FormattedMessage id="pages.answers.user" />:{" "}
                    <Link component={RouterLink} to={`/users/${answer.user!.id}`}>
                      {answer.user!.displayName}
                    </Link>
                    <br />
                    <FormattedMessage id="pages.answers.stepType" />:{" "}
                    <Link component={RouterLink} to={`/steps/${answer.step!.id}`}>
                      <FormattedMessage id={`enums.stepType.${answer.step!.stepType}`} />
                    </Link>
                  </Fragment>
                }
              />
              <ListItemSecondaryAction>
                <IconButton onClick={handleView(answer)}>
                  <Visibility />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ProgressOverlay>

      <Pagination
        shape="rounded"
        page={data.skip / data.take + 1}
        count={Math.ceil(count / data.take)}
        onChange={handleChangePage}
      />

      <ViewAnswerDialog
        onCancel={handleViewCancel}
        onConfirm={handleViewConfirmed}
        open={isViewDialogOpen}
        initialValues={selectedAnswer}
      />
    </Grid>
  );
};
