import { FC, Fragment } from "react";

import { NumberInput } from "@encoderinc/mui-inputs-core";

interface ICompareTestResultsStepProps {
  name: string;
}

export const CompareTestResultsStep: FC<ICompareTestResultsStepProps> = props => {
  const { name } = props;

  return (
    <Fragment>
      <NumberInput name={`${name}.compareTestResultsFirstStepId`} InputProps={{ inputProps: { min: 1 } }} />
      <NumberInput name={`${name}.compareTestResultsSecondStepId`} InputProps={{ inputProps: { min: 1 } }} />
    </Fragment>
  );
};
