import { FC } from "react";

import { CheckboxInput } from "@encoderinc/mui-inputs-core";

interface ITutorialInputProps {
  name: string;
  checked?: boolean;
}

export const TutorialInput: FC<ITutorialInputProps> = props => {
  const { name, checked = false } = props;

  return checked ? <CheckboxInput name={name} /> : <CheckboxInput name={name} defaultChecked />;
};
