import { ComponentClass, FunctionComponent } from "react";
import { render } from "react-dom";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

import { EncoderincThemeProvider } from "@encoderinc/mui-provider-theme";
import { history } from "@encoderinc/history";

export default (App: ComponentClass<any> | FunctionComponent<any>): void => {
  render(
    <EncoderincThemeProvider>
      <HistoryRouter history={history}>
        <App />
      </HistoryRouter>
    </EncoderincThemeProvider>,
    document.getElementById("app"),
  );
};
